import React, { useState } from "react";
import { GiOrange } from "react-icons/gi";
import {
  MdDriveFileRenameOutline,
  MdPhone,
  MdFax,
  MdEmail,
  MdWeb,
  MdFacebook,
} from "react-icons/md";
import { FiInstagram, FiTwitter } from "react-icons/fi";
import { AiFillGoogleSquare } from "react-icons/ai";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { updateServiceProviders } from "../../../actions/serviceProviderActions";
import InputMask from "react-input-mask";
import ServiceProviderDetailsForm from "./ServiceProviderDetailsForm";

const ServiceProviderDetailsUpdateForm = ({ serviceProvider }) => {
  const [formData, setFormData] = useState({
    name: serviceProvider.name,
    phone_number: serviceProvider.phone_number,
    fax_number: serviceProvider.fax_number,
    email: serviceProvider.email,
    website: serviceProvider.website,
    facebook: serviceProvider.facebook || "",
    instagram: serviceProvider.instagram || "",
    twitter: serviceProvider.twitter || "",
    google_buisness: serviceProvider.google_buisness || "",
  });

  const dispatch = useDispatch();

  const formDataChangeHandler = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.id]: event.target.value,
      };
    });
  };

  const [errorNumber, setError] = useState("");
  const submitFormHandler = (e) => {
    e.preventDefault();
    dispatch(updateServiceProviders(formData, serviceProvider.id));
  };

  return (
    <>
      <div className="border-bottom">
        <h1
          className="text-center mineTextOrange"
          style={{ backgroundColor: "#000034", padding: "10px" }}
        >
          <img src="/images/FindxStorage3.png" height={40} />
          {/* <GiOrange />
          FindxStorage */}
        </h1>
        <p className="lead text-uppercase text-center">
          Please fill your data carefully to update your information.
        </p>
      </div>
      <form className="mt-2" onSubmit={submitFormHandler}>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="name">
            Name <span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdDriveFileRenameOutline />
            </span>
            <input
              type="text"
              className="form-control"
              id="name"
              value={formData.name}
              placeholder="Enter Name"
              onChange={formDataChangeHandler}
              required
              title="Please fill in the name."
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="phone_number">
            Phone Number <span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdPhone />
            </span>
            {/* <input
              type="text"
              value={formData.phone_number}
              className="form-control"
              id="phone_number"
              placeholder="Enter Phone Number. Pattern should be +999999999"
              onChange={(e) => {
                formDataChangeHandler(e);
              }}
              pattern="^\+[0-9]{9}$"
              title="Please enter a valid phone number in the format +999999999"
              required
            /> */}
            <InputMask
              mask="+1 (999) 999-9999"
              value={formData.phone_number}
              className="form-control"
              id="phone_number"
              placeholder="Enter Phone Number"
              onChange={(e) => {
                const formatNumber = e.target.value.replace(/[()\s-]/g, "");
                e.target.value = formatNumber;
                formDataChangeHandler(e);
              }}
              title="Please enter a valid phone number in the format +1 (999) 999-9999"
              required
            >
              {(inputProps) => (
                <input type="text" {...inputProps} className="form-control" />
              )}
            </InputMask>
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="fax_number">
            Fax Number
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdFax />
            </span>
            <input
              type="text"
              value={formData.fax_number}
              className="form-control"
              id="fax_number"
              placeholder="Enter Fax Number"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="email">
            Business Email
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdEmail />
            </span>
            <input
              type="email"
              value={formData.email}
              className="form-control"
              id="email"
              placeholder="Enter Email"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="website">
            Website
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdWeb />
            </span>
            <input
              type="text"
              value={formData.website}
              className="form-control"
              id="website"
              placeholder="Enter Website URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="facebook">
            Facebook
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdFacebook />
            </span>
            <input
              type="text"
              value={formData.facebook}
              className="form-control"
              id="facebook"
              placeholder="Enter Facebook URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="instagram">
            Instagram
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <FiInstagram />
            </span>
            <input
              type="text"
              value={formData.instagram}
              className="form-control"
              id="instagram"
              placeholder="Enter Instagram URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="twitter">
            Twitter
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <FiTwitter />
            </span>
            <input
              type="text"
              value={formData.twitter}
              className="form-control"
              id="twitter"
              placeholder="Enter Twitter URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="google_buisness">
            Google Business
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <AiFillGoogleSquare />
            </span>
            <input
              type="text"
              value={formData.google_buisness}
              className="form-control"
              id="google_buisness"
              placeholder="Enter Google Buisness URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <button className="mt-2 btn  text-dark">Update Details</button>
      </form>
    </>
  );
};

export default ServiceProviderDetailsUpdateForm;
