import React from "react";
import StorageUnitMap from "./StorageUnitMap";
import StorageUnitImage from "./StorageUnitImage";
import StorageUnitCarousel from "./StorageUnitCarousel";
import Carousel from "react-bootstrap/Carousel";
import { GoLocation } from "react-icons/go";
import { FiFlag } from "react-icons/fi";
const StorageUnitImageAndMap = ({ unit }) => {
  return (
    <>
      <div className="col-md-5 mb-2">
        <div className="card h-100 ">
          <div className="card-body d-flex flex-column justify-content-start align-items-center">
            <h5 className="my-3 text-center">
              Warehouses Unveiled: Beyond Storage
            </h5>

            {unit.facility_images.length !== 0 ? (
              <Carousel>
                {unit?.facility_images?.map((image) => {
                  return (
                    <Carousel.Item>
                      <img
                        src={image.image}
                        alt="storage1"
                        className="img-fluid rounded"
                        style={{
                          height: "300px",
                          maxHeight: "300px",
                          objectFit: "contain",
                        }}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            ) : (
              <img
                src={
                  unit?.facility_images[0]
                    ? unit?.facility_images[0].image
                    : "/images/storage1.jpg"
                }
                alt="storage1"
                className="img-fluid rounded h-75 w-100"
                style={{
                  objectFit: "cover",
                }}
              />
            )}
            <div className="d-flex flex-column mx-1">
              <h6 className="mt-3">
                <GoLocation /> {unit.address}
              </h6>
              <h6 className="mt-1 text-muted">
                <FiFlag /> {unit.location.address}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-7 mb-2">
        <div className="card h-100">
          <div className="card-body">
            <h5 className="mb-3">Storage Facility Location</h5>
            <StorageUnitMap unit={unit} />
          </div>
        </div>
      </div>
    </>
  );
};

export default StorageUnitImageAndMap;
