import React from "react";

const ChooseOrangeDetails = () => {
  return (
    <div className="bg-light p-3">
      <h5>Choose FindxStorage's as Your Storage Place in New York, NY</h5>
      <p>
        With a variety of self-storage facilities in New York to choose from,
        FindxStorage's is just around the corner. We are committed to providing
        storage locations that are clean, dry and secure. There is no cost to
        reserve and you’re guaranteed to find a unit and size that fits your
        needs.
      </p>
      <h6>Picking the Right Storage Unit in New York, NY</h6>
      <p>
        Each location is going to have a unique mix of features and services,
        giving you multiple options to choose from when renting a storage room.
        Find one that fits your needs by determining the size and type of unit
        you need first. Available options in the New York market include:
      </p>
      <ul>
        <li>Indoor and Climate Controlled Storage Units</li>
        <li>24 Hour Storage Access</li>
      </ul>
      <p>
        Rates for mini storage in New York are going to depend on the features
        and services selected. Generally, climate controlled facilities tend to
        cost more, but provide double the security and protection. Meanwhile, if
        you are looking at accessing your unit frequently, drive up storage
        units are more affordable and convenient.
      </p>
      <h6>
        Move-In Specials Available for Storage Customers Near New York, NY
      </h6>
      <ul>
        <li>No Admin or Deposit Fees</li>
        <li>
          First Month of Storage Free with a One Way Truck or Trailer Rental
        </li>
      </ul>
    </div>
  );
};

export default ChooseOrangeDetails;
