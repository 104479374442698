import React from "react";
import Features from "./Features";
import AvailableUnits from "./AvailableUnits";
import ImageModal from "../../../shared-components/PhotoGallery/PhotoModal";
import PromotedFacilities from "./PromotedFacilities";
import { useRef } from "react";
const FeaturesAndAvailableUnits = ({ product, storageFacility }) => {
  const imageModalRef = useRef(null);

  const showImageModal = () => {
    if (imageModalRef.current) {
      imageModalRef.current.showModal();
    }
  };

  return (
    <div className="row py-3">
      <div className="col-md-3">
        <Features product={product} storageFacility={storageFacility} />
      </div>
      <div className="col-md-9">
        <AvailableUnits product={product} storageFacility={storageFacility} />
        <PromotedFacilities storageFacility={storageFacility} />
      </div>
    </div>
  );
};

export default FeaturesAndAvailableUnits;
