import {
  UPDATE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  NOTIFICATION_STATUS_FAIL,
  NOTIFICATION_STATUS_REQUEST,
  NOTIFICATION_STATUS_SUCCESS,
} from "../constants/notificationConstants";
export const postNotficationReducer = (
  state = { loading: true, message: null, error: null },
  action,
) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_REQUEST:
      return { loading: true };
    case UPDATE_NOTIFICATION_SUCCESS:
      return { loading: false, message: action.payload };
    case UPDATE_NOTIFICATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const updateNotficationReducer = (
  state = { loading: true, message: null, error: null },
  action,
) => {
  switch (action.type) {
    case NOTIFICATION_STATUS_REQUEST:
      return { loading: true };
    case NOTIFICATION_STATUS_SUCCESS:
      return { loading: false, message: action.payload };
    case NOTIFICATION_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
