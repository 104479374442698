import React from "react";
import { Link } from "react-router-dom";
import { BsFire } from "react-icons/bs";

import Rating from "../../../shared-components/RatingStars/RatingStars";

import "./style.css";

const StorageUnitsList = ({ storageFacilties }) => {
  return (
    <div className="flex flex-col">
      <ul className="list-group list-group-flush">
        {storageFacilties?.results?.map((storage) => {
          return (
            <li
              className="card card-body p-4 mb-3 position-relative "
              key={storage._id}
            >
              {storage.has_promotion ? (
                <span class="position-absolute top-10 end-0 py-1 px-2 pe-4 promotion-banner">
                  <BsFire size={20} className="mb-1 me-1" />

                  <span class="text-danger fw-semibold">
                    Promotions Available
                  </span>
                </span>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-12">
                  <span className="badge bg-orange p-3">{storage._id}</span>
                  <h4 className="">{storage.name}</h4>
                </div>
                <Rating
                  value={storage.average_rating ? storage.average_rating : 0}
                  text={`${
                    storage.total_persons_rating
                      ? storage.total_persons_rating
                      : 0
                  } reviews `}
                  color="#8704f5"
                  fontSize={"15px"}
                />
                {/* <div>
                  <h6 className="text-muted">{storage.distance} Miles</h6>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="m-0">{storage.location.address}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div className="col">
                      <span className="fs-6 fw-semibold">Office Hours: </span>
                      {/* <p className="fw-bold text-success m-0">Open Today</p> */}
                      <span>{storage.working_hours}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="fs-6 fw-semibold">Access Hours: </span>
                      <span>{storage.access_hours}</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 d-flex justify-content-end">
                  <div className="d-flex flex-column  align-items-center ">
                    <div className="">
                      Starting from{" "}
                      <span className="fs-5 fw-semibold">
                        ${storage.minimum_price}
                      </span>
                      /month
                    </div>
                    <Link
                      to={`/storages/results/${storage.id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <button className=" btn btn-dark text-dark">
                        View Storage Units
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default StorageUnitsList;
