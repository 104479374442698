export const GET_ALL_STORAGE_FACILTIES_REQUEST =
  "GET_ALL_STORAGE_FACILITIES_REQUEST";
export const GET_ALL_STORAGE_FACILTIES_SUCCESS =
  "GET_ALL_STORAGE_FACILITIES_SUCCESS";
export const GET_ALL_STORAGE_FACILTIES_FAIL = "GET_ALL_STORAGE_FACILITIES_FAIL";

export const GET_STORAGE_FACILTIES_DETAILS_REQUEST =
  "GET_STORAGE_FACILITIES_DETAILS_REQUEST";
export const GET_STORAGE_FACILTIES_DETAILS_SUCCESS =
  "GET_STORAGE_FACILITIES_DETAILS_SUCCESS";
export const GET_STORAGE_FACILTIES_DETAILS_FAIL =
  "GET_STORAGE_FACILITIES_DETAILS_FAIL";

export const GET_DISTINCTIVE_STORAGE_FACILITIES_SUCCESS =
  "GET_DISTINCTIVE_STORAGE_FACILITIES_SUCCESS";
export const GET_DISTINCTIVE_STORAGE_FACILITIES_REQUEST =
  "GET_DISTINCTIVE_STORAGE_FACILITIES_REQUEST";
export const GET_DISTINCTIVE_STORAGE_FACILITIES_FAIL =
  "GET_DISTINCTIVE_STORAGE_FACILITIES_FAIL";
