import React from "react";

const IndoorStorageHeading = ({ product, storageType }) => {
  return (
    <div className="d-flex row">
      <div className="col-12 text-center ">
        <h5>
          {storageType?.storage_unit_type.toUpperCase().replace(/_/g, " ")}
        </h5>
      </div>
    </div>
  );
};

export default IndoorStorageHeading;
