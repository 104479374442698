import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../shared-components/container/Container";
import HomeCard from "./HomeCard/HomeCard";
import TopCards from "./HomeTopCards/TopCards";
import WelcomeOrange from "./WelcomeOrange/WelcomeOrange";
import WhyOrange from "./WhyOrange/WhyOrange";
import HomeHotOffers from "./HomeHotOffers/HomeHotOffers";
import HomeClientsTestimonials from "./HomeClientsTestimonials/HomeClientsTestimonials";
import { getAllServiceProviders } from "../../actions/serviceProviderActions";
import { USER_REGISTER_RESET } from "../../constants/userConstants";

import "./Home.css";

const Home = () => {
  const { user } = useSelector((state) => state.userLogin);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_REGISTER_RESET });
    if (user) {
      if (user.service_provider && user.is_provider_verified) {
        // if (user.is_stripe_account_active !== undefined && user.is_stripe_account_active) {
        //   dispatch(getAllServiceProviders());
        //   navigate("/service-provider-dashboard/analytics");
        // } else if (user.is_stripe_account_active !== undefined && !user.is_stripe_account_active) {
        //   navigate("/service-provider-stripe-status");
        // }else {
        //   navigate("/");
        // }
        dispatch(getAllServiceProviders());
        navigate("/service-provider-dashboard/analytics");
      } else if (user.service_provider && !user.is_provider_verified) {
        navigate("/provider-details-verification");
      } else {
        navigate("/");
      }
    }
  }, [user]);

  return (
    <>
      <section
        id="showcase-area "
        // style={{
        //   minHeight: "80vh",
        //   background:
        //     'url("https://www.storemygoods.in/assets/images/change_in_images/self-storage-all.jpg")',
        //   backgroundPosition: "center",
        //   backgroundRepeat: "no-repeat",
        // }}

        style={{
          minHeight: "80vh",
          background: 'url("/images/banner-background.jpeg")',
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="dark-overlay">
          <div className="showcase-content py-5">
            <Container>
              {/* <div className="container" style={{height:"80vh"}}> */}
              <HomeCard />
              {/* </div> */}
            </Container>
          </div>
        </div>
      </section>
      {/* Welcome To Orange App */}
      <section id="welcome-orange" className="px-3 py-5 ">
        <Container>
          <WelcomeOrange />
        </Container>
      </section>
      {/* Top Cards */}
      {/* <section id="top-cards" className="pb-5">
        <Container>
          <TopCards />
        </Container>
      </section> */}
      {/* Why Orange app */}
      <section id="why-orange" className="py-5">
        <Container>
          <WhyOrange />
        </Container>
      </section>
      {/* Some cards */}
      <section id="some-cards" className="py-5 bg-light">
        <h1 className="text-center">
          Small. <span className="mineTextOrange">Medium.</span> Large.
        </h1>

        <HomeHotOffers />
      </section>
      {/* Client Testimonials */}
      <section id="clients" className="py-5">
        <h1 className="text-center mb-5">
          See What Our <span className="mineTextOrange">Clients</span> Says
          about us
        </h1>
        <HomeClientsTestimonials />
      </section>
    </>
  );
};

export default Home;
