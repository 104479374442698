import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { logoutUser } from "../../../actions/userActions";
import { CREATE_SERVICE_PROVIDER_CLEAR } from "../../../constants/serviceProviderConstants";
import Spinner from "../../../shared-components/Spinner/Spinner";
import ServiceProviderDetailsForm from "./ServiceProviderDetailsForm";

const ServiceProviderDetails = () => {
  const { loading, error, serviceProvider } = useSelector(
    (state) => state.createServiceProvider,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceProvider) {
      // navigate(`/${serviceProvider.stripe_data.url}`);
      dispatch(logoutUser(true));
      toast.success(
        "Service Provider Sign Up Successfully. Please Login to your account.",
      );
      dispatch({ type: CREATE_SERVICE_PROVIDER_CLEAR });
      const url = serviceProvider.stripe_data.url; // Use the URL directly
      window.open(url, "_blank"); // This will open the URL in a new tab
    }

    if (error) {
      toast.error("Something went wrong.");
    }
  }, [serviceProvider, error]);
  return (
    <div className="py-5">
      <div className="container">
        <div className="mt-3">
          {loading ? (
            <Spinner />
          ) : (
            <div className="card authWidth w-75 m-auto">
              <div className="card-body">
                <ServiceProviderDetailsForm />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderDetails;
