import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Container from "../../../shared-components/container/Container";
import HomeOrdersTableServiceProvider from "../../Home/HomeOrdersTableServiceProvider/HomeOrdersTableServiceProvider";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { getAllOrdersAdmin } from "../../../actions/ordersActions";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import { CiReceipt, CiShoppingCart } from "react-icons/ci";

const OrdersPage = () => {
  const allOrdersAdmin = useSelector((state) => state.allOrdersAdmin);
  const dispatch = useDispatch();
  const { orders, loading } = allOrdersAdmin;
  const [selectedData, setSelectedData] = useState({
    pending: false,
    completed: false,
    starting_date: "",
    ending_date: "",
    search: "",
    page: 1,
  });

  const options = [
    { value: "completed", label: "Completed" },
    { value: "pending", label: "Pending" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedData({
      ...selectedData,
      pending: selectedOptions.some((option) => option.value === "pending"),
      completed: selectedOptions.some((option) => option.value === "completed"),
    });
  }, [selectedOptions]);

  const handleMultiSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleFilter = () => {
    dispatch(getAllOrdersAdmin({ ...selectedData, page: 1 }));
  };

  const handleDateChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (name === "starting_date" || name === "ending_date") {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        value = `${year}-${month}-${day}`;
      }

      setSelectedData({
        ...selectedData,
        [name]: value,
      });
    } else {
      setSelectedData({
        ...selectedData,
        [name]: "",
      });
    }
  };

  const handleTextChange = (e) => {
    setSelectedData({
      ...selectedData,
      search: e.target.value,
    });
  };

  const handlePagination = (pageNumber) => {
    setSelectedData({
      ...selectedData,
      page: pageNumber,
    });
  };

  useEffect(() => {
    dispatch(getAllOrdersAdmin(selectedData));
  }, [selectedData.page]);

  return (
    <div className="py-2">
      <Container>
        <div className="align-items-center d-none d-lg-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div className="p-2 bg-orange text-dark rounded me-2">
              <CiReceipt size={24} />
            </div>
            <h5 className="text-uppercase">Your Orders</h5>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-body">
            <div
              className="d-flex align-items-end justify-content-start flex-wrap mb-5"
              style={{
                gap: "30px",
              }}
            >
              <div className="mt-3" style={{ width: "fit-content" }}>
                <label className="fw-bold">Status:</label>
                <Select
                  isMulti
                  name="status"
                  options={options}
                  value={selectedOptions}
                  onChange={handleMultiSelectChange}
                />
              </div>

              <div className="mt-3" style={{ width: "150px" }}>
                <label className="fw-bold">Start Date:</label>
                <input
                  type="date"
                  className="input-group border border-1 rounded p-2"
                  name="starting_date"
                  value={selectedData.starting_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                />
              </div>
              <div className="mt-3" style={{ width: "150px" }}>
                <label className="fw-bold">End Date:</label>
                <input
                  type="date"
                  className="input-group border border-1 rounded p-2"
                  name="ending_date"
                  value={selectedData.ending_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                />
              </div>
              <div className="mt-3" style={{ width: "150px" }}>
                <label className="fw-bold">Search Text:</label>
                <input
                  type="text"
                  className="border border-1 rounded p-2"
                  style={{
                    maxWidth: "150px",
                    height: "35px",
                  }}
                  value={selectedData.search}
                  onChange={handleTextChange}
                />
              </div>

              <button className="btn mt-6 text-dark" onClick={handleFilter}>
                Search
              </button>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <HomeOrdersTableServiceProvider orders={orders} />
            )}
          </div>
          <div
            className="d-flex justify-content-center align-items-center mb-5"
            style={{
              gap: "50px",
            }}
          >
            <Pagination
              activePage={selectedData.page}
              itemsCountPerPage={10} // Number of items per page
              totalItemsCount={orders?.count || 1} // Total number of items
              pageRangeDisplayed={5} // Number of pages to display in the pagination
              onChange={handlePagination} // Callback function to handle page changes
              itemClass="page-item" // Bootstrap class for each pagination item
              linkClass="page-link" // Bootstrap class for pagination links
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OrdersPage;
