import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import StorageUnitImageAndMap from "../ServiceProviderDashboardComponents/StorageProviderDetailsPageComponents/StorageUnitImageAndMap";
import StorageUnitCharts from "../ServiceProviderDashboardComponents/StorageProviderDetailsPageComponents/StorageUnitCharts";
import StorageUnitRooms from "../ServiceProviderDashboardComponents/StorageProviderDetailsPageComponents/StorageUnitRooms";
import Spinner from "../../../shared-components/Spinner/Spinner";

import {
  getSingleStorageUnit,
  getTotalStorageUnits,
} from "../../../actions/storageUnitActions";
import { getAllRoomsOfSpecificUnit } from "../../../actions/roomsActions";
import StorageUnitReviews from "../ServiceProviderDashboardComponents/StorageProviderDetailsPageComponents/StorageUnitReviews";
import { LuWarehouse } from "react-icons/lu";

const StorageUnitDetailsPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const { loading, storageUnit: unit } = useSelector(
    (state) => state.getSingleStorageUnit,
  );
  const { loading: totalLoading, rooms } = useSelector(
    (state) => state.getAllRoomsOfSpecificUnit,
  );

  useEffect(() => {
    dispatch(getSingleStorageUnit(id));
    dispatch(getAllRoomsOfSpecificUnit(id));
  }, [dispatch]);
  return (
    <div className="mt-3">
      {/* {loading || totalLoading ? ( */}
      {!unit || loading || totalLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h2 className="text-uppercase d-inline mb-0">{unit.name}.</h2>
              <p className="lead text-muted">{unit.storage_provider.email}</p>
            </div>
            <div>
              <Link
                to={`/service-provider-dashboard/addUnit/${unit.id}`}
                className="btn  text-dark"
              >
                <LuWarehouse
                  className="pr-1"
                  style={{
                    marginRight: "10px",
                  }}
                />
                EDIT FACILITY
              </Link>
            </div>
          </div>
          <div className="row gx-4 mt-2">
            <StorageUnitImageAndMap unit={unit} />
          </div>
          <div className="row gx-4 mt-3">
            <StorageUnitCharts unit={unit} />
          </div>

          <div className="row mx-0 mt-3">
            <StorageUnitRooms storageUnits={rooms} unit={unit} />
          </div>

          <div className="container card mt-3">
            <div className="row card-body">
              <h5 className="">Reviews</h5>
              {unit.facility_ratings.length > 0 ? (
                unit?.facility_ratings.map((rating, index) => (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-center align-items-center mb-3 mt-3"
                  >
                    <div className="border p-3">
                      <StorageUnitReviews rating={rating} />
                    </div>
                  </div>
                ))
              ) : (
                <p className="fw-bold m-5">
                  This Storage Facility has no reviews!
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StorageUnitDetailsPage;
