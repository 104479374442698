import React from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const StorageTypes = () => {
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const name = queryParams.get("name")
  //   ? decodeURIComponent(queryParams.get("name"))
  //   : "Self Storage";

  return (
    <div className="container">
      <div className="row my-5">
        <div
          className="col-md-6 py-4 px-5  mt-5"
          style={{ backgroundColor: "lightgray" }}
        >
          <h5 className="mb-3">Storage</h5>
          <h3 className="text-align mb-4 fw-bold">
            Clean, Well-Lit Personal Storage Options In Your Neighborhood.
          </h3>
          <div className="input-group my-3 d-flex gap-3">
            <div>
              <input
                type="text"
                className="form-control"
                style={{ height: "38px", width: "250px" }}
                placeholder="Enter city, state or zip"
              />
            </div>
            <div className="input-group-append">
              <button className="btn btn-warning ml-5 text-dark" type="button">
                Find Storage
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-5">
          <img
            src="https://img.freepik.com/premium-photo/warehouse-logistics-center-foundation-storage-solutions_892776-12901.jpg"
            className="img-fluid"
            style={{ height: "320px", width: "100%" }}
            alt="Public Storage"
          />
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item mt-1 mb-3">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active mt-1 mb-3" aria-current="page">
            Storage
          </li>
        </ol>
      </nav>

      <div>
        <h2 className="mt-4 mb-5 text-center blueTextColr fw-bold">
          STORAGE TYPES
        </h2>
        <div
          className="row my-5 p-4"
          style={{
            backgroundColor: "#F3F7F8",
            fontSize: "15px",
            textAlign: "justify",
          }}
        >
          <div className="col-md-4 mt-5 mb-5">
            <h3 className="blueTextColr fw-bold fs-4">SELF STORAGE</h3>
            <p className="blueTextColr">
              FindxStorage Storage facility is available to individuals and to
              businesses. Facility may offer boxes and packaging supplies for
              sale.
            </p>
          </div>
          <div className="col-md-4 mt-5 mb-5">
            <h3 className="blueTextColr fw-bold fs-4">BUSINESS STORAGE</h3>
            <p className="blueTextColr">
              FindxStorage offers secure, spacious and convenient facilities
              that will handle all your storage needs during a business process.
            </p>
          </div>
          <div className="col-md-4 mt-5 mb-5">
            <h3 className="blueTextColr fw-bold fs-4">
              CLIMATE CONTROLLED STORAGE
            </h3>
            <p className="blueTextColr">
              For the things that matter most, choose one of our
              climate-controlled self-storage units.A climate-controlled unit
              may help keep your stuff within a set temperature or humidity
              range.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center my-5">
        <h1
          style={{ fontSize: "40px", textAlign: "center", fontWeight: "bold" }}
          className="mt-5 blueTextColr"
        >
          Sometimes you just need a little more space. At FindxStorage, we help
          you make room for what matters most.
        </h1>
      </div>
      <div
        className="row g-0 my-5"
        style={{ textAlign: "justify", fontSize: "17px" }}
      >
        <div className="col-md-5 mt-5">
          <h3 className="blueTextColr fw-bold">Get Your House Back</h3>
          <p className="blueTextColr">
            If your closets are overflowing and there's no room in the garage
            for your car, a storage space can help you reclaim some square
            footage.
          </p>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-5 mb-5 mt-5">
          <h3 className="blueTextColr fw-bold">
            Make Room for Life's Transitions
          </h3>
          <p className="blueTextColr">
            Whether you’re downsizing, relocating or making space for a
            grandparent or a college grad who’s moving back home, life is full
            of surprises that require a little extra space. Whenever you need
            more room, we can help.
          </p>
        </div>
        <div className="col-md-5 mt-3">
          <h3 className="blueTextColr fw-bold">
            Hold On to the Things That Matter
          </h3>
          <p className="blueTextColr">
            You don’t have to get rid of something, or live without it just
            because you’re short on space. Our storage units let you keep things
            nearby and get them conveniently.
          </p>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-5 mt-3">
          <h3 className="blueTextColr fw-bold">
            Make Room for a New Addition to the Family
          </h3>
          <p className="blueTextColr">
            Babies and all those baby things, such as car seats, strollers and
            cribs, take up space. You don’t have to sacrifice your stuff to make
            room for your little one. Just store it with us.
          </p>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-md-6 mt-5 mb-5">
          <img
            src="https://img.freepik.com/premium-photo/storage-large-warehouse-building_87720-118964.jpg"
            style={{ height: "320px", width: "100%" }}
            className="img-fluid"
            alt="Neighborhood"
          />
        </div>
        <div
          className="col-md-6 mt-5 px-5 mb-5"
          style={{
            backgroundColor: "lightgray",
            textAlign: "justify",
            fontSize: "15px",
          }}
        >
          <h4 className="mt-3 mb-3 blueTextColr fw-bold fs-3">
            STORAGE SOLUTIONS
          </h4>
          <h3 className="blueTextColr fw-bold fs-4">Moving</h3>
          <p className="blueTextColr">
            Sometimes schedules slip and couches don’t fit. If your moving plans
            leave you in limbo, you can always store your packed-up household
            items with us.
          </p>
          <h3 className="blueTextColr fw-bold fs-4">
            Traveling and Military Deployment
          </h3>
          <p className="blueTextColr">
            Going on an adventure? Shipping out to serve our country? Pack light
            and store everything else with us. All our units are month-to-month
            rentals, so you can grab things and go when you return.
          </p>
        </div>
      </div>
      <h2 className="mt-4 mb-5 text-center blueTextColr fw-bold">
        STORAGE SOLUTIONS
      </h2>
      <div
        className="row my-5 p-4"
        style={{
          backgroundColor: "#F3F7F8",
          fontSize: "15px",
          textAlign: "justify",
        }}
      >
        <div className="col-md-4 mt-5 mb-5">
          <h3 className="blueTextColr fw-bold fs-4">Staging for a Sale</h3>
          <p className="blueTextColr">
            House shoppers need to be able to see themselves in the homes they
            consider, which is why Realtors tell sellers to declutter before the
            first open house. If you’re putting your house on the market, put
            some things in storage so your house shows at its best.
          </p>
        </div>
        <div className="col-md-4 mt-5 mb-5">
          <h3 className="blueTextColr fw-bold fs-4">
            Subletting and Airbnb-ing
          </h3>
          <p className="blueTextColr">
            People expect furnishings and a working kitchen when they sublet or
            rent via Airbnb, but they probably don’t want to live with your
            personal items. If you have things that you don’t want to share with
            your guests, store them with us.
          </p>
        </div>
        <div className="col-md-4 mt-5 mb-5">
          <h3 className="blueTextColr fw-bold fs-4">Remodeling</h3>
          <p className="blueTextColr">
            When you’re opening up walls and tearing out cabinets, the more room
            you have in which to work, the better. Whether you’re doing it
            yourself or hiring it out, putting things in storage will make
            remodeling a lot easier.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StorageTypes;
