import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_RESET,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_EMAIL_UPDATE_FAIL,
  USER_EMAIL_UPDATE_REQUEST,
  USER_EMAIL_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_FAIL,
  CHECK_USER,
  USER_CHECK_FAIL,
} from "../constants/userConstants";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { cartGetItems } from "./cartActions";
import { USER_INFO_CLEAR } from "../constants/userConstants";
// Register User
export const API_URL = "https://3.82.183.189/app";
// export const API_URL = "https://3.82.183.189/app";
// export const API_URL = "http://127.0.0.1:8000/app";
axios.defaults.withCredentials = true;

export const registerUser = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const { data } = await axios.post(`${API_URL}/auth/register/`, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

// Login User
export const loginUser = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axios.post(`${API_URL}/auth/login/`, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { user_id } = jwt_decode(data.access);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    dispatch(userInfo(user_id));
    dispatch(cartGetItems());
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("user_id", data.user_id);
  } catch (error) {
    console.error(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const signAgreement = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { user },
    } = getState();
    user.is_agreement_signed = true;

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: user,
    });
    localStorage.setItem("user", JSON.stringify(user));

    // call api here
    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${API_URL}/auth/accept-terms/`,
      {
        is_agreement_signed: true,
      },
      config,
    );
  } catch (error) {
    console.error(error);
  }
};

// Logout user
export const logoutUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    let {
      userLogin: { user },
    } = getState();

    user = user != null ? user : JSON.parse(localStorage.getItem("user"));

    const accessToken = user.access;
    const refresh_token = user.refresh;
    const user_id = localStorage.getItem("user_id");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("serviceProvider");

    dispatch({
      type: USER_LOGIN_RESET,
    });
    console.log("login reset dispatch hit");

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: data.msg,
    });
    const { data } = await axios.post(
      `${API_URL}/auth/logout/`,
      { refresh_token, user_id },
      config,
    );
  } catch (error) {
    console.error(error);
  }
};

// USER INFORMATION
export const userInfo = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INFO_REQUEST,
    });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/auth/viewuser/${userId}`,
      config,
    );

    dispatch({
      type: USER_INFO_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_INFO_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

// Login User
export const refreshTokens = () => async (dispatch, getState) => {
  try {
    console.log("refresh function run");
    const user = JSON.parse(localStorage.getItem("user"));
    const user_id = localStorage.getItem("user_id");

    const accessToken = user.access;
    const refreshToken = user.refresh;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${API_URL}/auth/token/refresh/`,
      { refresh: refreshToken, user_id: user_id },
      config,
    );
    const newData = {
      ...user,
      access: data.access,
      refresh: data.refresh,
      is_agreement_signed: user?.is_agreement_signed || false,
    };
    console.log("data after refresh", newData);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: newData,
    });

    localStorage.setItem("user", JSON.stringify(newData));
    console.log("refresh func ended");
  } catch (error) {
    console.error(error);
    console.log("logout happened");
    const newData = null;
    localStorage.setItem("user", JSON.stringify(newData));
    dispatch(logoutUser());
    dispatch({ type: USER_INFO_CLEAR });
    const navigate = useNavigate();
    navigate("/sign-in");
  }
};

export const forgotPassword = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    const { data } = await axios.post(
      `${API_URL}/auth/resetpassword/`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const updateUserEmail = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_EMAIL_UPDATE_REQUEST,
    });

    // const {
    //   userLogin: { user },
    // } = getState();

    const user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { user_id } = jwt_decode(accessToken);

    const { data } = await axios.put(
      `${API_URL}/auth/update_user/${user_id}/`,
      email,
      config,
    );

    dispatch({
      type: USER_EMAIL_UPDATE_SUCCESS,
      payload: data.msg,
    });

    dispatch(userInfo(user_id));
  } catch (error) {
    dispatch({
      type: USER_EMAIL_UPDATE_FAIL,
      payload:
        error.response && error.response.data.authorize
          ? error.response.data.authorize
          : error.message,
    });
  }
};

export const updateUserPassword = (password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PASSWORD_UPDATE_REQUEST,
    });

    // const {
    //   userLogin: { user },
    // } = getState();
    const user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { user_id } = jwt_decode(accessToken);

    const { data } = await axios.put(
      `${API_URL}/auth/change_password/${user_id}/`,
      password,
      config,
    );

    dispatch({
      type: USER_PASSWORD_UPDATE_SUCCESS,
      payload: data.msg,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_UPDATE_FAIL,
      payload: error.message,
    });
  }
};

export const checkuser = () => async (dispatch) => {
  try {
    dispatch({
      type: CHECK_USER,
    });
    // user check in local storage
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken2 = user.access;
    if (accessToken2) {
      // Decode access token
      const decodedToken = jwt_decode(accessToken2);
      const expirationTimeInSeconds = decodedToken.exp;
      const expirationTimeInMillis = expirationTimeInSeconds * 1000;
      const currentTimeInMillis = new Date().getTime();
      if (currentTimeInMillis > expirationTimeInMillis) {
        console.log("Token expired");
        dispatch(refreshTokens());
      } else {
        console.log("Token is not expired");
      }
    }

    if (!user) {
      dispatch({
        type: USER_CHECK_FAIL,
        payload: "No user found in local storage",
      });
      return;
    }

    // check user authorized or not
    if (!accessToken2) {
      dispatch({
        type: USER_CHECK_FAIL,
        payload: "No access token found in user data",
      });
      return;
    }

    if (user && accessToken2) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: user,
      });
    }

    // Verify the token
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken2}`,
      },
    };
  } catch (error) {
    dispatch({
      type: USER_CHECK_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message,
    });
  }
};
