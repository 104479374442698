import React, { useState } from "react";
import Select from "react-select";

const StorageUnitsFilter = ({
  onUpdateFilters,
  distinctiveStorageUnits,
  storageFacility,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    is_available: false,
    search: "",
    name: "",
    storage_facility__name: "",
  });
  const filterOptions = [{ value: "is_available", label: "Is Available" }];

  const handleChange = (e) => {
    setSelectedFilters({
      ...selectedFilters,
      [e.target.id]: e.target.value,
    });
  };

  const handleFilterChange = (selectedOptions) => {
    const updatedFilters = { ...selectedFilters };

    selectedOptions?.forEach((option) => {
      switch (option.value) {
        case "is_available":
          updatedFilters.is_available = true;
          break;
        default:
          break;
      }
    });

    filterOptions.forEach((option) => {
      if (
        !selectedOptions.some(
          (selectedOption) => selectedOption.value === option.value,
        )
      ) {
        switch (option.value) {
          case "is_available":
            updatedFilters.is_available = false;
            break;
          default:
            break;
        }
      }
    });

    setSelectedFilters(updatedFilters);
  };

  const selectedOptions = filterOptions.filter((option) => {
    switch (option.value) {
      case "is_available":
        return selectedFilters.is_available;
      default:
        return false;
    }
  });

  return (
    <div
      className="d-flex justify-content-end align-items-end mb-5 "
      style={{
        gap: "40px",
      }}
    >
      <div>
        <label htmlFor="business_type" className="">
          Filters
        </label>
        <Select
          options={filterOptions}
          isMulti
          value={selectedOptions}
          onChange={handleFilterChange}
          placeholder="Select filters..."
        />
      </div>
      {distinctiveStorageUnits && Array.isArray(distinctiveStorageUnits) && (
        <div className="form-group">
          <label htmlFor="distinctive_storage_unit">Storage Unit</label>
          <select
            id="name"
            className="form-select"
            aria-label="Default select example"
            onChange={handleChange}
          >
            <option selected value="">
              Select Unit
            </option>
            {distinctiveStorageUnits.map((item, index) => (
              <option key={index} id={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {storageFacility && Array.isArray(storageFacility) && (
        <div className="form-group">
          <label htmlFor="business_type">Storage Facility</label>
          <select
            id="storage_facility__name"
            className="form-select"
            aria-label="Default select example"
            onChange={handleChange}
          >
            <option selected value="">
              Select Facility
            </option>
            {storageFacility.map((item, index) => (
              <option key={index} id={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="flex-column d-flex">
        <label htmlFor="business_type">Search</label>

        <input
          type="text"
          value={selectedFilters.search || ""}
          id="search"
          className="border border-1 rounded p-2"
          onChange={handleChange}
        />
      </div>
      <button
        className="btn text-dark"
        onClick={() => onUpdateFilters(selectedFilters)}
      >
        Search
      </button>
    </div>
  );
};

export default StorageUnitsFilter;
