import React from "react";
import Rating from "../../../shared-components/RatingStars/RatingStars";
import { FaPhone, FaRegClock, FaLocationArrow } from "react-icons/fa";
import { MdFilterFrames } from "react-icons/md";
import { useRef } from "react";
import ImageModal from "../../../shared-components/PhotoGallery/PhotoModal";
import HoursModal from "./HoursModal";
import MapModal from "./MapModal/MapModal";

const NameAndPictureDetails = ({ product, storageFacility }) => {
  const imageModalRef = useRef(null);

  const showImageModal = () => {
    if (imageModalRef.current) {
      imageModalRef.current.showModal();
    }
  };
  return (
    <div className="d-flex justify-content-between border-bottom flex-row flex-wrap">
      <div className="">
        <h1>{storageFacility.name}</h1>
        <Rating
          value={storageFacility?.average_rating}
          text={`${
            storageFacility?.total_persons_rating
              ? storageFacility?.total_persons_rating
              : 0
          } Reviews`}
        />
        <h3 className="my-3 font-weight-bold container-fluid ">
          {storageFacility?.location.address}
        </h3>
        {/* <p className="pl-3"><span className="fw-bold">Address : </span>{storageFacility.address}</p> */}
        <div className="d-flex flex-wrap ">
          <span
            className="me-5  pointer d-flex align-items-center"
            style={{
              gap: "10px",
            }}
          >
            <a 
              href={`tel:${storageFacility?.storage_provider.phone_number}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
            <FaPhone className="mineTextOrange " />
            {storageFacility?.storage_provider.phone_number}
            </a>
          </span>
          <div
            className="me-5 pointer d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#hoursModal"
            style={{
              gap: "10px",
            }}
          >
            <FaRegClock className="mineTextOrange" />
            <span className="pl-2 font-weight-bold">Hours</span>
          </div>
          <div
            className="me-5 pointer  d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#mapModal"
            style={{
              gap: "10px",
            }}
          >
            <FaLocationArrow className="mineTextOrange" />
            <span className="ml-1">Directions</span>
          </div>
          <div
            className="pointer d-flex align-items-center"
            style={{
              gap: "10px",
            }}
          >
            <MdFilterFrames className="mineTextOrange" />

            <ImageModal ref={imageModalRef} storageFacility={storageFacility} />
          </div>
        </div>
      </div>

      <div
        className="m-auto mt-3 d-flex flex-column"
        style={{ textAlign: "center" }}
        onClick={showImageModal}
      >
        <img
          src={
            storageFacility?.facility_images[0]
              ? storageFacility?.facility_images[0].image
              : "/images/small.png"
          }
          className="img-fluid rounded"
          alt="storagePic"
          style={{
            width: 200,
            height: 200,
            cursor: "pointer",
          }}
        />
        <ImageModal ref={imageModalRef} storageFacility={storageFacility} />
      </div>

      <MapModal storageFacility={storageFacility} />
      <HoursModal storageFacility={storageFacility} />
    </div>
  );
};

export default NameAndPictureDetails;
