import React from "react";
import Container from "../../../shared-components/container/Container";

const Commercial = () => {
  return (
    <div>
      <Container>
        <h1>Business and Commercial</h1>
      </Container>
    </div>
  );
};

export default Commercial;
