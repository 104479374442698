import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { IoIosInformationCircleOutline } from "react-icons/io";

import { updateRoom } from "../../../../actions/roomsActions";
import { Tooltip } from "react-tippy";

const EditUnitForm = ({ room, storageId, roomId, promotions }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userLogin);

  // arranging initial promotions here to show in the select
  const uipromo = (room.storage_promotions || []).map((item) => ({
    value: item.id?.toString(),
    label: item.title,
  }));
  const [selectedOptions, setSelectedOptions] = useState();

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.number()
      .required("Unit Id is required")
      .min(1, "Must be greater than 0"),
    size: Yup.number().required("Size is required"),
    per_unit_price: Yup.number()
      .required("Unit Price is required")
      .min(1, "Price must be greater than 0"),
    description: Yup.string().required("Description is required"),
    storage_unit_type: Yup.string().required("Storage Type is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: room.name,
      size: room.size,
      per_unit_price: room.per_unit_price,
      description: room.description,
      storage_unit_type: room.storage_unit_type,
      is_available: room.is_available,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const updatedPromotions = selectedOptions
        ? selectedOptions.map((item) => parseInt(item.value))
        : room.storage_promotions.map((item) => parseInt(item.id));
      dispatch(
        updateRoom(roomId, {
          ...values,
          promotion_ids: updatedPromotions,
        }),
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Unit ID */}
      <div className="form-group">
        <label>Unit Id</label>
        <input
          type="number"
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`form-control ${
            formik.touched.name && formik.errors.name ? "is-invalid" : ""
          }`}
          placeholder="Storage Unit Number"
        />
        {formik.touched.name && formik.errors.name && (
          <div className="text-danger">{formik.errors.name}</div>
        )}
      </div>
      {/* Unit Size */}
      <div className="form-group">
        <label>Size</label>
        <select
          className={`form-select ${formik.touched.size && formik.errors.size ? "is-invalid" : ""}`}
          id="size"
          name="size"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.size}
        >
          <option value="">Choose size</option>
          {[
            10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
            95, 100,
          ].map((size) => (
            <option key={size} value={size * size}>
              {size} x {size}
            </option>
          ))}
        </select>
        {formik.touched.size && formik.errors.size && (
          <div className="text-danger">{formik.errors.size}</div>
        )}
      </div>
      {/* Unit Price */}
      <div className="form-group">
        <label>Unit Price</label>
        <input
          type="number"
          name="per_unit_price"
          id="per_unit_price"
          value={formik.values.per_unit_price}
          className={`form-control ${
            formik.touched.per_unit_price && formik.errors.per_unit_price
              ? "is-invalid"
              : ""
          }`}
          placeholder="Unit Price"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.per_unit_price && formik.errors.per_unit_price && (
          <div className="text-danger">{formik.errors.per_unit_price}</div>
        )}
      </div>
      {/* Description */}
      <div className="form-group">
        <label>Description</label>
        <textarea
          id="description"
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`form-control ${
            formik.touched.description && formik.errors.description
              ? "is-invalid"
              : ""
          }`}
          placeholder="Write the description of the Storage Unit"
          value={formik.values.description}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <div className="text-danger">{formik.errors.description}</div>
        )}
      </div>
      {/* Storage Type */}
      <div className="form-group">
        <label>Storage Type</label>
        <select
          className={`form-select ${
            formik.touched.storage_unit_type && formik.errors.storage_unit_type
              ? "is-invalid"
              : ""
          }`}
          id="storage_unit_type"
          name="storage_unit_type"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.storage_unit_type}
        >
          <option value="">Choose Type</option>
          <option value="indoor_storage">Indoor Storage</option>
          <option value="outdoor">Outdoor Storage</option>
          <option value="climate_control">Climate Control</option>
        </select>
        {formik.touched.storage_unit_type &&
          formik.errors.storage_unit_type && (
            <div className="text-danger">{formik.errors.storage_unit_type}</div>
          )}
      </div>
      {/* Is Available */}
      {room.is_occupied ? (
        ""
      ) : (
        <div className="form-group my-3">
          <Tooltip
            title={
              !user.is_stripe_account_active
                ? "Please connect your stripe account"
                : ""
            }
            position="right"
            trigger="mouseenter"
            disabled={user.is_stripe_account_active}
          >
            <label
              className={`${!user.is_stripe_account_active && "text-muted"}`}
            >
              Available
            </label>
            <input
              type="checkbox"
              name="is_available"
              checked={formik.values.is_available}
              onChange={formik.handleChange}
              className="form-check-input p-2 mx-2"
              disabled={!user.is_stripe_account_active}
            />
          </Tooltip>
        </div>
      )}
      {/* Promotion Select */}
      <div className="form-group">
        <label>Select Promotions</label>
        <Select
          isMulti
          closeMenuOnSelect={false}
          name="promotion_ids"
          value={selectedOptions || uipromo}
          options={promotions.map((promo) => ({
            value: promo.id.toString(),
            label: promo.title,
          }))}
          onChange={handleSelectChange}
          className="basic-single"
          classNamePrefix="select"
        />
      </div>
      {/* submit button */}
      <button
        type="submit"
        disabled={room.is_occupied}
        className="btn btn-dark text-dark mt-2"
      >
        Submit
      </button>
      {/* Show messag if occupied */}
      {room.is_occupied ? (
        <div className="text-danger my-2">
          <IoIosInformationCircleOutline size={25} className="me-2" />
          Storage unit is already booked. You cannot update it right now.
        </div>
      ) : (
        ""
      )}
    </form>
  );
};

export default EditUnitForm;
