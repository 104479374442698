import axios from "axios";
import { toast } from "react-toastify";
import {
  ALL_ORDERS_ADMIN_FAIL,
  ALL_ORDERS_ADMIN_REQUEST,
  ALL_ORDERS_ADMIN_SUCCESS,
  ALL_ORDERS_FAIL,
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_SUCCESS,
  COMPLETED_ORDERS_ADMIN_FAIL,
  COMPLETED_ORDERS_ADMIN_REQUEST,
  COMPLETED_ORDERS_ADMIN_SUCCESS,
  CREATE_RATING_FAIL,
  CREATE_RATING_REQUEST,
  CREATE_RATING_SUCCESS,
  CURRENT_ORDERS_ADMIN_FAIL,
  CURRENT_ORDERS_ADMIN_REQUEST,
  CURRENT_ORDERS_ADMIN_SUCCESS,
  DELETE_ORDERS_ADMIN_FAIL,
  DELETE_ORDERS_ADMIN_REQUEST,
  DELETE_ORDERS_ADMIN_SUCCESS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  ORDER_DETAILS_ADMIN_FAIL,
  ORDER_DETAILS_ADMIN_REQUEST,
  ORDER_DETAILS_ADMIN_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  PLACE_ORDER_FAIL,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  UPDATE_ORDERS_ADMIN_FAIL,
  UPDATE_ORDERS_ADMIN_REQUEST,
  UPDATE_ORDERS_ADMIN_SUCCESS,
  UPDATE_RATING_FAIL,
  UPDATE_RATING_REQUEST,
  UPDATE_RATING_SUCCESS,
} from "../constants/ordersConstants";

import { cartGetItems } from "./cartActions";
import { API_URL } from "./userActions";

export const requestPayout =
  (flag1, flag2, id) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      // console.log(orderID);

      const { data } = await axios.post(
        `${API_URL}/orders/request-payout/${id}/`,
        {
          contract_completed: flag1,
          handover_storageunit: flag2,
        },
        config,
      );
      console.log(data);
      toast.success("Payout requested");
    } catch (error) {
      console.error(error);
      toast.error("Failed to request payout");
    }
  };

export const placeOrder = (orderData) => async (dispatch, getState) => {
  try {
    dispatch({ type: PLACE_ORDER_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${API_URL}/orders/order-place/`,
      orderData,
      config,
    );
    window.open(data.url, "_blank");
    dispatch({ type: PLACE_ORDER_SUCCESS, payload: data });
    dispatch(cartGetItems());
  } catch (error) {
    dispatch({
      type: PLACE_ORDER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getAllOrders = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ALL_ORDERS_REQUEST });
    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    let apiUrl = `${API_URL}/orders/get-order-user/`;

    // Check if there's any query parameter to add
    if (
      formData.search !== "" ||
      formData.business_type !== "" ||
      formData.page
    ) {
      apiUrl += "?"; // Start building the query string
      const queryParams = [];

      if (formData.search !== "") {
        queryParams.push(`search=${formData.search}`);
      }

      if (formData.business_type !== "Select Type") {
        queryParams.push(`business_type=${formData.business_type}`);
      }

      if (formData.page) {
        queryParams.push(`page=${formData.page}`);
      }

      apiUrl += queryParams.join("&"); // Join query parameters with '&'
    }

    const { data } = await axios.get(apiUrl, config);

    dispatch({ type: ALL_ORDERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_ORDERS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// GET Order Details
export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/orders/get-detail-order-user/${id}/`,
      config,
    );

    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete Order
export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ORDER_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(
      `${API_URL}/orders/order/storage/${id}/`,
      config,
    );

    let msg = "";
    if (!data) {
      msg = "Deleted Successfully";
    }

    dispatch({ type: DELETE_ORDER_SUCCESS, payload: msg });
  } catch (error) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Admin
export const getAllOrdersAdmin = (options) => async (dispatch, getState) => {
  try {
    dispatch({ type: ALL_ORDERS_ADMIN_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    // Initialize the API URL with the base path
    let apiUrl = `${API_URL}/orders/get-order-admin/?`;

    // Add the pending parameter if it's true
    if (options.pending) {
      apiUrl += "pending=true&";
    }

    // Add the completed parameter if it's true
    if (options.completed) {
      apiUrl += "completed=true&";
    }

    // Add the starting_date parameter if it exists
    if (options.starting_date) {
      apiUrl += `starting_date=${options.starting_date}&`;
    }

    // Add the ending_date parameter if it exists
    if (options.ending_date) {
      apiUrl += `ending_date=${options.ending_date}&`;
    }

    // Add the search parameter if it exists
    if (options.search) {
      apiUrl += `search=${options.search}&`;
    }

    // Add the page parameter if it exists
    if (options.page) {
      apiUrl += `page=${options.page}&`;
    }

    const { data } = await axios.get(apiUrl, config);

    dispatch({ type: ALL_ORDERS_ADMIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_ORDERS_ADMIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Update order admin
export const updateOrderAdmin =
  (id, orderData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_ORDERS_ADMIN_REQUEST });

      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const { data } = await axios.patch(
        `${API_URL}/orders/update-retrieve-order-admin/${id}/`,
        orderData,
        config,
      );

      dispatch({ type: UPDATE_ORDERS_ADMIN_SUCCESS, payload: data });

      dispatch(getAllOrdersAdmin());
    } catch (error) {
      dispatch({
        type: UPDATE_ORDERS_ADMIN_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// Create Rating
export const createRating = (id, ratingData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_RATING_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data, status } = await axios.post(
      `${API_URL}/units/create-ratings/${id}/`,
      ratingData,
      config,
    );
    dispatch({ type: CREATE_RATING_SUCCESS, payload: { data, status } });
  } catch (error) {
    dispatch({
      type: CREATE_RATING_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

//Update Feedback
export const updateFeedback = (id, idDetails) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_RATING_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.patch(
      `${API_URL}/orders/update-order-user/${id}/`,
      {
        feedback: true,
      },
      config,
    );

    dispatch({ type: UPDATE_RATING_SUCCESS, payload: data });
    dispatch(getOrderDetails(idDetails));
  } catch (error) {
    dispatch({
      type: UPDATE_RATING_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete Order Admin
export const deleteOrderAdmin = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ORDERS_ADMIN_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(
      `${API_URL}/orders/order/admin_storage/${id}/`,
      config,
    );

    let msg = "";
    if (!data) {
      msg = "Deleted Successfully";
    }

    dispatch({ type: DELETE_ORDERS_ADMIN_SUCCESS, payload: msg });

    dispatch(getAllOrdersAdmin());
  } catch (error) {
    dispatch({
      type: DELETE_ORDERS_ADMIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// GET Admin Orders Details
export const getOrderDetailsAdmin = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAILS_ADMIN_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/orders/update-retrieve-order-admin/${id}/`,
      config,
    );

    dispatch({ type: ORDER_DETAILS_ADMIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_ADMIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Get current orders admin
export const getCurrentOrdersAdmin = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENT_ORDERS_ADMIN_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/orders/admin-current-orders/`,
      config,
    );

    dispatch({ type: CURRENT_ORDERS_ADMIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CURRENT_ORDERS_ADMIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Get completed Orders
export const getCompletedOrdersAdmin = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COMPLETED_ORDERS_ADMIN_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/orders/admin-completed-orders/`,
      config,
    );

    dispatch({ type: COMPLETED_ORDERS_ADMIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPLETED_ORDERS_ADMIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
