import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import Container from "../../../shared-components/container/Container";
import { logoutUser } from "../../../actions/userActions";
import { API_URL } from "../../../actions/userActions";

const StripeStatus = () => {
  const { user } = useSelector((state) => state.userLogin);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const accessToken = user.access;

  function redirectToStripe() {
    dispatch(logoutUser());
    window.open(user.stripe_data.url, "noopener noreferrer");
  }

  async function signContract() {
    setLoading(true);
    const { data } = await axios.get(`${API_URL}/auth/generate-signing-url/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(data.signing_url);
    window.open(data.signing_url, "_blank");
    setLoading(false);
  }
  const getStatusMessage = () => {
    if (user) {
      if (user.is_stripe_account_pending) {
        return (
          <div className="text-center">
            <p>
              Stripe Account is Pending. Please wait for the admin to approve
              it.
            </p>
          </div>
        );
      } else if (user.is_stripe_account_inactive) {
        return (
          <div className="text-center">
            <p>Please click on the button below to activate it</p>
            <button className="btn btn-primary" onClick={redirectToStripe}>
              Activate Stripe Account
            </button>
          </div>
        );
      } else if (!user.is_stripe_account_active) {
        return (
          <div className="text-center">
            <h3 className="text-center">StripeStatus</h3>
            <p>Please click on the button below to activate it</p>
            <button className="btn btn-primary" onClick={redirectToStripe}>
              Activate Stripe Account
            </button>
          </div>
        );
      }
    }
    return (
      <div className="text-center">
        <p>No Stripe Account Status Available</p>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
      {!user.is_docusign_completed ? (
        <div className="text-center my-3">
          <h3>Contract</h3>
          <p>Click on button below to sign contract</p>
          <button className="btn btn-primary" onClick={signContract}>
            Sign Contract{"  "}{" "}
            {loading && <Spinner animation="border" size="sm" />}
          </button>
        </div>
      ) : (
        ""
      )}
      <Container>{getStatusMessage()}</Container>
    </div>
  );
};

export default StripeStatus;
