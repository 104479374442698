import React from "react";

const CustomersPage = () => {
  return (
    <div>
      <h1>Customers Page</h1>
    </div>
  );
};

export default CustomersPage;
