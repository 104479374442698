import { de } from "date-fns/locale";
import {
  GET_ALL_STORAGE_UNITS_REQUEST,
  GET_ALL_STORAGE_UNITS_SUCCESS,
  GET_ALL_STORAGE_UNITS_FAIL,
  GET_SINGLE_STORAGE_UNITS_FAIL,
  GET_SINGLE_STORAGE_UNITS_REQUEST,
  GET_SINGLE_STORAGE_UNITS_SUCCESS,
  GET_TOTAL_STORAGE_UNITS_FAIL,
  GET_TOTAL_STORAGE_UNITS_REQUEST,
  GET_TOTAL_STORAGE_UNITS_SUCCESS,
  GET_AVAILABLE_STORAGE_UNITS_REQUEST,
  GET_AVAILABLE_STORAGE_UNITS_SUCCESS,
  GET_AVAILABLE_STORAGE_UNITS_FAIL,
  GET_OCCUPIED_STORAGE_UNITS_REQUEST,
  GET_OCCUPIED_STORAGE_UNITS_SUCCESS,
  GET_OCCUPIED_STORAGE_UNITS_FAIL,
  CREATE_STORAGE_UNIT_REQUEST,
  CREATE_STORAGE_UNIT_SUCCESS,
  CREATE_STORAGE_UNIT_FAIL,
  DELETE_STORAGE_UNIT_REQUEST,
  DELETE_STORAGE_UNIT_SUCCESS,
  DELETE_STORAGE_UNIT_FAIL,
  UPDATE_STORAGE_UNIT_REQUEST,
  UPDATE_STORAGE_UNIT_SUCCESS,
  UPDATE_STORAGE_UNIT_FAIL,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAIL,
  DELETE_FEATURE_REQUEST,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAIL,
  ADD_NEW_FEATURE_REQUEST,
  ADD_NEW_FEATURE_SUCCESS,
  ADD_NEW_FEATURE_FAIL,
  UPDATE_FEATURE_FAIL,
  UPDATE_FEATURE_SUCCESS,
  UPDATE_FEATURE_REQUEST,
  UPDATE_IMAGE_FAIL,
  UPDATE_IMAGE_REQUEST,
  UPDATE_IMAGE_SUCCESS,
  GET_DISTINCTIVE_STORAGE_UNITS_FAIL,
  GET_DISTINCTIVE_STORAGE_UNITS_REQUEST,
  GET_DISTINCTIVE_STORAGE_UNITS_SUCCESS,
} from "../constants/storageUnitConstants";
import { postNotfication } from "./notificationActions";

import { API_URL } from "./userActions";
import axios from "axios";

// GET ALL STORAGE UNITS (SERVICE PROVIDER)
export const getAllStorageUnits = (page) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_STORAGE_UNITS_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/units/storage/storage_facility/?page=${page ? page : 1}`,
      config,
    );

    console.log("API storage units", data);

    dispatch({ type: GET_ALL_STORAGE_UNITS_SUCCESS, payload: data });
  } catch (error) {
    const {
      userLogin: { user },
    } = getState();
    dispatch({
      type: GET_ALL_STORAGE_UNITS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// GET SINGLE STORAGE UNITS (SERVICE PROVIDER)
export const getSingleStorageUnit = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SINGLE_STORAGE_UNITS_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/units/storage/storage_facility/${id}/`,
      config,
    );

    dispatch({ type: GET_SINGLE_STORAGE_UNITS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_STORAGE_UNITS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// CREATE NEW STORAGE UNIT (SERVICE PROVIDER)
export const createStorageUnit = (unitData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_STORAGE_UNIT_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${API_URL}/units/storage/storage_facility/`,
      unitData,
      config,
    );

    dispatch({ type: CREATE_STORAGE_UNIT_SUCCESS, payload: data });
    const notificationData = {
      title: `New storage facility added`,
      body: `facility Name: ${data.name}`,
      url: `http://3.82.183.189`,
      is_read: false,
    };
    dispatch(postNotfication(notificationData));
  } catch (error) {
    dispatch({
      type: CREATE_STORAGE_UNIT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// CREATE NEW FEATURE
export const createNewFeature = (unitData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_NEW_FEATURE_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data, status } = await axios.post(
      `${API_URL}/units/create-feature/`,
      unitData,
      config,
    );
    dispatch({ type: ADD_NEW_FEATURE_SUCCESS, payload: { data, status } });
  } catch (error) {
    dispatch({
      type: ADD_NEW_FEATURE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// UPDATE  STORAGE UNIT (SERVICE PROVIDER)
export const updateStorageUnit =
  (id, unitData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_STORAGE_UNIT_REQUEST });

      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const { data } = await axios.patch(
        `${API_URL}/units/storage/storage_facility/${id}/`,
        unitData,
        config,
      );

      dispatch({ type: UPDATE_STORAGE_UNIT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: UPDATE_STORAGE_UNIT_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// Update a specific Feature
export const updateFeature = (id, unitData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_FEATURE_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { status } = await axios.put(
      `${API_URL}/units/update-destroy-feature/${id}/`,
      {
        features_text: unitData,
      },
      config,
    );

    dispatch({ type: UPDATE_FEATURE_SUCCESS, payload: { status } });
  } catch (error) {
    dispatch({
      type: UPDATE_FEATURE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// DELETE STORAGE UNIT (SERVICE PROVIDER)
export const deleteStorageUnit = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_STORAGE_UNIT_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(
      `${API_URL}/units/storage/storage_facility/${id}/`,
      config,
    );

    dispatch({ type: DELETE_STORAGE_UNIT_SUCCESS });
    dispatch(getAllStorageUnits());
  } catch (error) {
    dispatch({
      type: DELETE_STORAGE_UNIT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete Order Image
export const deleteImage = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_IMAGE_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { status } = await axios.delete(
      `${API_URL}/units/destroy-image/${id}/`,
      config,
    );

    dispatch({ type: DELETE_IMAGE_SUCCESS, payload: status });
  } catch (error) {
    dispatch({
      type: DELETE_IMAGE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete Feature

export const deleteFeature = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_FEATURE_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(
      `${API_URL}/units/update-destroy-feature/${id}/`,
      config,
    );

    let msg = "";
    if (!data) {
      msg = " Feature Deleted Successfully";
    } else {
      msg = " Error Occured While Deleting Feature";
    }

    dispatch({ type: DELETE_FEATURE_SUCCESS, payload: msg });
  } catch (error) {
    dispatch({
      type: DELETE_FEATURE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// GET TOTAL STORAGE UNITS (SERVICE PROVIDER)
export const getTotalStorageUnits =
  (selectedFilters) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TOTAL_STORAGE_UNITS_REQUEST });
      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      let apiUrl = `${API_URL}/units/total-storage-unit/?`;

      if (selectedFilters.is_available) {
        apiUrl += "is_available=true&";
      }
      apiUrl += `&page=${selectedFilters.page || 1}`;
      if (selectedFilters.search) {
        apiUrl += `&search=${selectedFilters.search}`;
      }
      if (selectedFilters.name) {
        apiUrl += `&name=${selectedFilters.name}`;
      }
      if (selectedFilters.storage_facility__name) {
        apiUrl += `&storage_facility__name=${selectedFilters.storage_facility__name} `;
      }
      const { data } = await axios.get(apiUrl, config);

      dispatch({ type: GET_TOTAL_STORAGE_UNITS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_TOTAL_STORAGE_UNITS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// GET AVAILABLE STORAGE UNITS (SERVICE PROVIDER)
export const getAvailableStorageUnits =
  (selectedFilters) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_AVAILABLE_STORAGE_UNITS_REQUEST });

      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      let apiUrl = `${API_URL}/units/available-storage-unit/?`;

      if (selectedFilters.is_available) {
        apiUrl += "is_available=true&";
      }
      if (selectedFilters.name) {
        apiUrl += `&name=${selectedFilters.name}`;
      }
      if (selectedFilters.storage_facility__name) {
        apiUrl += `&storage_facility__name=${selectedFilters.storage_facility__name} `;
      }
      apiUrl += `&page=${selectedFilters.page || 1}`;
      const { data } = await axios.get(apiUrl, config);

      dispatch({ type: GET_AVAILABLE_STORAGE_UNITS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_AVAILABLE_STORAGE_UNITS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

// GET OCCUPIED STORAGE UNITS (SERVICE PROVIDER)
export const getOccupiedStorageUnits =
  (selectedFilters) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_OCCUPIED_STORAGE_UNITS_REQUEST });

      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      let apiUrl = `${API_URL}/units/occupied-storage-unit/?`;

      if (selectedFilters.is_available) {
        apiUrl += "is_available=true&";
      }
      if (selectedFilters.name) {
        apiUrl += `&name=${selectedFilters.name}`;
      }
      if (selectedFilters.storage_facility__name) {
        apiUrl += `&storage_facility__name=${selectedFilters.storage_facility__name} `;
      }
      apiUrl += `&page=${selectedFilters.page || 1}`;
      const { data } = await axios.get(apiUrl, config);

      dispatch({ type: GET_OCCUPIED_STORAGE_UNITS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_OCCUPIED_STORAGE_UNITS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

//Update Image

export const updateImage = (unitData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_IMAGE_REQUEST });

    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { status } = await axios.post(
      `${API_URL}/units/create-image/`,
      unitData,
      config,
    );

    dispatch({ type: UPDATE_IMAGE_SUCCESS, payload: status });
  } catch (error) {
    dispatch({
      type: UPDATE_IMAGE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// GET DISTINCTIVE STORAGE UNITS

export const getDistinctiveStorageUnits = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_DISTINCTIVE_STORAGE_UNITS_REQUEST });
    console.log("gettingrequest");
    const {
      userLogin: { user },
    } = getState();

    const accessToken = user.access;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${API_URL}/units/get-storage-unit/`,
      config,
    );

    dispatch({ type: GET_DISTINCTIVE_STORAGE_UNITS_SUCCESS, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_DISTINCTIVE_STORAGE_UNITS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
