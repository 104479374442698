import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";

import Spinner from "../../../../shared-components/Spinner/Spinner";

function PromotionsTable({
  promotions,
  editPromotionHandler,
  deletePromotionHandler,
}) {
  const [unitsQuantity, setUnitsQuantity] = useState(0);
  const [rowInfo, setRowInfo] = useState({
    index: -1,
    promo_id: 0,
  });
  function deletePromotionModal(index, row) {
    setUnitsQuantity(row.storage_unit_ids.length);
    setRowInfo({ index: index, promo_id: row.id });
  }
  return (
    <div className="card mt-4 ">
      {promotions ? (
        <div className="card-body">
          <table className="table table-hover mt-2">
            <thead className="text-center">
              <tr>
                <th scope="col" style={{ width: "20rem" }}>
                  Name
                </th>
                <th scope="col" className="text-nowrap">
                  Promotion Type
                </th>
                <th scope="col" className="text-nowrap">
                  Total Months
                </th>
                <th scope="col" className="text-nowrap">
                  Offer Months
                </th>
                <th scope="col" style={{ width: "10rem" }}>
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="text-center">
              {promotions.map((row, index) => (
                <tr key={index}>
                  <td className=" text-break" style={{ width: "20rem" }}>
                    {row.title}
                  </td>
                  <td>{row.type}</td>
                  <td>{row.total_months}</td>
                  <td>{row.offer_months}</td>
                  <td>
                    <span
                      className="border-0 bg-none text-info m-1"
                      onClick={() => editPromotionHandler(index)}
                      // onClick={() => editPromotionHandler(index, row.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <MdModeEdit size={25} />
                    </span>
                    <span
                      className="border-0 bg-none text-danger m-1"
                      // onClick={() => deletePromotionHandler(index, row.id)}
                      onClick={() => deletePromotionModal(index, row)}
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#deletePromotionModal"
                    >
                      <MdDelete size={25} />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Spinner />
      )}

      {/* Delete promotion modal */}
      <div
        className="modal fade"
        id="deletePromotionModal"
        tabIndex={-1}
        aria-labelledby="deletePromotionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Delete Promotion
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              Are you sure you want to delete this promotion?
              <p>No. of Attached Storage Units: {unitsQuantity}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() =>
                  deletePromotionHandler(rowInfo.index, rowInfo.promo_id)
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PromotionsTable;
