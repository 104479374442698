import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const PayoutModal = ({ showModal, setShowModal, handlePayout, orderID }) => {
  const [signContractCheckbox, setSignContractCheckbox] = useState(false);
  const [handoverKeysCheckbox, setHandoverKeysCheckbox] = useState(false);

  function handleConfirmPayout() {
    handlePayout(signContractCheckbox, handoverKeysCheckbox);
    setSignContractCheckbox(false);
    setHandoverKeysCheckbox(false);
  }
  function handleCancelPayout() {
    setSignContractCheckbox(false);
    setHandoverKeysCheckbox(false);
    setShowModal(false);
  }
  return (
    <>
      <Modal show={showModal} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Payout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Before proceeding with <b>Order#{orderID}</b> payout, please check the
          check boxes below.
          <Form className="my-2">
            <Form.Check
              inline
              label="I and Renter signed the contract."
              checked={signContractCheckbox}
              onChange={(e) => setSignContractCheckbox(e.target.checked)}
            />
            <Form.Check
              inline
              label="I handed over the keys/access codes."
              checked={handoverKeysCheckbox}
              onChange={(e) => setHandoverKeysCheckbox(e.target.checked)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-dark"
            onClick={handleCancelPayout}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="text-dark"
            onClick={handleConfirmPayout}
            disabled={!signContractCheckbox || !handoverKeysCheckbox}
          >
            Confirm Payout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PayoutModal;
