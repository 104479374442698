import HomeClientsTestimonials from "../Home/HomeClientsTestimonials/HomeClientsTestimonials";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
const AboutUs = () => {
  return (
    <>
      <div className="container text-center justify-content-center d-flex flex-column">
        <h1
          className="  mt-5 "
          style={{
            color: "#FFCC00",
          }}
        >
          Elevate Your Storage Game: Your Goods, Our Safe Haven
        </h1>
        <p className="text-dark my-4 ">
          Experience the pinnacle of storage convenience with FindxStorage –
          your trusted partner for all your storage requirements. Simplify your
          life and safeguard your belongings with ease
        </p>
        <div className="row no-gutters justify-content-center align-items-center my-4">
          {/* <div className="col-md-4">
          <input
            type="text"
            placeholder="Search"
            className="form-control mb-2"
          />
        </div> */}
        </div>
      </div>

      <div className="container my-5">
        <div className="row justify-content-between align-items-start pl-3">
          <div className="col-lg-6">
            <img
              src="/images/AboutImage.png"
              height={180}
              width={400}
              alt="About Image"
            />
            <p className="font-weight-bold h3 orange-color">
              Why should you work with us?
            </p>
            <p className="h2 text-dark pt-1 pb-1">
              Your trusted choice for premium storage solutions.
            </p>
            <p className="text-muted">
              Choose FindxStorage for top-quality climate-controlled storage
              solutions, ensuring the utmost security and convenience for your
              valued possessions. Simplify storage, experience excellence.
            </p>
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-start align-items-start">
            <div className="row">
              <div
                className="col-md-12 mb-4 d-flex flex-row justify-content-center align-items-center"
                style={{
                  gap: "20px",
                }}
              >
                <img
                  src="/images/arrow.svg"
                  alt="Arrow"
                  className="text-primary h4 mr-2"
                  style={{ maxWidth: "30px", height: "auto" }}
                />
                <p className="text-muted">
                  Secure & Climate-Controlled: Keep your belongings safe in our
                  weather-proof units.
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 mb-4 d-flex flex-row justify-content-center align-items-center"
                style={{
                  gap: "20px",
                }}
              >
                <img
                  src="/images/arrow.svg"
                  alt="Arrow"
                  className="text-primary h4 mr-2"
                  style={{ maxWidth: "30px", height: "auto" }}
                />
                <p className="text-muted">
                  Versatile Storage: Choose from various indoor and outdoor
                  options.
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 mb-4 d-flex flex-row justify-content-center align-items-center"
                style={{
                  gap: "20px",
                }}
              >
                <img
                  src="/images/arrow.svg"
                  alt="Arrow"
                  className="text-primary h4 mr-2"
                  style={{ maxWidth: "30px", height: "auto" }}
                />
                <p className="text-muted">
                  Expert Assistance: Our team ensures a hassle-free storage
                  experience.
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 mb-4 d-flex flex-row justify-content-center align-items-center"
                style={{
                  gap: "20px",
                }}
              >
                <img
                  src="/images/arrow.svg"
                  alt="Arrow"
                  className="text-primary h4 mr-2"
                  style={{ maxWidth: "30px", height: "auto" }}
                />
                <p className="text-muted">
                  Narrow your search by location, price, size or amenities
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 mb-4 d-flex flex-row justify-content-center align-items-center"
                style={{
                  gap: "20px",
                }}
              >
                <img
                  src="/images/arrow.svg"
                  alt="Arrow"
                  className="text-primary h4 mr-2"
                  style={{ maxWidth: "30px", height: "auto" }}
                />
                <p className="text-muted">
                  Complete your storage rental online, if available
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 mb-4 d-flex flex-row justify-content-center align-items-center"
                style={{
                  gap: "20px",
                }}
              >
                <img
                  src="/images/arrow.svg"
                  alt="Arrow"
                  className="text-primary h4 mr-2"
                  style={{ maxWidth: "30px", height: "auto" }}
                />
                <p className="text-muted">
                  Visit the storage facility and move into your unit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Home Clients Testimonials */}
      <section id="clients" className="py-5">
        <h1 className="text-center mb-5">
          See What Our <span className="mineTextOrange">Clients</span> Says
          about us
        </h1>
        <HomeClientsTestimonials />
      </section>

      <section style={{ margin: "auto" }} className=" mt-3 mb-5">
        <div
          className="container mt-3 md:mt-5 px-4 py-5 col-10 "
          style={{ borderRadius: "30px" }}
        >
          <div className="text-center">
            <p className="h4 fw-bold text-dark pb-3">High Quality</p>
            <p className="h2 orange-color pb-5">
              Discover the Ideal Storage Solution for Your Needs.
            </p>
          </div>

          <div className="row justify-content-center align-items-center mt-auto pt-2 md:pt-5">
            <div className="col-md-4 mb-5">
              <img src="/images/safe.svg" alt="icon" className="img-fluid" />
              <p className="h4 font-weight-bold  py-3">
                High security to protect from piracy
              </p>
              <p className="text-dark">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy.
              </p>
            </div>
            <div className="col-md-4 mb-5">
              <img src="/images/thumb.svg" alt="icon" className="img-fluid" />
              <p className="h4 font-weight-bold  py-3">
                High security to protect from piracy
              </p>
              <p className="text-dark">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy.
              </p>
            </div>
            <div className="col-md-4 mb-5">
              <img
                src="/images/headphone.svg"
                alt="icon"
                className="img-fluid"
              />
              <p className="h4 font-weight-bold  py-3">
                High security to protect from piracy
              </p>
              <p className="text-dark">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="col-10 mb-5" style={{ margin: "auto" }}>
        <p className="h4 fw-bold text-dark pb-5 text-center">FAQ'S</p>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              What sizes of storage units do you offer?
            </Accordion.Header>
            <Accordion.Body>
              We offer a variety of storage unit sizes to accommodate your
              needs, ranging from small lockers to large warehouse-sized units.
              Our team can help you determine the right size for your
              belongings.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How secure are the storage units?
            </Accordion.Header>
            <Accordion.Body>
              Security is a top priority for us. Our facilities are equipped
              with state-of-the-art security features, including 24/7
              surveillance, access control systems, and well-lit premises. Your
              belongings are safe and protected.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Do you offer climate-controlled storage units
            </Accordion.Header>
            <Accordion.Body>
              Yes, we provide climate-controlled storage units to safeguard your
              sensitive items from extreme temperature fluctuations. These units
              maintain a consistent temperature and humidity level, ensuring
              your belongings remain in pristine condition.{" "}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Are there both indoor and outdoor storage unit options available
            </Accordion.Header>
            <Accordion.Body>
              Absolutely! We offer a variety of storage solutions to cater to
              your preferences. You can choose from indoor units for added
              protection or outdoor units for convenient access. We have options
              to suit every need.{" "}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>What is self-storage?</Accordion.Header>
            <Accordion.Body>
              known as storage facilities, offer rental spaces, commonly
              referred to as storage units or lockers, on a short-term basis,
              typically month-to-month.{" "}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>

      <div className="align-items-center justify-content-center d-flex mb-5 ">
        <Link
          to="/"
          style={{ textDecoration: "none" }}
          className="btn text-dark"
        >
          {/* <button className="btn" style={{textDecoration:"none"}}>Explore</button> */}
          Explore
        </Link>
      </div>
    </>
  );
};

export default AboutUs;
