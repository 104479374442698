import { useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

function EditPromotionModal({
  editPromotion,
  setEditPromotion,
  editFieldsHandler,
  storageUnits,
  showEditPromoModal,
  setShowEditPromoModal,
}) {
  const uipromo = (editPromotion.storage_unit_ids || []).map((item) => ({
    value: item.id?.toString(),
    label: item.name,
  }));

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditPromotion({
      ...editPromotion,
      [name]: value,
    });
  };

  const [selectedOptions, setSelectedOptions] = useState();
  const handleSelectChange = (selectedValues) => {
    const arrayOfValues = selectedValues.map((item) => ({
      id: parseInt(item.value),
    }));

    setSelectedOptions(selectedValues);

    setEditPromotion({
      ...editPromotion,
      storage_unit_ids: arrayOfValues,
    });
  };
  const handleCloseModal = () => {
    setShowEditPromoModal(false);
  };

  const totalMonthsOptions = Array.from(
    { length: 12 },
    (_, index) => index + 1,
  );

  return (
    <Modal show={showEditPromoModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Promotion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            ...editPromotion,
          }}
          validationSchema={Yup.object({
            title: Yup.string()
              .max(50, "Must be 50 characters or less")
              .required("Required"),
            total_months: Yup.number()
              .moreThan(0, "Please select Month/s")
              .required("Required"),
            offer_months: Yup.number()
              .moreThan(0, "Please select Month/s")
              .when(["total_months"], (total_months, schema) =>
                total_months
                  ? schema.max(
                      total_months,
                      "Offer months must be less than or equal to total months",
                    )
                  : schema,
              )
              .required("Required"),
            type: Yup.string()
              .min(1, "Please select Promotion Type")
              .required("Please select Promotion Type"),
            value: Yup.number()
              .moreThan(0, "Please select a value")
              .required("Required"),
          })}
          onSubmit={(values) => {
            const data = {
              ...values,
              storage_unit_ids: editPromotion.storage_unit_ids.map(
                (obj) => obj.id,
              ),
              facility_id: editPromotion.facility_id,
            };

            editFieldsHandler(data);
            handleCloseModal();
          }}
        >
          {({ errors, values }) => (
            <Form>
              <div className=" py-1 ">
                <div className="col mx-4">
                  <div className="row py-1">
                    <label htmlFor="title">Promotion Name</label>
                    <Field name="title" className="form-control" />
                    {errors.title ? (
                      <div className="text-danger">{errors.title}</div>
                    ) : null}
                  </div>
                  <div className="row py-1">
                    <label htmlFor="total_months">Total Months</label>
                    <Field
                      name="total_months"
                      as="select"
                      className="form-select"
                    >
                      <option value={0}>Choose Months</option>
                      {totalMonthsOptions.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </Field>
                    {errors.total_months ? (
                      <div className="text-danger">{errors.total_months}</div>
                    ) : null}
                  </div>
                  <div className="row py-1">
                    <label htmlFor="offer_months">Offer Months</label>
                    <Field
                      name="offer_months"
                      as="select"
                      className="form-select"
                    >
                      <option value={0}>Choose Months</option>
                      {totalMonthsOptions.map((value) =>
                        value > values.total_months ? null : (
                          <option
                            key={value}
                            value={value}
                            disabled={value > values.total_months}
                          >
                            {value}
                          </option>
                        ),
                      )}
                    </Field>
                    {errors.offer_months ? (
                      <div className="text-danger">{errors.offer_months}</div>
                    ) : null}
                  </div>
                  <div className="row py-1">
                    <label htmlFor="offer_months">Type Of Promotion</label>
                    <Field name="type" as="select" className="form-select">
                      <option value="">-</option>
                      <option value="percentage_off">Discounted %</option>
                      <option value="dollar_off">Discounted $</option>
                      <option value="initial_discount_price">
                        Total Discounted $
                      </option>
                    </Field>
                    {errors.type ? (
                      <div className="text-danger">{errors.type}</div>
                    ) : null}
                  </div>
                  <div className="row py-1">
                    <label htmlFor="value">Value</label>
                    <Field
                      type="number"
                      name="value"
                      className="form-control"
                    />
                    {errors.value ? (
                      <div className="text-danger">{errors.value}</div>
                    ) : null}
                  </div>
                  <div className="row py-1">
                    <label htmlFor="facility_id">Select Facility</label>
                    <select
                      className="form-select"
                      name="facility_id"
                      id="facility_id"
                      value={editPromotion.facility_id}
                      onChange={(event) => handleEditFormChange(event)}
                    >
                      <option>-</option>
                      {storageUnits?.results?.map((result, index) => (
                        <option key={index} value={index}>
                          {result?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row py-1 px-0">
                    <label htmlFor="storage_unit_ids">
                      Select Storage Units
                    </label>
                    <Select
                      isMulti
                      name="storage_unit_ids"
                      closeMenuOnSelect={false}
                      options={(
                        storageUnits?.results[editPromotion.facility_id]
                          ?.storage_unit || []
                      ).map((obj) => {
                        // Define the keys you want to use as 'value' and 'label'
                        const valueKey = "id"; // Change this to the desired key
                        const labelKey = "name"; // Change this to the desired key

                        return {
                          value: obj[valueKey]?.toString(),
                          label: obj[labelKey]?.toString(),
                        };
                      })}
                      value={selectedOptions || uipromo} // Pass the selected values back to the component
                      onChange={handleSelectChange}
                      className="basic-multi-select p-0"
                      classNamePrefix="select"
                    />
                  </div>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <button
                      className="border border-0 p-2 px-4 bg-orange text-dark rounded mt-2"
                      type="submit"
                    >
                      Edit Promotion
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
export default EditPromotionModal;
