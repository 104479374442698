import axios from "axios";
import React, { useEffect, useState, memo } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { BsFire } from "react-icons/bs";

import { API_URL } from "../../../actions/userActions";

import "react-multi-carousel/lib/styles.css";

const PromotedFacilities = ({ storageFacility }) => {
  const [promotedFacilities, setPromotedFacilities] = useState([]);

  useEffect(() => {
    async function fetchPromotedFacilites() {
      const { data } = await axios.get(
        `${API_URL}/units/public-promotion-storage-facility/?location=${storageFacility.location.address}`,
      );
      console.log(data);
      setPromotedFacilities(data.filter((facility) => facility.has_promotion));
    }

    fetchPromotedFacilites();
  }, []);

  let navigate = useNavigate();

  const handleReroute = (id) => {
    navigate(`/storages/results/${id}`, { replace: true });
    window.location.reload(); // Reload the page
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {promotedFacilities.length != 0 && (
        <li className="list-group-item bg-light p-2 ">
          <h3 className="text-center">
            More discounted facilities in {storageFacility.location.city}
          </h3>
        </li>
      )}
      <Carousel responsive={responsive} infinite={true}>
        {promotedFacilities
          .filter((facility) => facility.id !== storageFacility.id)
          .map((facility) => (
            <>
              <li
                className="card card-body p-4 m-4 position-relative "
                key={facility.id}
              >
                <span className="position-absolute top-10 end-0 py-1 px-2 pe-4 promotion-banner">
                  <BsFire size={20} className="mb-1 me-1" />
                </span>

                <div className="row">
                  <div className="col-12">
                    <h4 className="">{facility.name}</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="m-0">{facility.address}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="">
                    Starting from{" "}
                    <span className="fs-5 fw-semibold">
                      ${facility.minimum_price}
                    </span>
                    /month
                  </div>
                </div>
                <div className="row">
                  <button
                    className="btn btn-dark text-dark"
                    onClick={() => handleReroute(facility.id)}
                  >
                    View Storage Units
                  </button>
                </div>
              </li>
            </>
          ))}
      </Carousel>
    </>
  );
};

export default memo(PromotedFacilities);
