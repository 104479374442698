import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import jwt_decode from "jwt-decode";

// Reducers Import
import {
  userEmailUpdateReducer,
  userForgotPasswordReducer,
  userInfoReducer,
  userLoginReducer,
  userLogoutReducer,
  userPasswordUpdateReducer,
  userRegisterReducer,
} from "./reducers/userReducers";

import {
  allOrdersAdminReducer,
  allOrdersReducer,
  completedOrdersAdminReducer,
  currentOrdersAdminReducer,
  deleteOrderAdminReducer,
  deleteOrderReducer,
  orderDetailsAdminReducer,
  orderDetailsReducer,
  placeOrderReducer,
  updateOrderAdminReducer,
  createRatingReducer,
  UpdateFeedbackReducer,
} from "./reducers/ordersReducers";
import {
  createStorageUnitReducer,
  deleteStorageUnitReducer,
  getAllStorageUnitsReducer,
  getAvailableStorageUnitReducer,
  getOccupiedStorageUnitReducer,
  getSingleStorageUnitReducer,
  getTotalStorageUnitReducer,
  updateStorageUnitReducer,
  deleteImageReducer,
  AddNewFeatureReducer,
  updateFeatureReducer,
  updateImageReducer,
  deleteFeatureReducer,
  getDistinctiveStorageUnitsReducer,
} from "./reducers/storageUnitReducers";
import {
  createServiceProviderReducer,
  getServiceProviderReducer,
  updateServiceProviderReducer,
} from "./reducers/serviceProviderReducers";
import {
  createRoomsReducer,
  deleteRoomReducer,
  getAllRoomsOfSpecificUnitReducer,
  getSingleRoomReducer,
  updateRoomsReducer,
} from "./reducers/roomsReducers";
import {
  cartAddItemsReducer,
  cartGetItemsReducer,
  cartUpdateItemsReducer,
  updateNotificationReducer,
} from "./reducers/cartReducers";
import {
  getAllStorageFacilitiesReducer,
  getStorageFacilityDetailsReducer,
  getDistinctiveStorageFacilitiesreducer,
} from "./reducers/usStorageFacilityReducers";

import {
  postNotficationReducer,
  updateNotficationReducer,
} from "./reducers/notificationReducers";

import authReducer from "./reducers/authReducer";
import { getAllStorageUnits } from "./actions/storageUnitActions";

const reducers = combineReducers({
  userRegister: userRegisterReducer,
  userLogin: userLoginReducer,
  userLogout: userLogoutReducer,
  userInfo: userInfoReducer,
  userEmailUpdate: userEmailUpdateReducer,
  userPasswordUpdate: userPasswordUpdateReducer,
  userForgotPassword: userForgotPasswordReducer,
  placeOrder: placeOrderReducer,
  orderDetails: orderDetailsReducer,
  allOrders: allOrdersReducer,
  deleteOrder: deleteOrderReducer,
  allOrdersAdmin: allOrdersAdminReducer,
  deleteOrdersAdmin: deleteOrderAdminReducer,
  updateOrderAdmin: updateOrderAdminReducer,
  orderDetailsAdmin: orderDetailsAdminReducer,
  currentOrdersAdmin: currentOrdersAdminReducer,
  completedOrdersAdmin: completedOrdersAdminReducer,
  getAllStorageUnits: getAllStorageUnitsReducer,
  getSingleStorageUnit: getSingleStorageUnitReducer,
  getTotalStorageUnit: getTotalStorageUnitReducer,
  getAvailableStorageUnit: getAvailableStorageUnitReducer,
  getOccupiedStorageUnit: getOccupiedStorageUnitReducer,
  getServiceProvider: getServiceProviderReducer,
  createServiceProvider: createServiceProviderReducer,
  updateServiceProvider: updateServiceProviderReducer,
  createStorageUnit: createStorageUnitReducer,
  deleteStorageUnit: deleteStorageUnitReducer,
  updateStorageUnit: updateStorageUnitReducer,
  createRooms: createRoomsReducer,
  deleteRoom: deleteRoomReducer,
  getSingleRoom: getSingleRoomReducer,
  getAllRoomsOfSpecificUnit: getAllRoomsOfSpecificUnitReducer,
  updateRoom: updateRoomsReducer,
  cartAddItem: cartAddItemsReducer,
  cartGetItems: cartGetItemsReducer,
  cartUpdateItems: cartUpdateItemsReducer,
  usStorageFacilities: getAllStorageFacilitiesReducer,
  usStorageFacilityDetails: getStorageFacilityDetailsReducer,
  createRating: createRatingReducer,
  updateFeedback: UpdateFeedbackReducer,
  deleteImage: deleteImageReducer,
  addNewFeature: AddNewFeatureReducer,
  updateFeature: updateFeatureReducer,
  updateImage: updateImageReducer,
  deleteFeature: deleteFeatureReducer,
  postNotfication: postNotficationReducer,
  updateNotification: updateNotficationReducer,
  getDistinctiveStorageFacilities: getDistinctiveStorageFacilitiesreducer,
  getDistinctiveStorageUnits: getDistinctiveStorageUnitsReducer,
  // auth: authReducer,
});

// GET ITEMS FROM LOCAL STORAGE
const userFromStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const serviceProviderFromStorage = localStorage.getItem("serviceProvider")
  ? JSON.parse(localStorage.getItem("serviceProvider"))
  : null;

console.log("inside store");
// initial State
const initialState = {
  userLogin: {
    user: userFromStorage,
    loading: true,
    isAuthenticated: false,
  },
  userInfo: {
    info: userInfoFromStorage,
  },
  getServiceProvider: {
    serviceProvider: serviceProviderFromStorage,
  },
  getAllStorageUnits: {
    loading: false,
    storageUnits: {},
  },
};
console.log("Session", initialState);

const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
