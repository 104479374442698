import React, { useEffect } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import { FaWarehouse } from "react-icons/fa";
import AnalyticsSpaceCards from "../ServiceProviderDashboardComponents/AnalyticsSpaceCards";
import AnalyticsMapAndChart from "../ServiceProviderDashboardComponents/AnalyticsMapAndChart";
import AnalyticsRecentUnitsAndChart from "../ServiceProviderDashboardComponents/AnalyticsRecentUnitsAndChart";
import AnalyticsReviewsTableAndUnitStatus from "../ServiceProviderDashboardComponents/AnalyticsReviewsTableAndUnitStatus";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { useState } from "react";
import Select from "react-select";
import {
  getAllStorageUnits,
  getTotalStorageUnits,
  getAvailableStorageUnits,
  getOccupiedStorageUnits,
} from "../../../actions/storageUnitActions";
import { getAllUsStorageFacilities } from "../../../actions/usStorageFacilityActions";
import { getAllOrdersAdmin } from "../../../actions/ordersActions";
import HomeOrdersTableServiceProvider from "../../Home/HomeOrdersTableServiceProvider/HomeOrdersTableServiceProvider";
import Pagination from "react-js-pagination";
import { LuWarehouse } from "react-icons/lu";

const AnalyticsPage = () => {
  const dispatch = useDispatch();

  const { loading, storageUnits } = useSelector(
    (state) => state.getAllStorageUnits,
  );

  const { loading: ordersLoading, orders } = useSelector(
    (state) => state.allOrdersAdmin,
  );

  const { loading: totalLoading, storageUnits: totalUnits } = useSelector(
    (state) => state.getTotalStorageUnit,
  );
  const { loading: availableLoading, availableStorageUnits } = useSelector(
    (state) => state.getAvailableStorageUnit,
  );
  const { loading: occupiedLoading, occupiedStorageUnits } = useSelector(
    (state) => state.getOccupiedStorageUnit,
  );

  const {
    loading: storageFacilities,
    error,
    storageFacilties,
  } = useSelector((state) => state.usStorageFacilities);

  const [selectedFilters, setSelectedFilters] = useState({
    is_available: false,
    is_occupied: false,
    ordering: "-created_at",
  });

  useEffect(() => {
    dispatch(getAllStorageUnits());
    dispatch(getTotalStorageUnits(selectedFilters));
    dispatch(getAvailableStorageUnits(selectedFilters));
    dispatch(getOccupiedStorageUnits(selectedFilters));
  }, [dispatch]);

  const [selectedData, setSelectedData] = useState({
    pending: false,
    completed: false,
    starting_date: "",
    ending_date: "",
    search: "",
    page: 1,
  });
  const options = [
    { value: "completed", label: "Completed" },
    { value: "pending", label: "Pending" },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    setSelectedData({
      ...selectedData,
      pending: selectedOptions.some((option) => option.value === "pending"),
      completed: selectedOptions.some((option) => option.value === "completed"),
    });
  }, [selectedOptions]);

  const handleMultiSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleCheckboxChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    setSelectedData({
      ...selectedData,
      [name]: value,
    });
  };

  const handleFilter = () => {
    dispatch(getAllOrdersAdmin({ ...selectedData, page: 1 }));
  };

  const handleDateChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "starting_date" || name === "ending_date") {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      value = `${year}-${month}-${day}`;
    }

    setSelectedData({
      ...selectedData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getAllOrdersAdmin(selectedData));
  }, [dispatch, selectedData.page]);

  const handleTextChange = (e) => {
    setSelectedData({
      ...selectedData,
      search: e.target.value,
    });
  };

  const handlePaginationChange = (pageNumber) => {
    setSelectedData({
      ...selectedData,
      page: pageNumber,
    });
  };

  const handlePagination = (str) => {
    var url = null;
    if (str === "next") {
      url = orders.next;
    } else {
      url = orders.previous;
    }
    const urlObject = new URL(url);
    const pageParam = urlObject.searchParams.get("page");
    const pageNumber = parseInt(pageParam, 10);
    setSelectedData({
      ...selectedData,
      page: pageNumber,
    });
  };
  console.log("Storage Facilities ", storageUnits?.results);

  return (
    <div className="mt-3">
      <div className="align-items-center d-none d-lg-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div className="p-2 bg-orange text-dark rounded me-2">
            <AiOutlineHome size={20} />
          </div>
          <h5 className="text-uppercase">Dashboard</h5>
        </div>
        <div></div>
      </div>
      {loading || totalLoading || availableLoading || occupiedLoading ? (
        <Spinner />
      ) : storageUnits?.results?.length === 0 ? (
        <div>
          <div className="mt-5 text-center">
            <LuWarehouse size={70} />
            <h1 className="mt-2">No Facility Storage Found</h1>
            <p className="mt-2">
              It looks like there are no facility stores available yet. Click
              here to create a new one.
            </p>
            <Link
              to="/service-provider-dashboard/addUnit"
              className="btn btn-dark text-dark"
            >
              <LuWarehouse
                className="pr-1"
                style={{
                  marginRight: "10px",
                }}
              />
              ADD NEW FACILITY
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="text-end">
            <Link
              to="/service-provider-dashboard/addUnit"
              className="btn btn-dark text-dark"
            >
              <LuWarehouse
                className="pr-1"
                style={{
                  marginRight: "10px",
                }}
              />
              ADD NEW FACILITY
            </Link>
          </div>
          <div className="row gx-4 mt-4">
            <AnalyticsSpaceCards
              totalUnits={totalUnits}
              availableUnits={availableStorageUnits}
              occupiedUnits={occupiedStorageUnits}
              StorageUnitData={storageUnits?.results}
            />
          </div>

          <div className="row gx-4 mt-4">
            <AnalyticsMapAndChart
              StorageUnitData={storageUnits?.results}
              loading={loading}
            />
          </div>

          <div className="row gx-4 mt-4">
            <AnalyticsRecentUnitsAndChart
              StorageUnitData={storageUnits?.results}
            />
          </div>

          <div className="row gx-4 mt-4">
            <AnalyticsReviewsTableAndUnitStatus
              StorageUnitData={storageUnits?.results}
              count={storageUnits?.count}
            />
          </div>
          {ordersLoading ? (
            <Spinner />
          ) : (
            <div className="card mt-4">
              <div className="card-body">
                <div
                  className="d-flex align-items-end justify-content-start flex-wrap mb-5"
                  style={{
                    gap: "30px",
                  }}
                >
                  <div className="mt-3" style={{ width: "fit-content" }}>
                    <label className="fw-bold">Status:</label>
                    <Select
                      isMulti
                      name="status"
                      options={options}
                      value={selectedOptions}
                      onChange={handleMultiSelectChange}
                    />
                  </div>

                  <div className="mt-2" style={{ width: "150px" }}>
                    <label className="fw-bold">Start Date:</label>
                    <input
                      type="date"
                      className="input-group border border-1 rounded p-2"
                      name="starting_date"
                      value={selectedData.starting_date}
                      onChange={handleDateChange}
                      style={{ height: "36px" }}
                    ></input>
                  </div>
                  <div className="mt-3" style={{ width: "150px" }}>
                    <label className="fw-bold">End Date:</label>
                    <input
                      type="date"
                      className="input-group border border-1 rounded p-2"
                      name="ending_date"
                      value={selectedData.ending_date}
                      onChange={handleDateChange}
                      style={{ height: "36px" }}
                    />
                  </div>
                  <div className="mt-3" style={{ width: "150px" }}>
                    <label className="fw-bold">Search Text:</label>
                    <input
                      type="text"
                      className="border border-1 rounded p-2"
                      style={{
                        maxWidth: "150px",
                        height: "35px",
                      }}
                      value={selectedData.search}
                      onChange={handleTextChange}
                    />
                  </div>

                  <button className="btn mt-6 text-dark" onClick={handleFilter}>
                    Search
                  </button>
                </div>
                <HomeOrdersTableServiceProvider orders={orders} />
              </div>
              {/* <div
                className="d-flex justify-content-center align-items-center mb-5"
                style={{
                  gap: "50px",
                }}
              >
                <button
                  className="fw-bold d-flex align-items-center"
                  onClick={() => {
                    handlePagination("previous");
                  }}
                  disabled={!orders?.previous}
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    border: "none",
                    outline: "none",
                  }}
                >
                  <IoIosSkipBackward size={20} /> Previous
                </button>
                <span className="fw-bold">
                  Page {selectedData.page} of{" "}
                  {Math.ceil(orders?.count / 10) || 1}
                </span>
                <button
                  className="fw-bold d-flex align-items-center cursor-pointer"
                  onClick={() => {
                    handlePagination("next");
                  }}
                  disabled={!orders?.next}
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    border: "none",
                    outline: "none",
                  }}
                >
                  Next <IoIosSkipForward size={20} />
                </button>
              </div> */}
              <div className="d-flex justify-content-center align-items-center">
                <Pagination
                  activePage={selectedData.page}
                  itemsCountPerPage={10}
                  totalItemsCount={orders?.count}
                  pageRangeDisplayed={5}
                  onChange={handlePaginationChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AnalyticsPage;
