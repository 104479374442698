import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "../../shared-components/container/Container";
import Spinner from "../../shared-components/Spinner/Spinner";
import { getAllOrders, getAllOrdersAdmin } from "../../actions/ordersActions";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import HomeOrdersTableUser from "../Home/HomeOrdersTable/HomeOrdersTableUser";
import HomeOrdersTableServiceProvider from "../Home/HomeOrdersTableServiceProvider/HomeOrdersTableServiceProvider";
import Pagination from "react-js-pagination"; // Import react-js-pagination
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Orders = () => {
  const { info } = useSelector((state) => state.userInfo);
  const allOrdersAdmin = useSelector((state) => state.allOrdersAdmin);
  const allOrders = useSelector((state) => state.allOrders);
  const isServiceProvider = info && info.is_service_provider;
  const order = isServiceProvider ? allOrdersAdmin : allOrders;
  const { orders, loading } = order;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    search: "",
    business_type: "",
    page: 1,
  });

  useEffect(() => {
    if (isServiceProvider) {
      dispatch(getAllOrdersAdmin());
    } else {
      dispatch(getAllOrders(formData));
    }
  }, [dispatch, formData.page]);

  const handleSearchTextChange = (e) => {
    setFormData({
      ...formData,
      search: e.target.value,
    });
  };

  const handleBusinessTypeChange = (e) => {
    setFormData({
      ...formData,
      business_type: e.target.value,
    });
  };

  const handleFilter = () => {
    dispatch(getAllOrders({ ...formData, page: 1 }));
  };

  const handlePagination = (pageNumber) => {
    setFormData({
      ...formData,
      page: pageNumber,
    });
  };

  return (
    <div className="py-5 " style={{ backgroundColor: "rgb(242, 237, 243)" }}>
      <Container>
        <h1>
          My <span className="mineTextOrange">Orders</span>
        </h1>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {orders ? (
              orders?.length === 0 ? (
                <h5 className="text-center">You have no Orders</h5>
              ) : (
                <>
                  {isServiceProvider ? (
                    <HomeOrdersTableServiceProvider
                      orders={allOrdersAdmin.orders}
                    />
                  ) : (
                    <div className="card mt-4 ">
                      <div className="card-body">
                        <div
                          className="d-flex justify-content-end align-items-end flex-wrap"
                          style={{
                            gap: "30px",
                          }}
                        >
                          <div className="form-group">
                            <label htmlFor="business_type" className="fw-bold">
                              Business Type :
                            </label>
                            <select
                              id="business_type"
                              onChange={handleBusinessTypeChange}
                              className="form-select"
                              aria-label="Default select example"
                              style={{
                                width: "fit-content",
                              }}
                              value={formData.business_type}
                            >
                              <option value="Select Type">Select Type</option>
                              <option value="commercial">Commercial</option>
                              <option value="public">Public</option>
                              <option value="business">Business</option>
                              <option value="personal">Personal</option>
                            </select>
                          </div>
                          <div className="mt-3" style={{ width: "150px" }}>
                            <label className="fw-bold">Search Text:</label>
                            <input
                              type="text"
                              className="border border-1 rounded p-2"
                              style={{
                                maxWidth: "150px",
                                height: "35px",
                              }}
                              value={formData.search}
                              onChange={handleSearchTextChange}
                            />
                          </div>

                          <button
                            className="btn mt-6 text-dark"
                            onClick={handleFilter}
                          >
                            Search
                          </button>
                        </div>
                        <HomeOrdersTableUser orders={orders.results} />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center mb-5"
                        style={{
                          gap: "50px",
                        }}
                      >
                        <Pagination
                          activePage={formData.page}
                          itemsCountPerPage={10} // Number of items per page
                          totalItemsCount={orders?.count || 1} // Total number of items
                          pageRangeDisplayed={5} // Number of pages to display in the pagination
                          onChange={handlePagination} // Callback function to handle page changes
                          itemClass="page-item text-dark" // Bootstrap class for each pagination item
                          linkClass="page-link text-dark" // Bootstrap class for pagination links
                        />
                      </div>
                    </div>
                  )}
                </>
              )
            ) : (
              <p className="p-3  text-black rounded">No Orders</p>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Orders;
