export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAIL = "USER_INFO_FAIL";
export const USER_INFO_CLEAR = "USER_INFO_CLEAR";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";
export const USER_FORGOT_PASSWORD_RESET = "USER_FORGOT_PASSWORD_RESET";

export const USER_EMAIL_UPDATE_REQUEST = "USER_EMAIL_UPDATE_REQUEST";
export const USER_EMAIL_UPDATE_SUCCESS = "USER_EMAIL_UPDATE_SUCCESS";
export const USER_EMAIL_UPDATE_FAIL = "USER_EMAIL_UPDATE_FAIL";
export const USER_EMAIL_UPDATE_RESET = "USER_EMAIL_UPDATE_RESET";

export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_SUCCESS = "USER_PASSWORD_UPDATE_SUCCESS";
export const USER_PASSWORD_UPDATE_FAIL = "USER_PASSWORD_UPDATE_FAIL";
export const USER_PASSWORD_UPDATE_RESET = "USER_PASSWORD_UPDATE_RESET";

export const USER_CHECK_FAIL = "USER_CHECK_FAIL";
export const CHECK_USER = "CHECK_USER";
