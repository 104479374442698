import React from "react";
import Container from "../../../shared-components/container/Container";

const Vehicle = () => {
  return (
    <div>
      <Container>
        <h1>Vehicle Storage</h1>
      </Container>
    </div>
  );
};

export default Vehicle;
