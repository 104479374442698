import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const StorageUnitDoughnutChart = ({ unit }) => {
  const [chartData, setChartData] = useState({
    labels: [unit.name],
    datasets: [
      {
        label: "Total Units",
        data: [unit.total_storage_units],
        backgroundColor: "#FF7F50",
      },
      {
        label: "Available Units",
        data: [unit.available_storage_units],
        backgroundColor: "rgba(255, 140, 0, 1)",
      },
      {
        label: "Occupied Units",
        data: [unit.occupied_storage_unit],
        backgroundColor: "#FFDAB9",
      },
    ],
  });
  return (
    <div className="">
      <Bar data={chartData} />
    </div>
  );
};

export default StorageUnitDoughnutChart;
