import RatingStars from "../../../shared-components/RatingStars/RatingStars";

const UnitStorageReviewsTable = ({ data }) => {
  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  const firstLetter = data && data?.facility_ratings[0]?.user?.email?.charAt(0);
  return (
    <div className="table-responsive " style={{ minHeight: "210px" }}>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">User</th>
            <th scope="col">Storage Facility</th>
            <th scope="col">Rating</th>
          </tr>
        </thead>
        {data?.facility_ratings.length > 0 ? (
          <tbody>
            {data &&
              data?.facility_ratings?.map((user) => (
                <tr key={user.id}>
                  <td>
                    <div
                      className="rounded-circle avatar-letter mr-3"
                      style={{
                        background:
                          "#" +
                          Math.floor(Math.random() * 16777215).toString(16),
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <span className="text-white d-flex justify-content-center align-items-center h-100 ">
                        {firstLetter}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span> {data && data?.name}</span>
                  </td>
                  <td>
                    <RatingStars
                      value={
                        data && data?.facility_ratings[0]?.facility_ratings
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>
                <p className="fw-bold text-center  ">
                  This Facility currently has no reviews
                </p>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default UnitStorageReviewsTable;
