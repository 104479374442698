import React from "react";
import Container from "../../../shared-components/container/Container";

const Outdoor = () => {
  return (
    <div>
      <Container>
        <h1>Outdoor / Drive-Up Storage</h1>
      </Container>
    </div>
  );
};

export default Outdoor;
