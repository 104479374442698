import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaBell } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

import useNotifications from "../../hooks/useNotications";
import "./notification.css";

const Notification = () => {
  const { user } = useSelector((state) => state.userLogin);
  let accessToken = user && user.access ? user.access : "";

  const user_id = localStorage.getItem("user_id");
  const [isOpen, setIsOpen] = useState(false);
  const { notifications } = useNotifications(user_id);
  // Making a copy and reversing the order
  // const reversedNotifications = notifications.slice().reverse();

  // notifications.length = 0;

  // Function to extract initials
  const getInitials = (name) => {
    return name
      .split(" ")
      .slice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  // Error handler for image loading failure
  const handleImageError = (e, title) => {
    e.target.onerror = null; // Prevents future triggers
    e.target.style.display = "none"; // Hides the image element
    e.target.nextElementSibling.innerText = getInitials(title); // Sets the initials
    e.target.nextElementSibling.style.display = "block"; // Shows the initials
  };

  useEffect(() => {
    if (isOpen) {
      notifications?.map((value, index) => {
        if (value.is_read === false) {
          const url = `https://3.82.183.189/app/notifications/update-notification/${value.id}/`;
          const data = {
            is_read: true,
          };

          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };

          axios
            .patch(url, data, { headers })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    }
  }, [isOpen]);

  return (
    <div className="notification-container">
      <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)} align="end">
        <Dropdown.Toggle
          variant="link"
          id="notification-icon"
          className="notification-icon text-dark"
        >
          <FaBell />
          {notifications[0]?.unread_notifications > 0 && (
            <span className="notification-badge">
              {notifications[0]?.unread_notifications}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="notification-dropdown-menu">
          {notifications.length === 0 && (
            <div className="notification-item d-flex justify-content-center m-3 text-nowrap">
              No notifications to show
            </div>
          )}
          {notifications
            .slice()
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((notification, index) => (
              <Dropdown.Item
                key={index}
                className={`notification-item ${notification.is_read ? "" : "unread"}`}
              >
                {/* <img src={notification.avatarUrl} alt="" className="notification-avatar" onError={(e) => handleImageError(e, notification.title)} /> */}
                <div className="notification-initials">
                  {getInitials(notification.title)}
                </div>
                <div className="notification-details">
                  <span className="notification-user">
                    {notification.title}
                  </span>
                  <span className="notification-action">
                    {notification.body}
                  </span>
                  <div className="notification-timestamp">
                    {notification.created_at || "July 23, 2023 7:39 AM"}
                  </div>
                </div>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Notification;
