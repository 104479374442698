import React, { useEffect, useState } from "react";
import { FaWarehouse } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import StorageUnitsTable from "../ServiceProviderDashboardComponents/StorageUnitsPageComponents/StorageUnitsTable";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableStorageUnits } from "../../../actions/storageUnitActions";
import StorageUnitsRoomsTable from "../ServiceProviderDashboardComponents/StorageProviderDetailsPageComponents/StorageUnitRoomsTable";
import StorageUnitsFilter from "../../../shared-components/Filter/StorageUnitsFilter";
import StorageUnitPagination from "../../../shared-components/Filter/StorageUnitPagination";
import { useLocation } from "react-router-dom";
import {
  getTotalStorageUnits,
  getOccupiedStorageUnits,
} from "../../../actions/storageUnitActions";
import { LuWarehouse } from "react-icons/lu";
const StorageUnitPageAvailable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const parts = location.pathname.split("/");
  const lastPart = parts[parts.length - 1];
  const { loading, availableStorageUnits: storageUnits } = useSelector(
    (state) => state.getAvailableStorageUnit,
  );

  const [selectedFilters, setSelectedFilters] = useState({
    is_available: false,
    is_occupied: false,
    ordering: "-created_at",
    page: 1,
  });
  const handleFilterChange = (selectedOptions) => {
    const updatedFilters = { ...selectedFilters };
    setSelectedFilters(updatedFilters);
  };

  useEffect(() => {
    handleSearch();
  }, [dispatch]);

  const handleSearch = () => {
    switch (lastPart) {
      case "storageUnits":
        dispatch(getTotalStorageUnits(selectedFilters));
        break;
      case "availableStorageUnits":
        dispatch(getAvailableStorageUnits(selectedFilters));
        break;
      case "occupiedStorageUnits":
        dispatch(getOccupiedStorageUnits(selectedFilters));
        break;
    }
  };

  const handlePageChange = (pageNumber) => {
    setSelectedFilters({
      ...selectedFilters,
      page: pageNumber,
    });

    switch (lastPart) {
      case "storageUnits":
        dispatch(
          getTotalStorageUnits({ ...selectedFilters, page: pageNumber }),
        );
        break;
      case "availableStorageUnits":
        dispatch(
          getAvailableStorageUnits({ ...selectedFilters, page: pageNumber }),
        );
        break;
      case "occupiedStorageUnits":
        dispatch(
          getOccupiedStorageUnits({ ...selectedFilters, page: pageNumber }),
        );
        break;
      default:
        break;
    }
  };

  const updateFiltersAndSearch = (updatedFilters) => {
    setSelectedFilters(updatedFilters);
    dispatch(getAvailableStorageUnits(updatedFilters));
  };

  const { distinctiveStorageUnits } = useSelector(
    (state) => state.getDistinctiveStorageUnits,
  );
  const { storageFacility } = useSelector(
    (state) => state.getDistinctiveStorageFacilities,
  );

  const { loading: totalStorageLoading, storageUnits: totalStorageUnits } =
    useSelector((state) => state.getTotalStorageUnit);

  const { loading: allStorageLoading, storageUnits: allStorageUnits } =
    useSelector((state) => state.getAllStorageUnits);

  const { loading: ordersLoading, orders } = useSelector(
    (state) => state.allOrdersAdmin,
  );

  const { loading: availableLoading, availableStorageUnits } = useSelector(
    (state) => state.getAvailableStorageUnit,
  );

  const { loading: occupiedLoading, occupiedStorageUnits } = useSelector(
    (state) => state.getOccupiedStorageUnit,
  );

  const storageFacilityOptions = Array.isArray(allStorageUnits.results)
    ? allStorageUnits.results.map((facility) => ({
        value: facility.id,
        label: facility.name,
      }))
    : [];

  console.log("storage facility options", storageFacilityOptions);

  const handleDropdownItemClick = (id) => {
    // Implement your logic here for what should happen when a dropdown item is clicked
    console.log("Selected storage facility ID:", id);
    navigate(`/service-provider-dashboard/addRoom/${id}`);
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    // Optionally, reset after some time or other conditions
    setTimeout(() => setIsClicked(false), 300); // Reset after 300ms
  };

  return (
    <>
      <div className="mt-3">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col d-none d-lg-flex align-items-center">
              <div className="p-2 bg-orange text-dark rounded me-2">
                <FaWarehouse size={20} />
              </div>
              <h5 className="text-uppercase">Storage Units</h5>
            </div>
            <div className="col text-end">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-danger dropdown-toggle d-inline-bock"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: isClicked ? "#e0a800" : "#ffc107", // Change color on click
                    color: isClicked ? "white" : "black",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                    position: "fixed",
                    right: "25px",
                    top: "22%",
                    marginright: "10px",
                  }}
                  onClick={handleClick}
                >
                  ADD NEW UNIT
                </button>
                <ul
                  className="dropdown-menu"
                  style={{ backgroundColor: "#ffc107", color: "black" }}
                >
                  {storageFacilityOptions.map((option, index) => (
                    <li key={index}>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default link behavior
                          handleDropdownItemClick(option.value);
                        }}
                      >
                        {option.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-body">
            <h5 className="text-muted">Page</h5>
            <h3>Available Storage Units</h3>
            <StorageUnitsFilter
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              onUpdateFilters={updateFiltersAndSearch}
            />
            {loading ? (
              <Spinner />
            ) : (
              <StorageUnitsRoomsTable storageUnits={storageUnits} />
            )}
            <StorageUnitPagination
              storageUnits={storageUnits}
              selectedFilters={selectedFilters}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StorageUnitPageAvailable;
