import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  const { user, loading, isAuthenticated } = useSelector(
    (state) => state.userLogin,
  );
  useEffect(() => {
    if (!loading) {
      if (user && isAuthenticated) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setCheckingStatus(false);
    } else {
      setCheckingStatus(true);
    }
  }, [user, isLoggedIn]);

  return { user, isLoggedIn, checkingStatus };
};

export default useAuth;
