import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getOrderDetailsAdmin,
  getOrderDetails,
} from "../../../actions/ordersActions";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { UPDATE_ORDERS_ADMIN_RESET } from "../../../constants/ordersConstants";
import { toast } from "react-toastify";
import OrderDetailsServicePovider from "./OrderDetailsServicePovider";
import OrderDetailsUser from "./OrderDetailsUser";

const OrdersDetails = () => {
  const { id } = useParams();
  const { info } = useSelector((state) => state.userInfo);
  const { loading, order, error } = useSelector(
    (state) => state.orderDetailsAdmin,
  );
  const {
    loading: loading_user,
    order: order_user,
    error: error_user,
  } = useSelector((state) => state.orderDetails);
  const isServiceProvider = info && info.is_service_provider;
  const loadingSelector = isServiceProvider ? loading : loading_user;
  const { loading: updateLoading, order: updateOrder } = useSelector(
    (state) => state.updateOrderAdmin,
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (info && info.is_service_provider) {
      dispatch(getOrderDetailsAdmin(id));
      if (updateOrder) {
        navigate("/service-provider-dashboard/analytics");
        toast.success("Updated");
        dispatch({ type: UPDATE_ORDERS_ADMIN_RESET });
      }
    } else {
      dispatch(getOrderDetails(id));
    }
  }, [dispatch, updateOrder]);

  return (
    <div
      className="py-5"
      style={{
        background:
          info && !info.is_service_provider ? "#f2edf3" : "transparent", // Set a default value if the condition is false
      }}
    >
      {loadingSelector || updateLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="d-flex justify-content-center p-2 m-2">
            <h1>
              Order
              <span className="mineTextOrange"> Details</span>
            </h1>
          </div>
          <div
            className={`container ${
              !info.isServiceProvider ? "card px-5 py-3" : ""
            }`}
          >
            {!info.is_service_provider ? (
              <div className={`d-flex text-align-center`}>
                <div className=" my-2">
                  <div className="py-2">
                    {!true ? (
                      ""
                    ) : (
                      <h3 className="mb-4">Thank you for your order!</h3>
                    )}

                    <div className="  d-flex flex-column">
                      <p className="fw-semibold fs-5">
                        Order ID:{" "}
                        <span className=" text-dark ">{order_user.slug}</span>
                      </p>

                      <p className="fw-semibold fs-5">
                        Business Type:{" "}
                        <span className=" text-dark text-capitalize">
                          {order_user.business_type}
                        </span>
                      </p>
                      <div></div>

                      <p className="fw-semibold fs-5">
                        Total Units Ordered:{" "}
                        <span className=" text-dark ">
                          {order_user.order.length}
                        </span>
                      </p>
                    </div>
                  </div>
                  <h3>Services Booked:</h3>
                </div>
              </div>
            ) : (
              <div className="d-flex ">
                <p className="fw-bold ">
                  Business Type:{" "}
                  <span className=" text-dark ">
                    {order.order.business_type.toUpperCase()}
                  </span>
                </p>
              </div>
            )}
            <div className="row">
              <div className=" col-12 ">
                {info && info.is_service_provider ? (
                  <OrderDetailsServicePovider order={order} />
                ) : (
                  <div>
                    <OrderDetailsUser order={order_user} id={id} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrdersDetails;
