import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { CiDiscount1 } from "react-icons/ci";

import AddPromotionModal from "./AddPromotionModal";
import PromotionsTable from "./PromotionsTable";
import PromotedUnitsTable from "./PromotedUnitsTable";
import EditPromotionModal from "./EditPromotionModal";
import { API_URL } from "../../../../actions/userActions";
import { getAllStorageUnits } from "../../../../actions/storageUnitActions";

const PromotionsPage = () => {
  const { user } = useSelector((state) => state.userLogin);
  const accessToken = user.access;
  const { storageUnits } = useSelector((state) => state.getAllStorageUnits);
  const [showAddPromoModal, setShowAddPromoModal] = useState(false);
  const [showEditPromoModal, setShowEditPromoModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStorageUnits());
  }, [dispatch]);

  const [activeTab, setActiveTab] = useState("tab1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getPromotions(accessToken);
  }, [accessToken]);

  const [formFields, setFormFields] = useState({
    title: "",
    total_months: 0,
    offer_months: 0,
    type: "",
    value: 0,
    facility_id: "",
    storage_unit_ids: [],
  });

  const [promotions, setPromotions] = useState([]);
  const [editPromotion, setEditPromotion] = useState({
    title: "",
    total_months: 0,
    offer_months: 0,
    type: "",
    value: 0,
    facility_id: "",
    storage_unit_ids: [],
  });
  const [editPromotionIndex, setEditPromotionIndex] = useState();

  const addFieldsHandler = (data) => {
    setPromotions((prevPromotions) => [...prevPromotions, data]);
    setFormFields({
      title: "",
      total_months: 0,
      offer_months: 0,
      type: "",
      value: 0,
      facility_id: "",
      storage_unit_ids: [],
    });
  };
  const editFieldsHandler = (data) => {
    setPromotions((prevPromotions) =>
      prevPromotions.map((promotion, index) =>
        index === editPromotionIndex ? data : promotion,
      ),
    );
    editPromotionFunction(data);
  };

  const deletePromotionHandler = (ind, id) => {
    deletePromotion(id);
    setPromotions(promotions.filter((f, index) => index !== ind));
  };

  const editPromotionHandler = (id) => {
    setEditPromotionIndex(id);
    setEditPromotion(promotions.find((f, index) => index === id));
    setShowEditPromoModal(true);
  };

  function addPromotionFunction(data) {
    axios
      .post(`${API_URL}/promotions/add_pormotion/`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getPromotions(token) {
    let res = await axios
      .get(`${API_URL}/promotions/get_pormotions/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.error(error);
      });
    // console.log("res.data", res.data);
    setPromotions(res.data);
  }
  function deletePromotion(id) {
    axios
      .delete(`${API_URL}/promotions/delete_pormotion/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function editPromotionFunction(data) {
    axios
      .patch(`${API_URL}/promotions/update_pormotion/${data.id}/`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // console.log(promotions);
  return (
    <>
      <div className="py-3">
        <div className="container">
          <div className="align-items-center d-lg-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="p-2 bg-orange text-dark rounded me-2">
                <CiDiscount1 size={24} />
              </div>

              <ul className="nav align-items-center nav-tabs">
                <li className="nav-item mb-0 ">
                  <a
                    className={`nav-link mb-0 ${activeTab === "tab1" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => toggleTab("tab1")}
                  >
                    <h5 className="text-uppercase mineTextOrange myCursorClass">
                      Add Promotions
                    </h5>
                  </a>
                </li>
                <li className="nav-item mb-0 ">
                  <a
                    className={`nav-link mb-0  ${activeTab === "tab2" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => toggleTab("tab2")}
                  >
                    <h5 className="text-uppercase mineTextOrange myCursorClass ">
                      Promoted Units
                    </h5>
                  </a>
                </li>
              </ul>
            </div>
            <button
              className="btn btn-primary text-dark"
              onClick={() => setShowAddPromoModal(true)}
            >
              <CiDiscount1 size={20} className="m-2" />
              Add promotion
            </button>
          </div>

          {/* this is promotion page */}
          {activeTab === "tab1" ? (
            <PromotionsTable
              promotions={promotions}
              editPromotionHandler={editPromotionHandler}
              deletePromotionHandler={deletePromotionHandler}
            />
          ) : (
            <PromotedUnitsTable
              promotions={promotions}
              editPromotionHandler={editPromotionHandler}
              deletePromotionHandler={deletePromotionHandler}
            />
          )}
        </div>
      </div>
      {/* add promotion modal */}
      <AddPromotionModal
        formFields={formFields}
        setFormFields={setFormFields}
        storageUnits={storageUnits}
        addFieldsHandler={addFieldsHandler}
        addPromotionFunction={addPromotionFunction}
        showAddPromoModal={showAddPromoModal}
        setShowAddPromoModal={setShowAddPromoModal}
      />
      {/* edit promotion modal */}
      <EditPromotionModal
        editPromotion={editPromotion}
        setEditPromotion={setEditPromotion}
        editFieldsHandler={editFieldsHandler}
        storageUnits={storageUnits}
        showEditPromoModal={showEditPromoModal}
        setShowEditPromoModal={setShowEditPromoModal}
      />
    </>
  );
};
export default PromotionsPage;
