import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart } from "../../../actions/cartActions";
import { toast } from "react-toastify";
import { BsCartPlus } from "react-icons/bs";
import { BsCurrencyDollar } from "react-icons/bs";

import { CART_ADD_ITEM_RESET } from "../../../constants/cartConstants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const IndoorStorageList = memo(({ room }) => {
  const dispatch = useDispatch();
  const { error, cartItem } = useSelector((state) => state.cartAddItem);
  const { user } = useSelector((state) => state.userLogin);
  const addToCart = (roomId) => {
    toast.success("Added to cart");
    dispatch(addItemsToCart(roomId));
  };

  useEffect(() => {
    if (cartItem) {
      dispatch({ type: CART_ADD_ITEM_RESET });
    }

    if (error) {
      toast.error("Please login first to add items to the cart.");
      dispatch({ type: CART_ADD_ITEM_RESET });
    }
  }, [cartItem, error]);

  return (
    <>
      <li key={room.id} className="list-group-item p-3">
        <div className="d-flex row">
          <div className="col-2">
            <img
              src="/images/large.png"
              className="img-fluid rounded"
              alt="indoor"
            />
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-8 ">
                <h5 className="ms-1">
                  {room.name} | {room.size_field}
                </h5>
                <span className="text-body-orange fs-5 fw-semibold">
                  <BsCurrencyDollar size={20} className="mb-1 p-0 ms-0" />
                  {room.per_unit_price}
                </span>
                <span className="text-secondary fs-6"> / MONTH</span>
              </div>
              {user ? (
                <>
                  <div className="col-4 text-center">
                    <button
                      onClick={() => addToCart(room.id)}
                      className={`border-0 p-2 ${
                        room.free_space === 0 ? "bg-secondary" : "bg-orange"
                      } text-dark`}
                      disabled={room.free_space === 0}
                    >
                      {room.free_space === 0 ? (
                        "Reserved"
                      ) : (
                        <span>
                          <BsCartPlus size={20} className="mb-1" /> Add To Cart
                        </span>
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4 text-center">
                    Login / Sign up to reserve.
                  </div>
                </>
              )}
            </div>
            <div className="row my-2">
              <div className="col-12">
                {room.storage_promotions.map((promo, index) => (
                  <OverlayTrigger
                    key={index}
                    overlay={
                      <Tooltip>
                        If you buy for {promo.total_months} months or more, you
                        will get {promo.value}
                        {promo.type === "percentage_off"
                          ? "% off for"
                          : promo.type === "dollar_off"
                            ? "$ off for"
                            : "$ for first"}{" "}
                        {promo.offer_months} months
                      </Tooltip>
                    }
                    placement="top"
                  >
                    <span
                      className="badge bg-danger m-1"
                      style={{ cursor: "default" }}
                    >
                      {promo.value}
                      {promo.type === "percentage_off"
                        ? "% off"
                        : promo.type === "dollar_off"
                          ? "$ off"
                          : `$ for first ${promo.offer_months} months`}
                    </span>
                  </OverlayTrigger>
                ))}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <p className=" text-break ms-1">
                  {room.description}
                  {/* Contrary to popular belief, Lorem Ipsum is
                  not simply random text. It has roots in a piece of classical
                  Latin literature from 45 BC, making it over 2000 years old.
                  Richard McClintock, */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
});

export default IndoorStorageList;
