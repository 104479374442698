export const GET_SERVICE_PROVIDER_REQUEST = "GET_SERVICE_PROVIDER_REQUEST";
export const GET_SERVICE_PROVIDER_SUCCESS = "GET_SERVICE_PROVIDER_SUCCESS";
export const GET_SERVICE_PROVIDER_FAIL = "GET_SERVICE_PROVIDER_FAIL";

export const CREATE_SERVICE_PROVIDER_REQUEST =
  "CREATE_SERVICE_PROVIDER_REQUEST";
export const CREATE_SERVICE_PROVIDER_SUCCESS =
  "CREATE_SERVICE_PROVIDER_SUCCESS";
export const CREATE_SERVICE_PROVIDER_FAIL = "CREATE_SERVICE_PROVIDER_FAIL";
export const CREATE_SERVICE_PROVIDER_CLEAR = "CREATE_SERVICE_PROVIDER_CLEAR";

export const UPDATE_SERVICE_PROVIDER_REQUEST =
  "UPDATE_SERVICE_PROVIDER_REQUEST";
export const UPDATE_SERVICE_PROVIDER_SUCCESS =
  "UPDATE_SERVICE_PROVIDER_SUCCESS";
export const UPDATE_SERVICE_PROVIDER_FAIL = "UPDATE_SERVICE_PROVIDER_FAIL";
export const UPDATE_SERVICE_PROVIDER_CLEAR = "UPDATE_SERVICE_PROVIDER_CLEAR";
