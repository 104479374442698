import React from "react";
import { AiOutlineStar } from "react-icons/ai";
import { AiTwotoneStar } from "react-icons/ai";
const StorageUnitReviews = ({ rating }) => {
  const starArray = Array.from({ length: 5 });
  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  const firstLetter = rating?.user.email.charAt(0);
  return (
    <div
      className="justify-content-start align-items-center d-flex flex-column"
      style={{ maxWidth: "250px", minHeight: "250px" }}
    >
      <div
        className="rounded-circle avatar-letter mr-3"
        style={{ background: randomColor, width: "70px", height: "70px" }}
      >
        <span className="text-white d-flex justify-content-center align-items-center h-100 ">
          {firstLetter}
        </span>
      </div>
      <div
        className="media-body justify-content-center align-items-center d-flex flex-column "
        style={{
          gap: "15px",
        }}
      >
        <h5 className="mt-3 px-3">{rating?.user.email}</h5>
        <div className="d-flex align-items-center">
          <div className="d-flex">
            {starArray.map((_, index) =>
              index >= rating?.user.facility_ratings ? (
                <div
                  className="cursor-pointer my-3"
                  style={{ cursor: "pointer" }}
                  key={index}
                >
                  <AiOutlineStar size={27} />
                </div>
              ) : (
                <div className="cursor-pointer" key={index}>
                  <AiTwotoneStar
                    size={27}
                    style={{ color: "#FFCC00", cursor: "pointer" }}
                  />
                </div>
              ),
            )}
          </div>
        </div>
        <p className="text-center">{rating?.comment}</p>
      </div>
    </div>
  );
};
export default StorageUnitReviews;
