import { useEffect, useState } from "react";

const serverURL = "wss://3.82.183.189"; // Update the URL to use wss

function useNotifications(user_id) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const socket = new WebSocket(
      `${serverURL}/apps/ws/notifications/${user_id}/`,
    );

    socket.addEventListener("open", (event) => {
      console.log("WebSocket connection opened:", event);
    });

    socket.addEventListener("message", (event) => {
      const notification = JSON.parse(event.data);
      setNotifications(notification); // Update the state with the accumulated notifications
    });

    socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed:", event);
    });

    return () => {
      socket.close();
    };
  }, [user_id]);

  return { notifications };
}

export default useNotifications;
