import React, { useState } from "react";
import Select from "react-select";
import GeoApiAuto from "../../../GeoApiAuto/GeoApiAuto";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const UnitStorageForm = () => {
  const [formData, setFormData] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const getLocationData = (data, id) => {
    if (!data) {
      return setFormData((prev) => {
        return {
          ...prev,
          [id]: "",
        };
      });
    }

    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.county || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };

    setFormData((prev) => {
      return {
        ...prev,
        [id]: locationData.address,
      };
    });
  };

  const unitSizeOptions = [
    { value: "small", label: "Small (Storage Locker, 5x5, 5x10)" },
    { value: "medium", label: "Medium (5x15, 10x10, 10x15)" },
    { value: "large", label: "Large (10x20, 10x25, 10x30)" },
  ];

  const typeOfStorage = [
    { value: "climate_control", label: "Climate Control" },
    { value: "indoor_storage", label: "Indoor Storage" },
    { value: "outdoor", label: "Outdoor/Drive Up" },
  ];

  const selectedSizeHandler = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    const smallSizeSelected = selectedValues.includes("small");
    setFormData((prevState) => ({
      ...prevState,
      small_size: smallSizeSelected ? 1 : 0,
    }));

    const mediumSizeSelected = selectedValues.includes("medium");
    setFormData((prevState) => ({
      ...prevState,
      medium_size: mediumSizeSelected ? 1 : 0,
    }));

    const largeSizeSelected = selectedValues.includes("large");
    setFormData((prevState) => ({
      ...prevState,
      large_size: largeSizeSelected ? 1 : 0,
    }));
  };

  const selectedTypeHandler = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prev) => {
      return {
        ...prev,
        storage_type: selectedValues,
      };
    });
  };

  const navigate = useNavigate();

  const submitFormHandler = (event) => {
    event.preventDefault();
    if (!formData.location) {
      return toast.error("Location is Required.");
    }

    localStorage.setItem("us_Storage_facility", JSON.stringify(formData));

    navigate({
      pathname: "/storages/results",
      search: `?${createSearchParams(formData)}`,
    });
  };

  return (
    <div className="px-3 pt-3 d-flex col-md-12">
      <div className="col-12 col-xl-8">
        <form>
          <div className="d-none d-md-block" style={{ textAlign: "center" }}>
            <h4
              style={{
                textAlign: "center",
                color: "white",
                marginBottom: "10px",
              }}
            >
              Find your stuff a home. <br />
              Find and reserve storage in minutes with FindxStorage.
            </h4>
          </div>
          <hr style={{ borderColor: "white" }} />
          <div className="d-flex flex-column flex-md-row mt-1">
            <div className="form-group col-md-6 col-12">
              <label
                htmlFor="size"
                style={{ color: "white", fontSize: "20px" }}
              >
                Unit Size (Optional)
              </label>
              <div className="mt-2 justify-content-center  p-1">
                <Select
                  classNames={{
                    control: (state) =>
                      state.isFocused ? "border-0" : "border-0",
                  }}
                  options={unitSizeOptions}
                  isMulti
                  placeholder="Select Unit Size"
                  onChange={selectedSizeHandler}
                />
              </div>
            </div>
            <div className="form-group col-md-6 col-12 ml-2">
              <label
                htmlFor="typeOfUnit"
                style={{ color: "white", fontSize: "20px" }}
              >
                Type Of Storage (Optional)
              </label>
              <div className="mt-2 justify-content-center p-1">
                <Select
                  classNames={{
                    control: (state) =>
                      state.isFocused ? "border-0" : "border-0",
                  }}
                  options={typeOfStorage}
                  isMulti
                  placeholder="Select type"
                  onChange={selectedTypeHandler}
                />
              </div>
            </div>
          </div>
          <div className="" style={{ width: "100%", marginLeft: "0.5%" }}>
            <div className="form-group w-100">
              <div className="mt-2 d-block d-md-flex justify-content-center col-md-12">
                <label
                  className="d-md-none"
                  htmlFor="size"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  Location
                </label>
                <div className="form-control p-0">
                  <GeoApiAuto id="location" getLocationData={getLocationData} />
                </div>
                <div
                  className="col-12 col-md-3 mt-2 mt-md-0"
                  style={{ marginRight: "-12%", marginLeft: "1%" }}
                >
                  <Link to="/storages/results">
                    <button
                      className="btn btn-lg btn-default text-dark"
                      type="button"
                      onClick={submitFormHandler}
                    >
                      Find Units
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        className="d-none d-xl-block col-lg-4"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 300,
          // marginLeft: "15%",
        }}
      >
        <div
          style={{
            float: "right",
            width: 300,
            height: 300,
            borderRadius: "50%",
            overflow: "hidden",
            border: "5px solid white",
          }}
        >
          <img
            src="/images/banner-round-image.webp"
            alt="Profile"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
};

export default UnitStorageForm;
