import React, { useEffect, useState } from "react";
import "./cookiespopup.css";

const CookiesPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has previously accepted cookies
    const hasAcceptedCookies = localStorage.getItem("acceptedCookies");

    if (!hasAcceptedCookies) {
      // If not, show the popup
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    // Set a localStorage flag to indicate that the user has accepted cookies
    localStorage.setItem("acceptedCookies", "true");

    // Hide the popup
    setIsVisible(false);
  };

  const declineCookies = () => {
    // You can handle declining cookies as needed
    // For example, you can implement different behavior or options here
    // For now, we will just hide the popup
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`cookie-notice ${isVisible ? "visible" : ""}`}>
      <div className="cookie-icon">🍪</div>
      <div className="cookie-text">
        This site uses cookies to improve your experience. By continuing to
        browse the site, you agree to our use of cookies.
      </div>
      <div className="cookie-buttons">
        <button className="cookie-button accept-button" onClick={acceptCookies}>
          Accept
        </button>
        <button
          className="cookie-button decline-button"
          onClick={declineCookies}
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default CookiesPopup;
