import React, { useEffect, useState } from "react";
import UnitStorageStatusTable from "./UnitStorageStatusTable";
import UnitStorageReviewsTable from "./UnitStorageReviewsTable";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
import { getAllStorageUnits } from "../../../actions/storageUnitActions";

const scrollStyle = {
  maxHeight: "300px",
  height: "100%",
  overflow: "auto",
};

const AnalyticsReviewsTableAndUnitStatus = ({ StorageUnitData, count }) => {
  const [selectedFacility, setSelectedFacility] = useState({ name: "" });
  const [selectedReviews, setSelectedReviews] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [itemWithRatings, setItemWithRatings] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    const facilityNames = StorageUnitData?.map((item) => item.name);
    setFacilities(facilityNames);
  }, [StorageUnitData]);

  useEffect(() => {
    const itemWithRatings = StorageUnitData?.find(
      (item) => item.facility_ratings.length !== 0,
    );
    setItemWithRatings(itemWithRatings);
  }, [StorageUnitData]);

  const handleChange = (event) => {
    setSelectedFacility({
      name: event.target.value,
    });
  };

  useEffect(() => {
    if (selectedFacility.name) {
      const filteredByName = StorageUnitData?.find(
        (item) => item.name === selectedFacility.name,
      );
      setSelectedReviews(filteredByName || {});
    } else {
      setSelectedReviews(null);
    }
  }, [selectedFacility, StorageUnitData]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getAllStorageUnits(pageNumber));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      <div className="col-md-6 mb-2" style={scrollStyle}>
        <div className="card h-100">
          <div className="card-body">
            <h5 className="mb-3">Storage Facility Status</h5>
            <UnitStorageStatusTable
              data={StorageUnitData.slice(startIndex, endIndex)}
            />
          </div>
          <div className="text-center m-auto">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={count}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-2" style={scrollStyle}>
        <div className="card h-100">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h5 className="mb-3">User Reviews</h5>
              <div>
                <label htmlFor="duration" className="pb-2">
                  Storage Facilities{" "}
                </label>
                <select
                  id="facilities"
                  value={selectedFacility.name}
                  onChange={handleChange}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="">Select Facility</option>
                  {facilities.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <UnitStorageReviewsTable
              data={!selectedReviews ? itemWithRatings : selectedReviews}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsReviewsTableAndUnitStatus;
