// import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./SizingGuidPage.css";
import { useLocation, Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
//import Card from './cardComponent';
import React, { useEffect, useState } from "react";
import faq from "./Faq.jpg";

const SizingGuidPage = () => {
  const data = [
    {
      title: "Small Units",
      items: [
        {
          title: "Lockers",
          description: "A few Things",
          image_url: "/images/size-guide-1.svg",
        },
        {
          title: "5'x5'",
          description: "Large Closet",
          image_url: "/images/size-guide-2.svg",
        },
        {
          title: "5'x10'",
          description: "One Room",
          image_url: "/images/size-guide-3.svg",
        },
      ],
    },
    {
      title: "Medium Units",
      items: [
        {
          title: "5'x15'",
          description: "Two Rooms",
          image_url: "/images/size-guide-4.svg",
        },
        {
          title: "10'x10'",
          description: "Three Rooms",
          image_url: "/images/size-guide-5.svg",
        },
      ],
    },
    {
      title: "Large Units",
      items: [
        {
          title: "10'x15'",
          description: "Four Rooms",
          image_url: "/images/size-guide-6.svg",
        },
        {
          title: "10'x20'",
          description: "Five Rooms",
          image_url: "/images/size-guide-7.svg",
        },
        {
          title: "10'x25'",
          description: "Six Rooms +",
          image_url: "/images/size-guide-8.svg",
        },
      ],
    },
    {
      title: "Vehicle units",
      items: [
        {
          title: "Up To 20'",
          description: "Most Cars",
          image_url: "/images/size-guide-9.svg",
        },
        {
          title: "Up To 35",
          description: "Most RV's and Boats",
          image_url: "/images/size-guide-10.svg",
        },
        {
          title: "Up to 50'",
          description: "Oversize Vehicles",
          image_url: "/images/size-guide-11.svg",
        },
      ],
    },
  ];

  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const name = queryParams.get("name")
  //   ? decodeURIComponent(queryParams.get("name"))
  //   : "lockers";

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".animate-on-scroll");
      elements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight) {
          element.classList.add("active");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const quest = [
    {
      title: `Storage Unit Size`,
      questions: [
        {
          question: "How big is a storage unit?",
          answer: `The self storage units are 5 feet wide and 15 feet long, totaling 75 square feet. This medium-sized storage unit is the size of a large walk-in closet. Many of our facilities offer units with an 8-foot ceiling, equalling up to 600 cubic feet of packing space. \n Heights and availability will vary based on location. Find your nearest FindxStorage location to check the exact dimensions at their facility.`,
        },
        {
          question: "What can you fit in a storage unit?",
          answer: `A 75-square-foot storage unit can hold the contents of an entire bedroom with space for a few additional items. If you’re moving out of a small apartment, this is a popular self storage unit option to store your belongings.`,
        },
        {
          question: "What does a storage unit look like?",
          answer: `Our storage units are comparable in size to a large walk-in closet you would find in your home. In this size of storage unit, you usually will have enough space to walk around, stand and browse your stored items.`,
        },
        {
          question: "How do you pack a storage unit?",
          answer: `There’s a lot you can do to maximize your storage space to get the most out of your storage unit. When you’re packing the unit, stack boxes on top of each other to take advantage of vertical space. To do this without it being a safety hazard, use heavy boxes as a base, and lightweight boxes on the top. Since you’ll have 75 square feet of room in your storage unit, it's a great idea to also make an aisle. This will give you space to browse all of your items so you can have easy access to your properly labeled boxes, should you only need to pull one or two items out of storage.`,
        },
      ],
    },
    {
      title: `Storage Unit Items`,
      questions: [
        {
          question: "What can I fit in a storage unit?",
          answer: (
            <div>
              <p>
                Our storage units can hold up to about two rooms of space. If
                you’re moving or completing a home renovation, this is a great
                option to hold boxes and furniture such as sofas, desks, kitchen
                tables and chairs, and more.
              </p>
              <div className="container bgGray">
                <div className="row animate-on-scroll slide-in-left">
                  <div className="col-md-6 px-3 py-2">
                    <h6>Furniture</h6>
                    <ul className="list-styled small">
                      <li>Bedroom furniture</li>
                      <li>Sofa</li>
                      <li>Desk</li>
                      <li>Kitchen table</li>
                    </ul>
                  </div>
                  <div className="col-md-6 px-3 py-2">
                    <h6>Appliances</h6>
                    <ul className="list-styled small">
                      <li>Refrigerator</li>
                      <li>Washer</li>
                      <li>Dryer</li>
                    </ul>
                  </div>
                  <div className="col-md-6 px-3 py-2">
                    <h6>Entertainment</h6>
                    <ul className="list-styled small">
                      <li>TVs</li>
                      <li>Books</li>
                      <li>Toys</li>
                      <li>Instruments</li>
                      <li>Collectibles</li>
                      <li>Records</li>
                    </ul>
                  </div>
                  <div className="col-md-6 px-3 py-2">
                    <h6>Misc.</h6>
                    <ul className="list-styled small">
                      <li>Small to medium-sized boxes</li>
                      <li>Gardening supplies</li>
                      <li>Luggage</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ),
        },
        {
          question: "Can a queen-size bed fit in a storage unit?",
          answer: `Of course! Actually, you will have enough storage space for a king-sized bed, including the box spring and more. If you’re looking for additional storage, our 10x10 storage units can hold up to three rooms of space, giving you 100 square feet of storage space.`,
        },
        {
          question:
            "Is a storage unit big enough for temporary furniture storage during a home renovation?",
          answer: `Depending on the size of your remodeling project, a storage unit would work well for temporary furniture storage. If you were looking to remodel a smaller space like a kitchen or a bedroom, a storage space would suffice.`,
        },
      ],
    },
    {
      title: `Storage Unit Prices`,
      questions: [
        {
          question: "How much does a storage unit cost?",
          answer: `The price of your storage unit will depend on the location of your facility and its current availability. FindxStorage units are available for upgrades as well with features such as climate control, which will be available at a different price point. Find storage units near you to get information about competitive prices in your area. Each self storage unit comes with no long-term commitment and a free reservation.`,
        },
        {
          question: "How do I find cheap storage units near me?",
          answer: `FindxStorage is the world’s leading provider of self storage units. Our medium-sized storage units are found at most of our facilities. Prices vary by location, but we have thousands of options nationwide for you to find a cheap storage unit near you.`,
        },
      ],
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the active panel if clicked again
    } else {
      setActiveIndex(index); // Open the clicked panel
    }
  };

  return (
    // d-flex flex-column align-items-center justify-content-center min-vh-100
    <div className="container">
      <header style={{ marginTop: "150px" }}>
        <h1 className="text-center fw-bold">Storage Unit</h1>
      </header>

      <div className="row mt-5">
        <section className="col-md-8 mt-4 text-left custom-text-section">
          <div
            className="col-md-10"
            style={{ textAlign: "justify", marginTop: "50px" }}
          >
            <p>
              No matter if you're a first-time renter or a returning customer,
              FindxStorage is committed to providing you with the peace of mind
              that comes from knowing your belongings are safe and secure. Our
              goal is to ensure that every customer, whether new or returning,
              has a positive and worry-free storage experience. Here, we aim to
              help answer any questions you may have about our storage units.
            </p>
            <p>
              Having trouble finding the information you're looking for?{" "}
              <Link to="/contact-us">contact-us</Link>, and we'll be happy to
              answer any further questions.
            </p>
          </div>
        </section>
        <div className="col-md-4">
          <div className="image-container">
            <img src={faq} alt="faq" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="justify-content-center align-items-center mt-5">
        <h1 className="text-center fw-bold">Size Guide</h1>
        {data.map((category, index) => (
          <React.Fragment key={index}>
            <div className="sizing-table mt-5">
              <h4 className="fw-bold text-center mt-2">{category.title}</h4>
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody className="">
                  {category.items.map((item, itemIndex) => (
                    <tr key={itemIndex} style={{ height: "100px" }}>
                      <td>
                        <img
                          src={item.image_url}
                          alt={item.title}
                          style={{ width: "100px", height: "auto" }}
                        />
                      </td>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div>
        <p className="mt-5 remainder text-center">
          <strong className="text-center d-block mb-3">
            --: Friendly Reminder :--
          </strong>
          As with all our spaces, sizes are approximate — please check your
          Rental Agreement for what can be stored.
        </p>
      </div>

      <h1 className="fw-bold text-center mt-5 animate-on-scroll slide-in-left">
        Frequently Asked Questions
      </h1>
      {quest.map((category, index) => (
        <div key={index} className="accordion mt-5" id="accordionExample">
          <div
            className={`accordion-item rounded bg-gradient`}
            style={{ marginBottom: "10px", cursor: "pointer" }}
          >
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className={`accordion-button ${
                  activeIndex === index ? "" : "collapsed"
                }`}
                type="button"
                aria-expanded={activeIndex === index ? "true" : "false"}
                aria-controls={`collapse${index}`}
                onClick={() => handleToggle(index)}
              >
                {category.title}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${
                activeIndex === index ? "show" : ""
              }`}
              aria-labelledby={`heading${index}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body rounded p-3">
                {activeIndex === index && (
                  <ul className="list-unstyled">
                    {category.questions.map((q, idx) => (
                      <li key={idx} className="mt-2">
                        <button
                          className="btn text-dark btn-link text-decoration-none"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseAnswer${index}-${idx}`}
                          aria-expanded="false"
                          aria-controls={`collapseAnswer${index}-${idx}`}
                        >
                          {q.question}
                        </button>
                        <div
                          className="collapse mt-3"
                          id={`collapseAnswer${index}-${idx}`}
                        >
                          <div className="card card-body bg-light p-3">
                            {q.answer}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* <section className="mt-4 text-left col-md-12 animate-on-scroll fade-in">
        <div className="container mt-5">
          <h2 className="text-center">
            <b>Other Storage Unit Sizes</b>
          </h2>
          <div className="row">
            {data.map((category, index) => (
              <div className="col-md-3 mb-3 text-left" key={index}>
                <h4 className="mb-3 mt-5" >
                  <b>{category.title}</b>
                </h4>
                <ul className="list-unstyled text-left">
                  {category.items.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      <Link to={`/SizingGuidPage?name=${item.title}`}>
                        <p className="mt-2 blueTextColr mb-1">
                          <i className="fas blueTextColr fa-box"></i> {item.title} -{" "}
                          {item.description}
                        </p>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <div className="container mt-5">
        {/* <div className="row">
          <Card
            title="Storage Tips & Advice"
            description="From cabinets to containers, find the perfect place for all your stuff."
            buttonText="Read More"
          />
          <Card
            title="What is Climate Controlled Storage?"
            description="Peace of mind and comfortable access to the things you cherish..."
            buttonText="Read More"
          />
          <Card
            title="How to Pack for a Move: 3 Ways to Get Started"
            description="Packing doesn't have to be stressful. Save time and energy with 3 simple steps."
            buttonText="Read More"
          />
        </div> */}

        <div className="container mt-5">
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card p-4">
                <div className="card-body text-center">
                  <div className="card-icon mb-3">
                    <img
                      src="https://media.istockphoto.com/id/1648256515/vector/isometric-exterior-of-a-modern-storage-room-for-a-warehouse-of-home-appliances-lamps.jpg?s=612x612&w=0&k=20&c=sBWZgez9v_ReUHDpz2CJOfk9iLvb6u9TSzNpXmjshXc="
                      width={150}
                      height={100}
                      alt="storagetype"
                    />
                  </div>
                  <h5 className="card-title mt-3">Storage Tip And advice</h5>
                  <p className="card-text">From Cabinate To Container</p>
                  <Link
                    to={`/storage-type?name=Self%20Storage`}
                    className="btn btn-primary btn-custom text-dark"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card p-4">
                <div className="card-body text-center">
                  <div className="card-icon mb-3">
                    <img
                      src="https://static.vecteezy.com/system/resources/previews/014/028/877/original/climate-control-system-change-temperature-air-conditioning-cooling-or-heating-refrigerator-storage-free-vector.jpg"
                      width={100}
                      height={100}
                      alt="storagetype"
                    />
                  </div>
                  <h5 className="card-title mt-3">
                    What is Climate Control Change
                  </h5>
                  <p className="card-text">Peace of mind and Comfortable</p>
                  <Link
                    to={`/storage-type?name=Self%20Storage`}
                    className="btn btn-primary btn-custom text-dark"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card p-4">
                <div className="card-body text-center">
                  <div className="card-icon mb-3">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHrHQcBcn-phRm-BSALwO6iJlFWjQ_QrGqFDngFQHmRhH7HQqrEzEvlu4azEB4dAc8M2w&usqp=CAU"
                      width={150}
                      height={100}
                      alt="storagetype"
                    />
                  </div>
                  <h5 className="card-title mt-3">How to Pack for a move</h5>
                  <p className="card-text">
                    Packing doesnot have to be stressful
                  </p>
                  <Link
                    to={`/storage-type?name=Self%20Storage`}
                    className="btn btn-primary btn-custom text-dark"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizingGuidPage;
