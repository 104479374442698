import React from "react";
import Container from "../../../shared-components/container/Container";

const HomeHotOffers = () => {
  return (
    <Container>
      <p className="text-center">
        FindxStorage® is the platform that connects you with the perfect
        storage space to meet your needs. Each listed storage facility offers a
        unique variety of sizes to fit local customers' requirements. Choosing
        the right storage unit size can be challenging. Here's a quick breakdown
        of each room by size category.
      </p>
      <div className="row gx-5 mt-5 ">
        <div className="col-md-4 mb-3 mb-md-0">
          <div className="card h-100">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#F8F9FA", height: "100%" }}
            >
              <div>
                <h5 className="card-title" style={{ paddingLeft: "5%" }}>
                  Small Storage Units
                </h5>
                <span className="badge badge-pill badge-light mt-2 text-dark bg-light border">
                  25 - 75 sq ft.
                </span>
              </div>
              <div>
                <img
                  src="/images/small.png"
                  className="card-img-top mt-2"
                  alt="unit-small"
                  style={{
                    height: 150,
                  }}
                />
              </div>
            </div>
            <div className="card-body bg-light text-center p-4">
              <h6>Range in size 5’ x 5’, 5’ x 10’, 5’ x 15’</h6>
              <h6>Looks like:</h6>
              <p className="card-text">
                Anywhere from a hall closet or half bathroom to a small bedroom
                depending on the storage unit size.
              </p>
              <h6>What will fit:</h6>
              <p>
                Small items, like bags, suitcases and boxes to the contents of a
                small-one bedroom apartment.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3 mb-md-0">
          <div className="card h-100">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#F8F9FA", height: "100%" }}
            >
              <div>
                <h5 className="card-title" style={{ paddingLeft: "5%" }}>
                  Medium Storage Units
                </h5>
                <span className="badge badge-pill badge-light mt-2 text-dark bg-light border">
                  75 to 200 SQ FT
                </span>
              </div>
              <div>
                <img
                  src="/images/medium.png"
                  className="card-img-top mt-2"
                  alt="unit-medium"
                  style={{
                    height: 150,
                  }}
                />
              </div>
            </div>
            <div className="card-body bg-light text-center p-4">
              <h6>Range in size 10’ x 10’, 10’ x 15’, 10’ x 20’</h6>
              <h6>Looks like:</h6>
              <p className="card-text">
                Dependent on the unit size, medium sized units look like an
                average bedroom.
              </p>
              <h6>What will fit:</h6>
              <p>
                Ranges from the contents of a one-bedroom apartment to the
                contents of a two-to-three bedroom house.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3 mb-md-0">
          <div className="card h-100">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#F8F9FA", height: "100%" }}
            >
              <div>
                <h5 className="card-title" style={{ paddingLeft: "5%" }}>
                  Large Storage Units
                </h5>
                <span className="badge badge-pill badge-light mt-2 text-dark bg-light border">
                  200 + SQ FT
                </span>
              </div>
              <div>
                <img
                  src="/images/large.png"
                  className="card-img-top mt-2"
                  alt="unit-large"
                  style={{
                    height: 150,
                  }}
                />
              </div>
            </div>
            <div className="card-body bg-light text-center p-4">
              <h6>Range in size 10’ x 25’, 10’ x 30’</h6>
              <h6>Looks like:</h6>
              <p className="card-text">
                A one car garage to a two car garage depending on the unit size.
              </p>
              <h6>What will fit:</h6>
              <p>
                Ranges from the contents of a three-bedroom house to a four to
                five bedroom house with appliances.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomeHotOffers;
