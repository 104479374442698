import React, { useState, useEffect } from "react";
import BarChart from "./BarChart";
import RecentUnits from "./RecentUnits";

const AnalyticsRecentUnitsAndChart = ({ StorageUnitData }) => {
  const [selectedFacility, setSelectedFacility] = useState({ name: "" });
  const [facilities, setFacilities] = useState([]);
  const [chartData, setChartData] = useState({
    labels: StorageUnitData.map((unit) => unit.name),
    datasets: [
      {
        label: "Total Units",
        data: StorageUnitData.map((unit) => unit.total_storage_units),
        backgroundColor: "#FF7F50",
      },
      {
        label: "Available Units",
        data: StorageUnitData.map((unit) => unit.available_storage_units),
        backgroundColor: "rgba(255, 140, 0, 1)",
      },
      {
        label: "Occupied Units",
        data: StorageUnitData.map((unit) => unit.occupied_storage_unit),
        backgroundColor: "#FFDAB9",
      },
    ],
  });

  const handleChange = (event) => {
    setSelectedFacility({
      name: event.target.value,
    });
  };

  useEffect(() => {
    const facilityNames = StorageUnitData?.map((item) => item.name);
    setFacilities(facilityNames);
  }, [StorageUnitData]);

  useEffect(() => {
    if (selectedFacility.name !== "") {
      const selectedFacilityData = StorageUnitData.find(
        (unit) => unit.name === selectedFacility.name,
      );

      if (selectedFacilityData) {
        // Update chartData with data for the selected facility
        setChartData({
          labels: [selectedFacilityData.name],
          datasets: [
            {
              label: "Total Space",
              data: [selectedFacilityData.total_storage_units],
              backgroundColor: "#FF7F50",
            },
            {
              label: "Available Space",
              data: [selectedFacilityData.available_storage_units],
              backgroundColor: "rgba(255, 140, 0, 1)",
            },
            {
              label: "Occupied Space",
              data: [selectedFacilityData.occupied_storage_unit],
              backgroundColor: "#FFDAB9",
            },
          ],
        });
      }
    } else {
      setChartData({
        labels: StorageUnitData.map((unit) => unit.name),
        datasets: [
          {
            label: "Total Space",
            data: StorageUnitData.map((unit) => unit.total_storage_units),
            backgroundColor: " #FF7F50",
          },
          {
            label: "Available Space",
            data: StorageUnitData.map((unit) => unit.available_storage_units),
            backgroundColor: "rgba(255, 140, 0, 1)",
          },
          {
            label: "Occupied Space",
            data: StorageUnitData.map((unit) => unit.occupied_storage_unit),
            backgroundColor: "#FFDAB9",
          },
        ],
      });
    }
  }, [selectedFacility.name, StorageUnitData]);

  return (
    <>
      <div className="col-md-7 mb-2">
        <div className="card h-100 ">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-3">Single Facility Analytics</h5>
              <div>
                <label htmlFor="duration">Storage Facility</label>
                <select
                  id="facilities"
                  value={selectedFacility.name}
                  onChange={handleChange}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="">Select Facility</option>
                  {facilities.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <BarChart chartData={chartData} />
          </div>
        </div>
      </div>
      <div className="col-md-5 mb-2">
        <div className="card h-100">
          <div className="card-body">
            <h5 className="mb-3">Recent Updates</h5>
            <RecentUnits />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsRecentUnitsAndChart;
