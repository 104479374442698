import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import FilterCards from "./FilterCards";
import StorageUnitsList from "./StorageUnitsList";
import StorageUnitsMap from "./StorageUnitsMap";
import { useSelector } from "react-redux";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import { getAllUsStorageFacilities } from "../../../actions/usStorageFacilityActions";

const FiltersAndStorages = ({ view }) => {
  const { loading, error, storageFacilties } = useSelector(
    (state) => state.usStorageFacilities,
  );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePagination = (str) => {
    const nextPageUrl =
      str === "next" ? storageFacilties.next : storageFacilties.previous;

    if (nextPageUrl) {
      try {
        const url = new URL(nextPageUrl);
        const location = url.searchParams.get("location") || "";
        const storage_type = url.searchParams.get("storage_type") || "";
        const small_size = Number(url.searchParams.get("small_size")) || 0;
        const medium_size = Number(url.searchParams.get("medium_size")) || 0;
        const large_size = Number(url.searchParams.get("large_size")) || 0;
        const pageValue = Number(url.searchParams.get("page")) || 1;
        const formDataObject = {
          location,
          storage_type,
          small_size,
          medium_size,
          large_size,
          pageValue,
        };

        setCurrentPage(pageValue);
        dispatch(getAllUsStorageFacilities(formDataObject));
      } catch (error) {
        console.error("Error constructing URL:", error);
      }
    }
  };

  return (
    <div className="row my-3">
      <div className="col-md-3">
        <FilterCards />
      </div>
      <div className="col-md-9">
        {loading ? (
          <Spinner />
        ) : error ? (
          <div className="alert alert-danger" role="alert">
            {error.msg}
          </div>
        ) : (
          <>
            {view.list ? (
              <div>
                <StorageUnitsList storageFacilties={storageFacilties} />
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    gap: "50px",
                  }}
                >
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={storageFacilties?.count}
                    pageRangeDisplayed={5}
                    onChange={(page) => {
                      setCurrentPage(page);
                      handlePagination(
                        page === currentPage + 1 ? "next" : "previous",
                      );
                    }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            ) : (
              <StorageUnitsMap storageFacilties={storageFacilties} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FiltersAndStorages;
