import React from "react";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Small Units",
    items: [
      {
        title: "Lockers",
        description: "A few Things",
        image_url: "/images/size-guide-1.svg",
      },
      {
        title: "5'x5'",
        description: "Large Closet",
        image_url: "/images/size-guide-2.svg",
      },
      {
        title: "5'x10'",
        description: "One Room",
        image_url: "/images/size-guide-3.svg",
      },
    ],
  },
  {
    title: "Medium Units",
    items: [
      {
        title: "5'x15'",
        description: "Two Rooms",
        image_url: "/images/size-guide-4.svg",
      },
      {
        title: "10'x10'",
        description: "Three Rooms",
        image_url: "/images/size-guide-5.svg",
      },
    ],
  },
  {
    title: "Large Units",
    items: [
      {
        title: "10'x15'",
        description: "Four Rooms",
        image_url: "/images/size-guide-6.svg",
      },
      {
        title: "10'x20'",
        description: "Five Rooms",
        image_url: "/images/size-guide-7.svg",
      },
      {
        title: "10'x25'",
        description: "Six Rooms +",
        image_url: "/images/size-guide-8.svg",
      },
    ],
  },
  {
    title: "Vehicle units",
    items: [
      {
        title: "Up To 20'",
        description: "Most Cars",
        image_url: "/images/size-guide-9.svg",
      },
      {
        title: "Up To 35",
        description: "Most RV's and Boats",
        image_url: "/images/size-guide-10.svg",
      },
      {
        title: "Up to 50'",
        description: "Oversize Vehicles",
        image_url: "/images/size-guide-11.svg",
      },
    ],
  },
];

function SizeGuideDropdownContent() {
  return (
    <div className="container">
      <div className="size-guide-dropdown row">
        {data.map((category, index) => (
          <div className="col-md-4 col-lg-3 mb-3" key={index}>
            <h4 className="text-center mt-3">{category.title}</h4>
            {category.items.map((item, itemIndex) => (
              <div className="size-guide-item text-center mt-3" key={itemIndex}>
                <span
                  className="d-block"
                  style={{
                    backgroundImage: `url(${item.image_url})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "80px",
                    height: "80px",
                    margin: "auto",
                  }}
                ></span>
                <Link to={`/SizingGuidPage?name=${item.title}`}>
                  <p style={{ margin: "0" }}>{item.title}</p>
                </Link>
                <p style={{ margin: "0" }}>{item.description}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SizeGuideDropdownContent;
