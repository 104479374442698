export const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST";
export const CART_ADD_ITEM_SUCCESS = "CART_ADD_ITEM_SUCCESS";
export const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL";
export const CART_ADD_ITEM_RESET = "CART_ADD_ITEM_RESET";

export const CART_GET_ITEM_REQUEST = "CART_GET_ITEM_REQUEST";
export const CART_GET_ITEM_SUCCESS = "CART_GET_ITEM_SUCCESS";
export const CART_GET_ITEM_FAIL = "CART_GET_ITEM_FAIL";
export const CART_GET_ITEM_RESET = "CART_GET_ITEM_RESET";

export const CART_UPDATE_ITEM_REQUEST = "CART_UPDATE_ITEM_REQUEST";
export const CART_UPDATE_ITEM_SUCCESS = "CART_UPDATE_ITEM_SUCCESS";
export const CART_UPDATE_ITEM_FAIL = "CART_UPDATE_ITEM_FAIL";
export const CART_UPDATE_ITEM_RESET = "CART_UPDATE_ITEM_RESET";

// types.js
