import React from "react";
import Container from "../../../shared-components/container/Container";

const ClimateControl = () => {
  return (
    <div>
      <Container>
        <h1>ClimateControl</h1>
      </Container>
    </div>
  );
};

export default ClimateControl;
