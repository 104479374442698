import Container from "../container/Container";

import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GiOrange } from "react-icons/gi";

const Footer = () => {
  const { info } = useSelector((state) => state.userInfo);
  return (
    <>
      <div
        className="bg-dark text-white pt-3 pb-3 d-flex flex-column "
        style={{
          gap: "50px",
        }}
      >
        {/* <div className="container ">
          <div className="row justify-content-between align-items-start col-9 col-md-8">
            <div className="col-md-4 col-lg-3">
              <Link to="/" className="navbar-brand">
                <h3>
                  <span className="mineTextOrange">
                    <GiOrange />
                    range
                  </span>
                </h3>
              </Link>
              <ul className="list-unstyled  ">
                <li className="mt-4">Our Community</li>
                <div
                  className="d-flex mt-3"
                  style={{
                    gap: "20px",
                  }}
                >
                  <li>
                    <FaFacebook
                      className="text-xl"
                      style={{ color: "#1877F2" }}
                      size={30}
                    />
                  </li>
                  <li>
                    <FaTwitter
                      className="text-xl"
                      style={{ color: "#1DA1F2" }}
                      size={30}
                    />
                  </li>
                  <li>
                    <FaInstagram
                      className="text-xl"
                      style={{ color: "#C13584" }}
                      size={30}
                    />
                  </li>
                  <li>
                    <FaLinkedin
                      className="text-xl"
                      style={{ color: "#2867B2" }}
                      size={30}
                    />
                  </li>
                </div>
              </ul>
            </div>

            <div className="col-md-2 col-lg-2 col-2 ">
              <h5 className="font-weight-bold" style={{ color: "#8704f5" }}>
                Company
              </h5>
              <ul className="list-unstyled">
                <li>Home</li>
                <li>Connect</li>
                <li>Pricing</li>
              </ul>
            </div>

            <div className="col-md-2 col-lg-2 col-2">
              <h5 className="font-weight-bold" style={{ color: "#8704f5" }}>
                Connect
              </h5>
              <ul className="list-unstyled">
                <li>Home</li>
                <li>Pricing</li>
                <li>About</li>
                <li>Aliquet et.</li>
              </ul>
            </div>

            <div className="col-md-2 col-lg-2 ">
              <h5 className="font-weight-bold" style={{ color: "#8704f5" }}>
                Experts
              </h5>
              <ul className="list-unstyled">
                <li>Experts</li>
                <li>Apply as a Talent</li>
                <li>Vetting Guidelines</li>
                <li>Expert Sign in</li>
              </ul>
            </div>
          </div>
        </div> */}
        <Container>
          <p className="m-0 text-center">
            FindxStorage &copy; All Rights Reserved | 2024
          </p>
        </Container>
      </div>
    </>
  );
};

export default Footer;
