import { useState, useEffect } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function AddPromotionModal({
  formFields,
  setFormFields,
  addFieldsHandler,
  storageUnits,
  addPromotionFunction,
  showAddPromoModal,
  setShowAddPromoModal,
}) {
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const handleCheckboxChange = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSelectChange = (selectedValues) => {
    const arrayOfValues = selectedValues.map((item) => parseInt(item.value));
    setSelectedOptions(selectedValues);

    setFormFields({
      ...formFields,
      storage_unit_ids: arrayOfValues,
    });
  };

  const handleCloseModal = () => {
    setShowAddPromoModal(false);
  };

  const addPromotion = (data) => {
    addFieldsHandler(data); // resets fields
    addPromotionFunction(data); // api request
    setSelectedOptions([]);
    setShowAdditionalFields(false);
    setShowAddPromoModal(false);
    handleCloseModal();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      total_months: 0,
      offer_months: 0,
      type: "",
      value: 0,
      facility_id: "",
      storage_unit_ids: [],
    },
    // import * as Yup from 'yup';

    // const validationSchema = Yup.object({
    //   type: Yup.string().required('Required'),
    //   total_months: Yup.number()
    //     .when('type', {
    //       is: value => value === 'percentage_off' || value === 'dollar_off',
    //       then: Yup.number()
    //         .moreThan(0, 'Please select Month/s')
    //         .required('Required'),
    //       otherwise: Yup.number().notRequired() // Adjust validation for other types as needed
    //     }),
    // });

    validationSchema: Yup.object({
      title: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      type: Yup.string()
        .min(1, "Please select Promotion Type")
        .required("Please select Promotion Type"),
      total_months: Yup.number()
        .moreThan(0, "Please select Month/s")
        .required("Required"),
      offer_months: Yup.number()
        .moreThan(0, "Please select Month/s")
        .when(["total_months"], (total_months, schema) =>
          total_months
            ? schema.max(
                total_months,
                "Offer months must be less than or equal to total months",
              )
            : schema,
        ),
      value: Yup.number()
        .moreThan(0, "Please select a value")
        .required("Required"),
    }),
    onSubmit: (values) => {
      const data = {
        ...values,
        storage_unit_ids: formFields.storage_unit_ids,
        facility_id: formFields.facility_id,
      };
      addPromotion(data);
      formik.values.total_months = 0;
      formik.values.offer_months = 0;
    },
  });

  const totalMonthsOptions = Array.from(
    { length: 12 },
    (_, index) => index + 1,
  );

  useEffect(() => {
    // console.log('Formik values:', formik.values);
    // if(formik.values.type==='percentage_off' || formik.values.type=== 'dollar_off')
    if (formik.values.type === "initial_discount_price") {
      formik.values.offer_months = formik.values.total_months;
    }
  }, [formik.values]);

  const renderTooltip = (props) => (
    <Tooltip id="info-tooltip" {...props} className="customtooltip">
      <ul className="text-start">
        <li>
          <strong>Discount in % : </strong>
          <br></br>Offers a percentage discount on the total price for
          offer_months.
        </li>
        <li>
          <strong>Discount in dollars : </strong>
          <br></br>Sets a total discounted price for offer_months{" "}
        </li>
        <li>
          <strong>Fixed Discount Amount : </strong>
          <br></br> Offers a fixed amount discount on total_months.
        </li>
      </ul>
    </Tooltip>
  );

  return (
    <Modal show={showAddPromoModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Promotion</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="py-1">
            <div className="col mx-4">
              <div className="row py-1">
                <label className="fw-bold" htmlFor="title">
                  Promotion Name
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control"
                  placeholder="Promotion Name"
                  {...formik.getFieldProps("title")}
                />
                {formik.errors.title ? (
                  <div className="text-danger">{formik.errors.title}</div>
                ) : null}
              </div>

              <div className="row py-1">
                <label className="fw-bold" htmlFor="type">
                  Type Of Promotion
                  <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                    <Button
                      className="btn btn-dark custom-tooltip"
                      style={{
                        height: "15px",
                        backgroundColor: "transparent",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      <FaInfoCircle
                        style={{ fontSize: "16px", color: "black" }}
                      />
                    </Button>
                  </OverlayTrigger>
                </label>
                <select
                  className="form-select"
                  name="type"
                  id="type"
                  {...formik.getFieldProps("type")}
                >
                  <option value="">-</option>
                  <option value="percentage_off">Discount in percentage</option>
                  <option value="dollar_off">Discount in dollars</option>
                  <option value="initial_discount_price">
                    Fixed Discount Amount
                  </option>
                </select>
                {formik.errors.type ? (
                  <div className="text-danger">{formik.errors.type}</div>
                ) : null}
              </div>

              <div className="row py-1">
                <label className="fw-bold" htmlFor="total_months">
                  Total Months
                </label>
                <select
                  className="form-select"
                  name="total_months"
                  id="total_months"
                  {...formik.getFieldProps("total_months")}
                >
                  <option value={0}>Choose Months</option>
                  {totalMonthsOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                {formik.errors.total_months ? (
                  <div className="text-danger">
                    {formik.errors.total_months}
                  </div>
                ) : null}
              </div>
              {(formik.values.type === "percentage_off" ||
                formik.values.type === "dollar_off") && (
                <>
                  <div className="row py-1">
                    <label className="fw-bold" htmlFor="offer_months">
                      Offer Months
                    </label>
                    <select
                      className="form-select"
                      name="offer_months"
                      id="offer_months"
                      {...formik.getFieldProps("offer_months")}
                    >
                      <option value={0}>Choose Months</option>
                      {totalMonthsOptions.map((value) =>
                        value > formik.values.total_months ? null : (
                          <option
                            key={value}
                            value={value}
                            disabled={value > formik.values.total_months}
                          >
                            {value}
                          </option>
                        ),
                      )}
                    </select>
                    {formik.errors.offer_months ? (
                      <div className="text-danger">
                        {formik.errors.offer_months}
                      </div>
                    ) : null}
                  </div>
                </>
              )}

              <div className="row py-1">
                <label className="fw-bold" htmlFor="value">
                  Value
                </label>
                <input
                  type="number"
                  name="value"
                  id="value"
                  className="form-control"
                  placeholder="Value in terms of % or $"
                  min="0"
                  {...formik.getFieldProps("value")}
                />
                {formik.errors.value ? (
                  <div className="text-danger">{formik.errors.value}</div>
                ) : null}
              </div>

              <div className="form-check py-1">
                <input
                  id="flexCheckChecked"
                  type="checkbox"
                  className="form-check-input"
                  checked={showAdditionalFields}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="fw-bold form-check-label"
                  htmlFor="flexCheckChecked"
                >
                  Attach Storage Unit
                </label>
              </div>

              {showAdditionalFields && (
                <>
                  <div className="row py-1">
                    <label className="fw-bold" htmlFor="facility_id">
                      Select Facility
                    </label>
                    <select
                      className="form-select"
                      name="facility_id"
                      id="facility_id"
                      value={formFields.facility_id}
                      onChange={handleFormChange}
                    >
                      <option value="">-</option>
                      {storageUnits?.results?.map((result, index) => (
                        <option key={index} value={index}>
                          {result?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="row py-1 px-0">
                    <label className="fw-bold" htmlFor="storage_unit_ids">
                      Select Storage Units
                    </label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      name="storage_unit_ids"
                      value={selectedOptions}
                      options={(
                        storageUnits?.results[formFields.facility_id]
                          ?.storage_unit || []
                      ).map((obj) => {
                        return {
                          value: obj.id?.toString(),
                          label: obj.name?.toString(),
                        };
                      })}
                      onChange={handleSelectChange}
                      className="basic-multi-select p-0"
                      classNamePrefix="select"
                    />
                  </div>
                </>
              )}

              <hr />
              <div className="d-flex justify-content-center">
                <button
                  className="border border-0 p-2 px-4 bg-orange text-dark rounded mt-2"
                  type="submit"
                  // onClick={handleSubmission}
                >
                  Add Promotion
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddPromotionModal;
