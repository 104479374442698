import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { useSelector } from "react-redux";
import { BsCartCheck } from "react-icons/bs";
import Notification from "../Notifications/Notifications";
import "./navbar.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
// import {IoArrowDown} from "react-icons/io5";
import { IoWarning } from "react-icons/io5";

import { logoutUser } from "../../actions/userActions";
import { USER_INFO_CLEAR } from "../../constants/userConstants";
import { Spinner } from "react-bootstrap";
import activateStripe from "../../hooks/activateStripe";
import SizeGuideDropdownContent from "./SizeGuideDropdownContent";
const Navbar = () => {
  const { user } = useSelector((state) => state.userLogin);
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);
  const { message, error } = useSelector((state) => state.userLogout);
  const { info } = useSelector((state) => state.userInfo);

  const { cartItems, loading } = useSelector((state) => state.cartGetItems);
  const navigate = useNavigate();
  const [stripeLoading, setStripeLoading] = useState(false);

  const [isNavOpen, setIsNavOpen] = useState(false); // State for navbar toggle

  const dispatch = useDispatch();
  let storageUnits;
  if (!loading && cartItems) {
    storageUnits = cartItems[0].storage_unit;
  }

  useEffect(() => {
    if (message) {
      toast.success(message);
    }
    if (error) {
      toast.error(error);
    }
  }, [message, error]);

  async function handleActiveStripe() {
    setStripeLoading(true);
    const status = await activateStripe(user);
    setStripeLoading(status);
  }

  // Handlers
  const logoutHandler = () => {
    dispatch(logoutUser());
    dispatch({ type: USER_INFO_CLEAR });
    navigate("/");
  };
  const homeLink =
    info && info.is_service_provider
      ? "/service-provider-dashboard/analytics"
      : "/";

  // console.log("Are you a service provider "  , info.is_service_provider);
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top nav"
      style={{ backgroundColor: "#000034" }}
    >
      <div
        className="container-fluid"
        style={{
          padding: "0px !important",
        }}
      >
        <Link to={homeLink} className="navbar-brand">
          <h3>
            <span
              className="mineTextOrange ms-3"
              style={{ marginRight: "10px" }}
            >
              <img src="/images/FindxStorage3.png" height={40} alt="Logo" />
            </span>
            {info && info.is_service_provider && (
              <small
                className=" d-lg-inline text-light"
                style={{
                  position: "absolute",
                  bottom: 1,
                  left: "6%",
                  fontSize: "13px",
                }}
              >
                Service Provider
              </small>
            )}
          </h3>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsNavOpen(!isNavOpen)} // Toggle state on click
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavOpen}
          aria-label="Toggle navigation"
          style={{ color: "white" }}
        >
          {/* <span className="navbar-toggler-icon"> */}
          <i className="fas fa-bars"></i>
          {/* </span> */}
        </button>
        <div
          className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
          id="navbarSupportedContent"
          style={{ backgroundColor: "#000034" }}
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
            <li className="nav-item m-2">
              <Link
                to={homeLink}
                className="nav-link text-light"
                activeClassName="active-link"
              >
                Home
              </Link>
            </li>

            <li className="nav-item m-2">
              <Link to={`/SizingGuidPage`} className="nav-link text-light">
                <p style={{ margin: "0" }}>Size Guide</p>
              </Link>
            </li>

            {/* <a
                className="nav-link dropdown-toggle text-light"
                href="#"
                id="customDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Size Guide
              </a>
              <div
                className="dropdown-menu p-3 size-guide"
                aria-labelledby="customDropdown"
              >
                <SizeGuideDropdownContent />
              </div> */}
            <li className="nav-item m-2">
              <Link to={`/storage-type`} className="nav-link text-light">
                <p style={{ margin: "0" }}>Storage Types</p>
              </Link>
            </li>

            <li className="nav-item m-2">
              <Link
                to="/about-us"
                className="nav-link text-nowrap text-light"
                activeClassName="active-link"
              >
                About Us
              </Link>
            </li>
            <li className="nav-item m-2">
              <Link
                to="/contact-us"
                className="nav-link text-nowrap text-light"
                activeClassName="active-link"
              >
                Contact Us
              </Link>
            </li>

            {user && info ? (
              <>
                {user && !user.service_provider && (
                  <li className="nav-item mx-2">
                    <Link to="/cart" className="nav-link text-light">
                      <button className="border-0 p-2 px-3 bg-outline-orange position-relative text-nowrap">
                        <BsCartCheck size={20} />
                        Cart
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {(cartItems && storageUnits.length) || 0}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </button>
                    </Link>
                  </li>
                )}
                <li className="nav-item m-2 my-3">
                  <Notification />
                </li>
                <li className="nav-item dropdown">
                  <span
                    className="nav-link dropdown-toggle text-light"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="">
                      {user &&
                        user.service_provider &&
                        !user.is_stripe_account_active && (
                          <IoWarning
                            size={22}
                            color="orange"
                            className="warning_sign mb-1"
                          />
                        )}
                      Hi, {serviceProvider ? serviceProvider.name : info.email}
                    </span>
                  </span>
                  <ul className="dropdown-menu ">
                    {user &&
                      user.service_provider &&
                      !user.is_stripe_account_active && (
                        <li>
                          <button
                            className="dropdown-item alert alert-warning"
                            onClick={handleActiveStripe}
                          >
                            <IoWarning size={25} color="orange" /> Please
                            activate your Stripe account{" "}
                            {stripeLoading && (
                              <Spinner
                                animation="border"
                                variant="warning"
                                size="sm"
                              />
                            )}
                          </button>
                        </li>
                      )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/myprofile"
                          >
                            Profile
                          </Link>
                        </li>
                      )}
                    {!user?.service_provider && (
                      <li>
                        <Link className="dropdown-item" to="/myprofile">
                          Profile
                        </Link>
                      </li>
                    )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/analytics"
                          >
                            Dashboard
                          </Link>
                        </li>
                      )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/orders"
                          >
                            Orders
                          </Link>
                        </li>
                      )}
                    {!user?.service_provider && (
                      <li>
                        <Link className="dropdown-item" to="/orders">
                          Orders
                        </Link>
                      </li>
                    )}
                    <li className="m-2">
                      <button
                        className="btn text-dark "
                        style={{ width: "100%" }}
                        onClick={logoutHandler}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item m-2">
                  <Link to="/sign-in">
                    <button className="border-0 p-2 px-3 bg-outline-orange text-dark">
                      Sign In / Sign Up
                    </button>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
