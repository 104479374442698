import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import CartList from "./CartList";
import { cartGetItems } from "../../actions/cartActions";
import CheckoutCard from "./CheckoutCard";
import Spinner from "../../shared-components/Spinner/Spinner";

import "./cart.css";

const Cart = () => {
  const dispatch = useDispatch();
  const [selectedStartingDates, setSelectedStartingDates] = useState([]);
  const [blockCheckoutArray, setBlockCheckoutArray] = useState([]);
  const [blockCheckout, setblockCheckout] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [platformFees, setPlatformFees] = useState(0);
  const [stripeFees, setStripeFees] = useState(0);

  const [unselected, setUnselected] = useState([]);
  const { loading, error, cartItems } = useSelector(
    (state) => state.cartGetItems,
  );

  const [backgroundBlur, setBackgroundBlur] = useState(false);

  useEffect(() => {
    if (cartItems) {
      const cartItemsLength = cartItems[0].storage_unit.length;
      setBlockCheckoutArray(
        Array.from({ length: cartItemsLength }, () => ({
          blockCheckout: false,
        })),
      );
    }
  }, [cartItems]);

  useEffect(() => {
    if (blockCheckoutArray) {
      const areAllFalse = blockCheckoutArray.reduce(
        (accumulator, currentValue) => {
          return accumulator || currentValue.blockCheckout;
        },
        false,
      );

      setblockCheckout(areAllFalse);
    }
  }, [blockCheckoutArray]);

  useEffect(() => {
    let totalPrice = selectedStartingDates.reduce(
      (accumulator, currentValue) => {
        return accumulator + (parseFloat(currentValue.total_price) || 0.0);
      },
      0.0,
    );
    let finalPrice = selectedStartingDates.reduce(
      (accumulator, currentValue) => {
        return accumulator + (parseFloat(currentValue.final_price) || 0.0);
      },
      0.0,
    );
    let discountPrice = totalPrice - finalPrice;
    let platformFees = finalPrice === 0 ? 0 : (5 * finalPrice) / 100;
    let stripeFees =
      finalPrice === 0 || platformFees === 0
        ? 0
        : (3 * (finalPrice + platformFees)) / 100;
    stripeFees = stripeFees + 0.3;

    totalPrice = totalPrice.toFixed(2);
    finalPrice = finalPrice.toFixed(2);
    discountPrice = discountPrice.toFixed(2);
    platformFees = platformFees.toFixed(2);
    stripeFees = stripeFees.toFixed(2);

    setTotalPrice(totalPrice);
    setFinalPrice(finalPrice);
    setDiscountPrice(discountPrice);
    setPlatformFees(platformFees);
    setStripeFees(stripeFees);
  }, [selectedStartingDates]);

  const handleDataFromChild = (data) => {
    if (!data) {
      return;
    }

    setSelectedStartingDates((prevStartingDates) => {
      const existingDateIndex = prevStartingDates.findIndex(
        (dateObj) => dateObj.storage_unit_ids === data.storage_unit_ids,
      );

      if (existingDateIndex === -1) {
        return [...prevStartingDates, data];
      } else {
        const updatedStartingDates = [...prevStartingDates];
        updatedStartingDates[existingDateIndex] = data;
        return updatedStartingDates;
      }
    });
  };

  useEffect(() => {
    dispatch(cartGetItems());
  }, [dispatch]);

  const handleUnselected = (ids) => {
    // Filter out undefined values from the ids array
    const filteredIds = ids.filter((id) => id !== undefined);
    setUnselected(filteredIds);
  };

  return (
    <div className="container py-3">
      <h1>Your Shopping Cart:</h1>

      <div className="row mt-3">
        <div
          className={`col-md-9 ${backgroundBlur && "checkout_backdrop_filter"} `}
        >
          {loading ? (
            <Spinner />
          ) : error ? (
            <>
              <div className="alert alert-info">No Items in the Cart.</div>
            </>
          ) : (
            <>
              <h5 className="border-bottom">Storage Unit Items</h5>
              <ul className="list-group list-group-flush">
                {cartItems && cartItems[0].storage_unit.length === 0 ? (
                  <>
                    <div className="alert alert-info">Your cart is Empty!</div>
                  </>
                ) : (
                  <>
                    {cartItems[0].storage_unit.map((item, index) => (
                      <CartList
                        key={item.id}
                        arrIndex={index}
                        item={item}
                        handleDataFromChild={handleDataFromChild}
                        unselected={unselected}
                        blockCheckoutArray={blockCheckoutArray}
                        setBlockCheckoutArray={setBlockCheckoutArray}
                      />
                    ))}
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between ">
                        <div>
                          <h4>Sub Total:</h4>
                        </div>
                        <div>
                          <h5>${finalPrice}</h5>
                        </div>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </>
          )}
        </div>
        <div tabIndex="99" className="col-md-3">
          <CheckoutCard
            subTotal={totalPrice}
            totalPrice={totalPrice}
            finalPrice={finalPrice}
            discountPrice={discountPrice}
            platformFees={platformFees}
            stripeFees={stripeFees}
            id={cartItems && cartItems}
            dateArray={selectedStartingDates}
            handleUnselected={handleUnselected}
            blockCheckout={blockCheckout}
            setBackgroundBlur={setBackgroundBlur}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
