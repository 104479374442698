import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Container from "../../shared-components/container/Container";
import { updateUserEmail, updateUserPassword } from "../../actions/userActions";
import {
  USER_EMAIL_UPDATE_RESET,
  USER_PASSWORD_UPDATE_RESET,
} from "../../constants/userConstants";
import activateStripe from "../../hooks/activateStripe";
import ServiceProviderDetailsUpdate from "../auth/serviceProviderDetails/ServiceProviderDetailsUpdate";

const MyProfile = () => {
  const { info } = useSelector((state) => state.userInfo);
  const { user } = useSelector((state) => state.userLogin);
  const { message, error } = useSelector((state) => state.userEmailUpdate);
  const { message: passwordMessage, error: passwordError } = useSelector(
    (state) => state.userPasswordUpdate,
  );
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);

  const [stripeLoading, setStripeLoading] = useState(false);
  const [activeSection, setActiveSection] = useState("personal"); // Default to 'personal'

  const [email, setEmail] = useState(info?.email || "");
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const dispatch = useDispatch();

  // Email Update Handler
  const emailUpdateHandler = (e) => {
    setEmail(e.target.value);
  };

  // Password Update Handler
  const passwordUpdateHandler = (e) => {
    setPasswordData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  // Email Update Submit Handler
  const emailUpdateSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUserEmail({ email }));
  };

  // Password Update Submit Handler
  const passwordUpdateSubmitHandler = (e) => {
    e.preventDefault();

    if (
      !passwordData.old_password ||
      !passwordData.password ||
      !passwordData.password2
    ) {
      return toast.error("Password fields must not be empty!");
    }

    if (/^\d+$/.test(passwordData.password)) {
      return toast.error("New password must not be all numeric.");
    }

    if (passwordData.password !== passwordData.password2) {
      return toast.error("Passwords do not match!");
    }

    dispatch(updateUserPassword(passwordData));
  };

  // Side Effects
  useEffect(() => {
    if (message) {
      toast.success(`Updated! ${message}`);
      dispatch({ type: USER_EMAIL_UPDATE_RESET });
    }

    if (error) {
      toast.error(error);
      dispatch({ type: USER_EMAIL_UPDATE_RESET });
    }

    if (passwordMessage) {
      toast.success(passwordMessage);
      dispatch({ type: USER_PASSWORD_UPDATE_RESET });
    }
    if (passwordError) {
      toast.error(passwordError);
      dispatch({ type: USER_PASSWORD_UPDATE_RESET });
    }
  }, [message, error, passwordMessage, passwordError]);

  async function handleActiveStripe() {
    setStripeLoading(true);
    const status = await activateStripe(user);
    setStripeLoading(status);
  }

  // Handlers for section buttons
  const showPersonal = () => setActiveSection("personal");
  const showSecurity = () => setActiveSection("security");
  const showProviderSettings = () => setActiveSection("providerSettings");

  return (
    <Container>
      <div>
        <h1>My Profile</h1>
        <h3>Personal Information</h3>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="col-3">
          <button
            type="button"
            className="btn mt-5 text-dark w-100"
            onClick={showPersonal}
          >
            Personal
          </button>
        </div>
        <div className="col-3">
          <button
            type="button"
            className="btn mt-5 text-dark w-100"
            onClick={showSecurity}
          >
            Security
          </button>
        </div>
        {serviceProvider ? (
          <div className="col-3">
            <button
              type="button"
              className="btn mt-5 text-dark w-100"
              onClick={showProviderSettings}
            >
              Provider Settings
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="mt-5"></div>
      {activeSection === "personal" && (
        <div className="card-body ">
          <div className="py-3 ">
            <h3>Personal Information</h3>
            <form onSubmit={emailUpdateSubmitHandler}>
              <div className="form-group mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={emailUpdateHandler}
                  className="form-control"
                  placeholder="email"
                  value={email}
                />
              </div>
              <button className="btn btn-primary text-dark">
                Update Email
              </button>
              {user?.service_provider && user?.is_provider_verified && (
                <div className="mt-2">
                  {/* <Link
                    to={`/service-provider-verification/update/${serviceProvider.id}`}
                    className="nav-link"
                  >
                    Want to Update Your Service Provider Information as well?
                  </Link> */}
                </div>
              )}
            </form>
            {user &&
              !user.is_stripe_account_active &&
              user.service_provider && (
                <div className="my-3">
                  <button
                    className="btn btn-primary text-dark"
                    onClick={handleActiveStripe}
                  >
                    Activate Stripe Account
                    {stripeLoading && (
                      <Spinner animation="border" variant="light" size="sm" />
                    )}
                  </button>
                </div>
              )}
          </div>
        </div>
      )}

      {activeSection === "security" && (
        <div className="card-body ">
          <div className="py-3 ">
            <h3>Security</h3>
            <form onSubmit={passwordUpdateSubmitHandler}>
              <div className="form-group mb-3">
                <label htmlFor="old_password">Old Password</label>
                <input
                  type="password"
                  name="old_password"
                  id="old_password"
                  onChange={passwordUpdateHandler}
                  className="form-control"
                  placeholder="Old Password"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password">New Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={passwordUpdateHandler}
                  className="form-control"
                  placeholder="New Password"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password2">Confirm New Password</label>
                <input
                  type="password"
                  name="password2"
                  id="password2"
                  onChange={passwordUpdateHandler}
                  className="form-control"
                  placeholder="Confirm New Password"
                />
              </div>
              <button className="btn btn-primary text-dark">
                Update Password
              </button>
            </form>
          </div>
        </div>
      )}

      {activeSection === "providerSettings" && (
        <div>
          <ServiceProviderDetailsUpdate />
        </div>
      )}
    </Container>
  );
};

export default MyProfile;
