import React, { useState } from "react";

const OrderStatusUpdate = ({ order, id, dispatch, updateOrderAdmin }) => {
  const [formData, setFormData] = useState({
    pending: order.pending,
    completed: order.completed,
  });
  const { pending, proccess, completed } = order;
  const handleSubmit = (e) => {
    e.preventDefault();
    sendNotificationToClient({
      business_type: "update",
      message: `Your Order with Order ID : ${
        formData ? formData?.order?.slug : "nj13n448255j13n"
      } has been Updated by the Service Provider!`,
    });
    dispatch(updateOrderAdmin(id, formData));
  };
  function sendNotificationToClient(order) {
    const notification = order;
    const existingNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    existingNotifications.push(notification);
    localStorage.setItem(
      "notifications",
      JSON.stringify(existingNotifications),
    );
  }

  return (
    <>
      <form>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={() =>
              setFormData((prev) => ({
                ...prev,
                pending: !prev.pending,
              }))
            }
            checked={formData.pending}
            id="pending"
            disabled={pending}
          />
          <label className="form-check-label" htmlFor="pending">
            Pending
          </label>
        </div>

        {order.pending && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={() =>
                setFormData((prev) => ({
                  ...prev,
                  completed: !prev.completed,
                }))
              }
              checked={formData.completed}
              id="pending"
              disabled={completed}
            />
            <label className="form-check-label" htmlFor="pending">
              Completed
            </label>
          </div>
        )}
        {order.completed ? (
          ""
        ) : (
          <button
            onClick={handleSubmit}
            className="btn btn-dark mt-3 text-dark"
          >
            Update
          </button>
        )}
      </form>
    </>
  );
};

export default OrderStatusUpdate;
