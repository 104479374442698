import React, { useState } from "react";
import Swal from "sweetalert2";

const ImageModal = ({ storageFacility }) => {
  const smallImages = storageFacility?.facility_images;

  const [currentImage, setCurrentImage] = useState(smallImages[0]?.image);
  const showModal = () => {
    const modalContent = document.createElement("div");

    const imageContainer = document.createElement("div");
    imageContainer.classList.add("image-container");

    const image = document.createElement("img");
    image.src = currentImage;
    image.classList.add("image");
    image.style.maxWidth = "350px";
    imageContainer.appendChild(image);

    modalContent.appendChild(imageContainer);

    const smallImagesContainer = document.createElement("div");
    smallImagesContainer.classList.add("small-images");

    smallImages.forEach((imageSrc, index) => {
      const smallImage = document.createElement("img");
      smallImage.src = imageSrc.image;
      smallImage.style.width = "100px";
      smallImage.style.marginTop = "30px";
      smallImage.style.marginLeft = "10px";
      smallImage.style.borderRadius = "20px";
      smallImage.classList.add("small-image");

      smallImage.addEventListener("click", () => {
        setCurrentImage(imageSrc.image);
      });

      smallImagesContainer.appendChild(smallImage);
    });

    modalContent.appendChild(smallImagesContainer);

    Swal.fire({
      title: "Images",
      html: modalContent,
      customClass: {
        content: "custom-swal-content",
        confirmButton: "custom-confirm-button",
      },
      confirmButtonColor: "#8704f5",
    });
  };

  return (
    <div>
      <span
        className="cursor-pointer text-center"
        onClick={showModal}
        style={{ cursor: "pointer" }}
      >
        Photos
      </span>
    </div>
  );
};

export default ImageModal;
