import React, { useEffect, useState } from "react";
import OrdersRating from "./OrdersRating";

const OrderDetailsUserCard = ({ orderItem, id }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [showRating, setShowRating] = useState(false);

  console.log(orderItem, "orderItem");
  useEffect(() => {
    if (orderItem?.order) {
      const calculatedTotalPrice = orderItem.order.reduce((total, item) => {
        return total + item.unit_order.per_unit_price;
      }, 0);
      setTotalPrice(calculatedTotalPrice);
    }
  }, [orderItem]);

  return (
    <>
      <div className="d-flex flex-column  justify-content-between border-bottom my-3">
        <div key={orderItem.id} className="col-12  ">
          <div className="d-flex flex-column flex-md-row justify-content-between  align-items-center mb-5">
            <div className="col-12 col-md-3 my-1">
              <img
                src="/images/storage5.jpg"
                className="img-fluid rounded "
                alt="indoor"
              />
            </div>
            <div className="col-12 col-md-8 my-1 d-flex flex-column">
              <div className="d-flex justify-content-between  ">
                <div className="col-10">
                  <h5>{orderItem.unit_order.name.toUpperCase()}</h5>
                  <p>
                    {orderItem.unit_order.description}
                    This is how I would write it, simply because it's more
                    obvious what the query is doing. On the other hand, yours
                    seems easier for the parser to optimize.
                  </p>
                </div>
                <div className="col-2 d-flex align-items-start justify-content-center">
                  <h5 className="fw-bold">${orderItem?.amount}</h5>
                </div>
              </div>
              <div className="col-10 d-flex flex-column flex-lg-row justify-content-between ">
                <div className="d-flex flex-column ">
                  <div className="">
                    <span className="fw-bold">Starting Date: </span>
                    <span>{orderItem.starting_date}</span>
                  </div>
                  <div className="">
                    <span className="fw-bold">Ending Date: </span>
                    <span>{orderItem.ending_date}</span>
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <div className="">
                    <span className="fw-bold">Payment Completed: </span>
                    <span
                      className={
                        orderItem.pending ? "text-success" : "text-danger"
                      }
                    >
                      {orderItem.pending ? "✅" : "❌"}
                    </span>
                  </div>
                  <div className="">
                    <span className="fw-bold">Order Completed: </span>
                    <span
                      className={
                        orderItem.completed ? "text-success" : "text-danger"
                      }
                    >
                      {orderItem.completed ? "✅" : "❌"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="pt-4">
                {!orderItem.feedback && orderItem.completed && (
                  <div className="col-md-10 col-12">
                    {showRating ? (
                      <OrdersRating
                        id={orderItem.unit_order.storage_facility.id}
                        id_update={orderItem.id}
                        idDetails={id}
                      />
                    ) : (
                      <button
                        className="btn text-dark"
                        onClick={() => setShowRating(true)}
                      >
                        Give Rating
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetailsUserCard;
