import React, { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import ImageModal from "../../../shared-components/PhotoGallery/PhotoModal";
import { useRef } from "react";
const Features = ({ product, storageFacility }) => {
  const allFeatures = storageFacility?.features;
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const maxFeaturesToShow = showAllFeatures ? allFeatures.length : 5;
  const featuresToShow = allFeatures.slice(0, maxFeaturesToShow);
  const imageModalRef = useRef(null);

  const showImageModal = () => {
    if (imageModalRef.current) {
      imageModalRef.current.showModal();
    }
  };

  return (
    <div>
      <ul className="list-group box-shadow mb-5 mb-md-0">
        <li className="list-group-item p-3">
          <h5>Features Available at this Facility:</h5>
          <div className="mt-2">
            {featuresToShow.map((feature, index) => {
              return (
                <p key={index}>
                  <BsFillCheckCircleFill
                    size={20}
                    className="text-success pr-2"
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  {feature.features_text}
                </p>
              );
            })}
            {allFeatures.length > 5 && !showAllFeatures && (
              <p
                className="text-primary cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => setShowAllFeatures(true)}
              >
                Show More Features
              </p>
            )}
          </div>
        </li>
        <li className="list-group-item p-3">
          <h5>Hours</h5>
          <p>{storageFacility?.working_hours}</p>
          <h5>Storage Access Hours</h5>
          <p>{storageFacility?.access_hours}</p>
          <h5>Address</h5>
          <p>{storageFacility.address}</p>

          <div style={{ textAlign: "center" }}>
            <img
              src={
                storageFacility?.facility_images.length >= 2
                  ? storageFacility?.facility_images[1].image
                  : "/images/small.png"
              }
              className="img-fluid rounded"
              alt="lsdfj"
            />
            <ImageModal ref={imageModalRef} storageFacility={storageFacility} />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Features;
