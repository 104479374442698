import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { LuWarehouse } from "react-icons/lu";

import { getAllStorageUnits } from "../../actions/storageUnitActions";
import Spinner from "../../shared-components/Spinner/Spinner";
import UnitStorageStatusTable from "../ServiceProviderDashboard/ServiceProviderDashboardComponents/UnitStorageStatusTable";

const StorageFacilities = () => {
  const { loading, storageUnits } = useSelector(
    (state) => state.getAllStorageUnits,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const itemsPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Dispatch the action inside the useEffect when currentPage changes
    dispatch(getAllStorageUnits(currentPage));
  }, [currentPage, dispatch]);

  useEffect(() => {
    dispatch(getAllStorageUnits()); // Initial load
  }, [dispatch]);

  return (
    <>
      <div className="align-items-center d-none d-lg-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div className="p-2 bg-orange text-dark rounded me-2">
            <LuWarehouse size={24} />
          </div>
          <h5 className="text-uppercase">Storage Facilities</h5>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : storageUnits?.results?.length === 0 ? (
        <div>
          <div className="mt-5 text-center">
            <LuWarehouse size={70} />
            <h1 className="mt-2">No Facility Storage Found</h1>
            <p className="mt-2">
              It looks like there are no facility stores available yet. Click
              here to create a new one.
            </p>
            <Link
              to="/service-provider-dashboard/addUnit"
              className="btn btn-dark text-dark"
            >
              <LuWarehouse
                className="pr-1"
                style={{
                  marginRight: "10px",
                }}
              />
              ADD NEW FACILITY
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-end">
            <Link
              to="/service-provider-dashboard/addUnit"
              className="btn btn-dark text-dark"
            >
              <LuWarehouse
                className="pr-1"
                style={{
                  marginRight: "10px",
                }}
              />
              ADD NEW FACILITY
            </Link>
          </div>
          <div className="card mt-4">
            <div className="card-body col-12">
              {loading ? (
                <Spinner />
              ) : (
                <UnitStorageStatusTable data={storageUnits?.results} />
              )}
            </div>
            <div className="text-center m-auto">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={storageUnits?.count}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StorageFacilities;
