import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import Pagination from "react-js-pagination"; // Import the Pagination component

const itemsPerPage = 10;

const StorageUnitPagination = ({ storageUnits, onPageChange }) => {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const lastPart = parts[parts.length - 1];
  const [selectedFilters, setSelectedFilters] = useState({
    is_available: false,
    is_occupied: false,
    ordering: "-created_at",
    page: 1,
  });

  const [pageNumber, setPageNumber] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    // If a filter is applied, set the current page to 1
    if (selectedFilters.is_available || selectedFilters.is_occupied) {
      setSelectedFilters({
        ...selectedFilters,
        page: 1,
      });
      setCurrentPage(1); // Set the current page to 1
      onPageChange(1); // Call onPageChange with page 1
    } else {
      // If no filter is applied, set the current page to the selected page
      setSelectedFilters({
        ...selectedFilters,
        page: pageNumber,
      });
      setCurrentPage(pageNumber); // Set the current page to the selected page
      onPageChange(pageNumber);
    }
  };

  const itemCount = storageUnits?.count || 0;
  const pageCount = Math.ceil(itemCount / itemsPerPage);
  // let currentPage = pageNumber;
  // currentPage = Math.min(currentPage, pageCount);

  return (
    <div>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={itemCount}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        innerClass="pagination justify-content-center"
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  );
};

export default StorageUnitPagination;
