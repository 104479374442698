import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import {
  deleteOrder,
  deleteOrderAdmin,
  getAllOrders,
} from "../../../actions/ordersActions";

const HomeOrdersTableUser = ({ orders }) => {
  const { info } = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrderAdmin(id));
    dispatch(getAllOrders(FormData));
    navigate("/storage-provider-dashboard");
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-hover mt-2">
          <thead className="text-center">
            <tr>
              <th scope="col">ID</th>
              {/* <th scope="col">E-Mail</th> */}

              <th scope="col">Business Type</th>
              <th scope="col">PRICE</th>
              {/* <th scope="col"> Area</th> */}

              {/* <th scope="col">PAID AT</th>  */}

              {/* {info && info.is_service_provider && <th scope="col"></th>} */}
            </tr>
          </thead>
          <tbody className="text-center">
            {orders &&
              orders?.map((order) => (
                <tr key={order?.id}>
                  <td className="p-3">{order?.slug}</td>
                  {/* <td className="p-3">{order.user?.email}</td> */}
                  <td className="p-3">{order.business_type}</td>

                  <td className="p-3">${order.total_price}</td>
                  {/* {info && info.is_service_provider ? ( */}

                  <td>
                    <button
                      className="btn btn-dark text-dark"
                      onClick={() => navigate(`/orders/${order.id}`)}
                    >
                      Details
                    </button>
                  </td>

                  {!order.payment_completed ? (
                    <td>
                      <button
                        className="btn text-dark"
                        onClick={() =>
                          window.open(order.payment_link, "_blank")
                        }
                      >
                        Payment Due
                      </button>
                    </td>
                  ) : (
                    <td></td>
                  )}

                  {/* {info && info.is_service_provider && (
                  <td className="p-3">
                    <button
                      className="btn btn-dark"
                      onClick={() => deleteOrderHandler(order.id)}
                    >
                      Delete
                    </button>
                  </td>
                )} */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HomeOrdersTableUser;
