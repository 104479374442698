import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import OrderStatusUpdate from "../OrderStatusUpdate/OrderStatusUpdate";
import { updateOrderAdmin } from "../../../actions/ordersActions";
import PayoutModal from "../../Home/HomeOrdersTableServiceProvider/PayoutModal";

const OrderDetailsServicePovider = ({ order }) => {
  console.log(order, "orders2222222");
  const dispatch = useDispatch();
  const { id } = useParams();

  const [showModal, setShowModal] = useState(false);

  function handlePayout(flag1, flag2) {
    // console.log(order);
    console.log(flag1, " ", flag2);
    setShowModal(false);
  }

  return (
    <>
      <div
        className="d-flex justify-content-between flex-column flex-md-row"
        style={{
          gap: "40px",
        }}
      >
        <div className="col-md-8 col-12">
          <div className="">
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <h5>Facility Information</h5>
                </li>

                <li className="list-group-item">
                  <h6>Facilty Details</h6>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Provider Name</p>
                  <p>{order?.unit_order?.storage_facility?.name}</p>
                </li>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span className="fw-bold me-2">Email</span>
                    <span>
                      {
                        order?.unit_order?.storage_facility.storage_provider
                          .email
                      }
                    </span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className=" d-flex justify-content-between">
                    <span className="fw-bold me-2">Phone Number</span>
                    <span>
                      {
                        order?.unit_order?.storage_facility.storage_provider
                          .phone_number
                      }
                    </span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className=" d-flex justify-content-between">
                    <span className="fw-bold me-2">Fax Number</span>
                    <span>
                      {
                        order?.unit_order?.storage_facility.storage_provider
                          .fax_number
                      }
                    </span>
                  </div>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Website</p>
                  <p>
                    {
                      order?.unit_order?.storage_facility.storage_provider
                        .website
                    }
                  </p>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Working Hours</p>
                  <p>{order?.unit_order?.storage_facility.working_hours}</p>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Location</p>
                  <p
                    style={{
                      maxWidth: "250px",
                    }}
                  >
                    {order?.unit_order?.storage_facility.address}
                  </p>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Total Size </p>
                  <p>{order?.unit_order?.storage_facility.total_size_field}</p>
                </li>
              </ul>
              {/* <div>
                <button className="btn text-dark" onClick={() => setShowModal(true)}>
                  Get Payout
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="card">
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <h5>Order Summary</h5>
                </li>

                <li className="list-group-item">
                  <h6>Storage Unit Details</h6>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Storage Unit ID</p>
                  <p>{order.unit_order.id}</p>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Number</p>
                  <p>{order?.unit_order?.name}</p>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Storage Type</p>
                  {(() => {
                    switch (order.unit_order.storage_unit_type) {
                      case "indoor_storage":
                        return <p>INDOOR STORAGE</p>;

                      case "outdoor_storage":
                        return <p>OUTDOOR STORAGE</p>;

                      case "climate_control":
                        return <p>CLIMATE CONTROL</p>;
                      default:
                        return null; // or some default content
                    }
                  })()}
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Price</p>
                  <p className="fw-bold">${order?.order?.total_price}</p>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <p className="fw-bold">Area</p>
                  <p>{order?.unit_order?.size_field}</p>
                </li>
              </ul>

              <div>
                <h5>Update Order Details</h5>
                <OrderStatusUpdate
                  id={id}
                  updateOrderAdmin={updateOrderAdmin}
                  dispatch={dispatch}
                  order={order}
                />
              </div>
            </div>
          </div>
        </div>
        <PayoutModal
          showModal={showModal}
          setShowModal={setShowModal}
          handlePayout={handlePayout}
        />
      </div>
    </>
  );
};
export default OrderDetailsServicePovider;
