import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { logoutUser } from "../../actions/userActions";
import { signAgreement } from "../../actions/userActions";
import TermsAndConditonText from "./TermsAndConditonText";

export const TermsAndConditions = () => {
  const dispatch = useDispatch();
  //   const { user } = useSelector((state) => state.userLogin);
  const user = JSON.parse(localStorage.getItem("user"));

  const [show, setShow] = useState(false);
  const [acceptCheckbox, setAgreeCheckbox] = useState(false);

  useEffect(() => {
    if (user) {
      if (!user.is_agreement_signed) {
        setShow(true);
      }
      if (user.is_agreement_signed) {
        setShow(false);
      }
    }
  }, [user]);

  function handleAgree() {
    dispatch(signAgreement());
    setAgreeCheckbox(false);
  }

  // function handleDecline() {
  //   dispatch(logoutUser());
  //   setShow(false);
  // }

  return (
    <>
      <Modal show={show} backdrop="static" size="lg" keyboard={false}>
        <Modal.Header className="ms-4 py-4">
          <Modal.Title>FindxStorage Terms And Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditonText
            acceptCheckbox={acceptCheckbox}
            setAgreeCheckbox={setAgreeCheckbox}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          {/* <Button className="mx-3 px-5 decline-btn" onClick={handleDecline}>
            Decline
          </Button> */}
          <Button
            className="mx-3 px-5 agree-btn"
            disabled={!acceptCheckbox}
            onClick={handleAgree}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
