import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../shared-components/Spinner/Spinner";

const ProtectedRoute = ({ serviceProvider, renter }) => {
  const { user, isLoggedIn, checkingStatus } = useAuth();

  if (checkingStatus) {
    return <Spinner />;
  }

  // remove these after its backend is made
  if (user) {
    if (user.is_agreement_signed == null) {
      user.is_agreement_signed = false;
    }
  }

  if (user) {
    if (!user.is_agreement_signed) {
      return <div></div>;
    }
  }

  if (
    (serviceProvider && user?.service_provider) || // only let you access the selected routes if you are a service provider
    (renter && !user?.service_provider) // only let you access the renter routes
  ) {
    return <Outlet />;
  } else if (user) {
    return <Navigate to="/not-found" />;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
