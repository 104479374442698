export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAIL = "PLACE_ORDER_FAIL";
export const PLACE_ORDER_RESET = "PLACE_ORDER_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

export const ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST";
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS";
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

export const ALL_ORDERS_ADMIN_REQUEST = "ALL_ORDERS_ADMIN_REQUEST";
export const ALL_ORDERS_ADMIN_SUCCESS = "ALL_ORDERS_ADMIN_SUCCESS";
export const ALL_ORDERS_ADMIN_FAIL = "ALL_ORDERS_ADMIN_FAIL";

export const ORDER_DETAILS_ADMIN_REQUEST = "ORDER_DETAILS_ADMIN_REQUEST";
export const ORDER_DETAILS_ADMIN_SUCCESS = "ORDER_DETAILS_ADMIN_SUCCESS";
export const ORDER_DETAILS_ADMIN_FAIL = "ORDER_DETAILS_ADMIN_FAIL";
export const ORDER_DETAILS_ADMIN_RESET = "ORDER_DETAILS_ADMIN_RESET";

export const UPDATE_ORDERS_ADMIN_REQUEST = "UPDATE_ORDERS_ADMIN_REQUEST";
export const UPDATE_ORDERS_ADMIN_SUCCESS = "UPDATE_ORDERS_ADMIN_SUCCESS";
export const UPDATE_ORDERS_ADMIN_FAIL = "UPDATE_ORDERS_ADMIN_FAIL";
export const UPDATE_ORDERS_ADMIN_RESET = "UPDATE_ORDERS_ADMIN_RESET";

export const DELETE_ORDERS_ADMIN_REQUEST = "DELETE_ORDERS_ADMIN_REQUEST";
export const DELETE_ORDERS_ADMIN_SUCCESS = "DELETE_ORDERS_ADMIN_SUCCESS";
export const DELETE_ORDERS_ADMIN_FAIL = "DELETE_ORDERS_ADMIN_FAIL";

export const CURRENT_ORDERS_ADMIN_REQUEST = "CURRENT_ORDERS_ADMIN_REQUEST";
export const CURRENT_ORDERS_ADMIN_SUCCESS = "CURRENT_ORDERS_ADMIN_SUCCESS";
export const CURRENT_ORDERS_ADMIN_FAIL = "CURRENT_ORDERS_ADMIN_FAIL";

export const COMPLETED_ORDERS_ADMIN_REQUEST = "COMPLETED_ORDERS_ADMIN_REQUEST";
export const COMPLETED_ORDERS_ADMIN_SUCCESS = "COMPLETED_ORDERS_ADMIN_SUCCESS";
export const COMPLETED_ORDERS_ADMIN_FAIL = "COMPLETED_ORDERS_ADMIN_FAIL";

export const CREATE_RATING_REQUEST = "CREATE_RATING_REQUEST";
export const CREATE_RATING_SUCCESS = "CREATE_RATING_SUCCESS";
export const CREATE_RATING_FAIL = "CREATE_RATING_FAIL";

export const UPDATE_RATING_REQUEST = "UPDATE_RATING_REQUEST";
export const UPDATE_RATING_SUCCESS = "UPDATE_RATING_SUCCESS";
export const UPDATE_RATING_FAIL = "UPDATE_RATING_FAIL";
