export const GET_ALL_STORAGE_UNITS_REQUEST = "GET_ALL_STORAGE_UNITS_REQUEST";
export const GET_ALL_STORAGE_UNITS_SUCCESS = "GET_ALL_STORAGE_UNITS_SUCCESS";
export const GET_ALL_STORAGE_UNITS_FAIL = "GET_ALL_STORAGE_UNITS_FAIL";

export const GET_SINGLE_STORAGE_UNITS_REQUEST =
  "GET_SINGLE_STORAGE_UNITS_REQUEST";
export const GET_SINGLE_STORAGE_UNITS_SUCCESS =
  "GET_SINGLE_STORAGE_UNITS_SUCCESS";
export const GET_SINGLE_STORAGE_UNITS_FAIL = "GET_SINGLE_STORAGE_UNITS_FAIL";

export const CREATE_STORAGE_UNIT_REQUEST = "CREATE_STORAGE_UNIT_REQUEST";
export const CREATE_STORAGE_UNIT_SUCCESS = "CREATE_STORAGE_UNIT_SUCCESS";
export const CREATE_STORAGE_UNIT_FAIL = "CREATE_STORAGE_UNIT_FAIL";
export const CREATE_STORAGE_UNIT_CLEAR = "CREATE_STORAGE_UNIT_CLEAR";

export const UPDATE_STORAGE_UNIT_REQUEST = "UPDATE_STORAGE_UNIT_REQUEST";
export const UPDATE_STORAGE_UNIT_SUCCESS = "UPDATE_STORAGE_UNIT_SUCCESS";
export const UPDATE_STORAGE_UNIT_FAIL = "UPDATE_STORAGE_UNIT_FAIL";
export const UPDATE_STORAGE_UNIT_CLEAR = "UPDATE_STORAGE_UNIT_CLEAR";

export const DELETE_STORAGE_UNIT_REQUEST = "DELETE_STORAGE_UNIT_REQUEST";
export const DELETE_STORAGE_UNIT_SUCCESS = "DELETE_STORAGE_UNIT_SUCCESS";
export const DELETE_STORAGE_UNIT_FAIL = "DELETE_STORAGE_UNIT_FAIL";

export const GET_TOTAL_STORAGE_UNITS_REQUEST =
  "GET_TOTAL_STORAGE_UNITS_REQUEST";
export const GET_TOTAL_STORAGE_UNITS_SUCCESS =
  "GET_TOTAL_STORAGE_UNITS_SUCCESS";
export const GET_TOTAL_STORAGE_UNITS_FAIL = "GET_TOTAL_STORAGE_UNITS_FAIL";

export const GET_AVAILABLE_STORAGE_UNITS_REQUEST =
  "GET_AVAILABLE_STORAGE_UNITS_REQUEST";
export const GET_AVAILABLE_STORAGE_UNITS_SUCCESS =
  "GET_AVAILABLE_STORAGE_UNITS_SUCCESS";
export const GET_AVAILABLE_STORAGE_UNITS_FAIL =
  "GET_AVAILABLE_STORAGE_UNITS_FAIL";

export const GET_OCCUPIED_STORAGE_UNITS_REQUEST =
  "GET_OCCUPIED_STORAGE_UNITS_REQUEST";
export const GET_OCCUPIED_STORAGE_UNITS_SUCCESS =
  "GET_OCCUPIED_STORAGE_UNITS_SUCCESS";
export const GET_OCCUPIED_STORAGE_UNITS_FAIL =
  "GET_OCCUPIED_STORAGE_UNITS_FAIL";

export const DELETE_FEATURE_REQUEST = "DELETE_FEATURE_REQUEST";
export const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS";
export const DELETE_FEATURE_FAIL = "DELETE_FEATURE_FAIL";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL";

export const UPDATE_FEATURE_REQUEST = "UPDATE_FEATURE_REQUEST";
export const UPDATE_FEATURE_SUCCESS = "UPDATE_FEATURE_SUCCESS";
export const UPDATE_FEATURE_FAIL = "UPDATE_FEATURE_FAIL";

export const ADD_NEW_FEATURE_REQUEST = "ADD_NEW_FEATURE_REQUEST";
export const ADD_NEW_FEATURE_SUCCESS = "ADD_NEW_FEATURE_SUCCESS";
export const ADD_NEW_FEATURE_FAIL = "ADD_NEW_FEATURE_FAIL";

export const UPDATE_IMAGE_REQUEST = "UPDATE_IMAGE_REQUEST";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS";
export const UPDATE_IMAGE_FAIL = "UPDATE_IMAGE_FAIL";

export const GET_DISTINCTIVE_STORAGE_UNITS_REQUEST =
  "GET_DISTINCTIVE_STORAGE_UNITS_REQUEST";
export const GET_DISTINCTIVE_STORAGE_UNITS_SUCCESS =
  "GET_DISTINCTIVE_STORAGE_UNITS_SUCCESS";
export const GET_DISTINCTIVE_STORAGE_UNITS_FAIL =
  "GET_DISTINCTIVE_STORAGE_UNITS_FAIL";
