import React from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { FaWarehouse } from "react-icons/fa";
import ServiceProviderDashboardSidebar from "../ServiceProviderDashboardSidebar/ServiceProviderDashboardSidebar";
import { LuWarehouse } from "react-icons/lu";

const ServiceProviderDashboardOffcanvas = () => {
  return (
    <div className="d-inline-flex p-2">
      {/* <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        Button with data-bs-target
      </button> */}
      <div className="d-inline-flex p-2 justify-content-between align-items-center d-block d-lg-none">
        <div
          className="p-1 p-md-3 bg-orange text-dark rounded pointer "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <RxHamburgerMenu size={30} />
        </div>
        {/* <h5 className="text-muted text-uppercase small small-md-normal">Dashboard</h5>
        <div>
          <Link
            to="/service-provider-dashboard/addUnit"
            className="btn text-dark btn-dark small small-md-normal d-flex align-items-center justify-contet-between"
          >
            <LuWarehouse
              className="pr-1"
              style={{
                marginRight: "10px",
              }}
            />
            ADD NEW FACILITY;(
          </Link>
        </div> */}
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div
          className="offcanvas-header"
          style={{ backgroundColor: "#000034" }}
        >
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            <img src="/images/FindxStorage3.png" height={40} />
            {/* <span className="mineTextOrange">
              <GiOrange />
              FindxStorage
            </span> */}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "white" }}
          ></button>
        </div>
        <div className="offcanvas-body">
          <ServiceProviderDashboardSidebar />
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderDashboardOffcanvas;
