import Navbar from "../shared-components/Navbar/Navbar";
import Footer from "../shared-components/footer/Footer";
import CookiesPupup from "../shared-components/CookiesPopup/CookiesPupup";
import { TermsAndConditions } from "../shared-components/TermsAndConditions/TermsAndConditions";
const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        {children}
      </div>
      <TermsAndConditions />
      <CookiesPupup />
      <Footer />
    </div>
  );
};

export default Layout;
