import React from "react";

const Rating = ({ value, text, color, fontSize }) => {
  return (
    <div className="rating">
      <span>
        <i
          style={{ color, fontSize }}
          className={
            value >= 1
              ? "fas fa-star"
              : value >= 0.5
                ? "fas fa-star-half-alt"
                : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color, fontSize }}
          className={
            value >= 2
              ? "fas fa-star"
              : value >= 1.5
                ? "fas fa-star-half-alt"
                : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color, fontSize }}
          className={
            value >= 3
              ? "fas fa-star"
              : value >= 2.5
                ? "fas fa-star-half-alt"
                : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color, fontSize }}
          className={
            value >= 4
              ? "fas fa-star"
              : value >= 3.5
                ? "fas fa-star-half-alt"
                : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color, fontSize }}
          className={
            value >= 5
              ? "fas fa-star"
              : value >= 4.5
                ? "fas fa-star-half-alt"
                : "far fa-star"
          }
        ></i>
      </span>
      {text !== null ? (
        <span className="mx-3">{text}</span>
      ) : (
        <span className="mx-3">0 </span>
      )}
    </div>
  );
};

Rating.defaultProps = {
  color: "#f8e825",
};

export default Rating;
