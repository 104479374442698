import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";

import {
  deleteOrder,
  deleteOrderAdmin,
  getAllOrders,
} from "../../../actions/ordersActions";
import { requestPayout } from "../../../actions/ordersActions";
import PayoutModal from "./PayoutModal";

const HomeOrdersTableServiceProvider = ({ orders }) => {
  const { info } = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrderAdmin(id));
    dispatch(getAllOrders());
    navigate("/storage-provider-dashboard");
  };

  const [showModal, setShowModal] = useState(false);
  const [orderID, setOrderID] = useState(null);

  const modalOpener = (id) => {
    setOrderID(id);
    setShowModal(true);
  };
  const handlePayout = (flag1, flag2) => {
    console.log(flag1, " ", flag2, " ", orderID);
    dispatch(requestPayout(flag1, flag2, orderID));
    setShowModal(false);
  };

  return (
    <>
      {orders?.count > 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-hover mt-2">
            <thead className="text-center">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">STORAGE UNIT</th>
                {/* <th scope="col">PENDING</th> */}
                <th scope="col">IN PROGRESS</th>

                <th scope="col">COMPLETED</th>
                <th scope="col">PRICE</th>
                <th scope="col">SLUG</th>
                <th scope="col">STARTING DATE</th>
                <th scope="col">ENDING DATE</th>

                <th scope="col"></th>
                {/* <th scope="col">PAID AT</th>  */}

                {/* {info && info.is_service_provider && <th scope="col"></th>} */}
              </tr>
            </thead>
            <tbody className="text-center">
              {orders &&
                orders.results.map((order) => (
                  <tr key={order?.id}>
                    <td className="p-3">{order?.id}</td>
                    <td className="p-3">{order?.unit_order?.name}</td>
                    <td className="p-3">
                      {order?.is_user_accepted_request ? (
                        <span className="text-success">
                          {" "}
                          Payout In The Process
                        </span>
                      ) : order?.pending ? (
                        <button
                          className="btn btn-dark text-dark"
                          onClick={() => modalOpener(order.id)}
                        >
                          Get Payout
                        </button>
                      ) : (
                        <RxCross1 className="text-danger" />
                      )}
                    </td>
                    <td className="p-3">
                      {order?.completed ? (
                        <BsCheck2 className="text-success" />
                      ) : (
                        <RxCross1 className="text-danger" />
                      )}
                    </td>
                    <td className="p-3">${order?.order?.total_price}</td>
                    <td className="p-3">{order?.order?.slug}</td>
                    <td className="p-3">{order?.starting_date}</td>
                    <td className="p-3">{order?.ending_date}</td>
                    {/* {info && info.is_service_provider ? ( */}
                    <td>
                      <button
                        className="btn btn-dark text-dark"
                        onClick={() => navigate(`/orders/${order.id}`)}
                      >
                        Details
                      </button>
                    </td>
                    {/* ) : (
                  <td></td>
                )} */}

                    {/* {info && info.is_service_provider && (
                  <td className="p-3">
                    <button
                      className="btn btn-dark"
                      onClick={() => deleteOrderHandler(order.id)}
                    >
                      Delete
                    </button>
                  </td>
                )} */}
                  </tr>
                ))}
            </tbody>
          </table>
          <PayoutModal
            showModal={showModal}
            setShowModal={setShowModal}
            handlePayout={handlePayout}
            orderID={orderID}
          />
        </div>
      ) : (
        <p className="fw-bold m-5">No Orders Present</p>
      )}
    </>
  );
};

export default HomeOrdersTableServiceProvider;
