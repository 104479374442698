import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaWarehouse } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import AddRoomForm from "../ServiceProviderDashboardComponents/AddNewRoomPageComponents/AddRoomForm";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { CREATE_ROOMS_CLEAR } from "../../../constants/roomsConstants";
import { API_URL } from "../../../actions/userActions";
import { LuWarehouse } from "react-icons/lu";

const AddNewRoomPage = () => {
  const { user } = useSelector((state) => state.userLogin);
  const accessToken = user.access;
  const { id } = useParams();
  const navigate = useNavigate();

  const { loading, rooms, error } = useSelector((state) => state.createRooms);
  const dispatch = useDispatch();

  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    if (rooms) {
      toast.success("Created successfully!");
      navigate(`/service-provider-dashboard/storagefacilities/${id}`);
      dispatch({ type: CREATE_ROOMS_CLEAR });
    }

    if (error) {
      toast.error("Something went wrong.");
      // dispatch({ type: CREATE_ROOMS_CLEAR });
    }
  }, [rooms, error]);

  useEffect(() => {
    axios
      .get(`${API_URL}/promotions/get_pormotions/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setPromotions(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessToken]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-3">
          <div className="d-flex align-items-center">
            <div className="p-2 bg-orange text-dark rounded me-2">
              <LuWarehouse size={20} />
            </div>
            <h5 className="text-uppercase">Add new Storage Unit</h5>
          </div>
          <div className="mt-4">
            <div className="card">
              <div className="card-body">
                <h3 className="text-uppercase">create New Storage Unit</h3>
                <AddRoomForm id={id} promotions={promotions} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewRoomPage;
