import { API_URL } from "../actions/userActions";
import axios from "axios";

const activateStripe = async (user) => {
  const accessToken = user.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(`${API_URL}/auth/stripe_account/`, config);
    window.open(data.stripe_data.url, "_blank");
    return false;
  } catch (error) {
    console.error(error);
    return true;
  }
};

export default activateStripe;
