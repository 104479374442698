import React from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineAnalytics } from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci";
import { CiReceipt } from "react-icons/ci";
import { CiSettings } from "react-icons/ci";
import { LiaWarehouseSolid } from "react-icons/lia";
import { LuWarehouse } from "react-icons/lu";

const ServiceProviderDashboardSidebar = () => {
  return (
    <div className="h-100 bg-light p-3 fullHeight">
      <div className="mb-2">
        <h5 className="text-muted text-uppercase">Dashboard</h5>
        <NavLink
          to="/service-provider-dashboard/analytics"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex nav-link p-3 rounded align-items-center justify-content-between"
              : isActive
                ? "d-flex text-dark nav-link bg-orange p-3 rounded align-items-center justify-content-between"
                : "d-flex nav-link p-3 rounded align-items-center justify-content-between"
          }
        >
          <MdOutlineAnalytics size={30} />
          <h6 className="m-0">Dashboard</h6>
        </NavLink>
      </div>

      <div className="mb-2">
        <h5 className="text-muted text-uppercase">Pages</h5>
        <NavLink
          to="/service-provider-dashboard/storagefacilities"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex nav-link p-3 rounded align-items-center justify-content-between"
              : isActive
                ? "d-flex text-dark nav-link bg-orange p-3 rounded align-items-center justify-content-between"
                : "d-flex nav-link p-3 rounded align-items-center justify-content-between"
          }
        >
          <LuWarehouse size={25} className="ms-1" />
          <h6 className="m-0">Storage Facility</h6>
        </NavLink>

        <NavLink
          to="/service-provider-dashboard/storageUnits"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex nav-link p-3 rounded align-items-center justify-content-between"
              : isActive
                ? "d-flex text-dark nav-link bg-orange p-3 rounded align-items-center justify-content-between"
                : "d-flex nav-link p-3 rounded align-items-center justify-content-between"
          }
        >
          <LiaWarehouseSolid size={30} />
          <h6 className="m-0">Storage Units</h6>
        </NavLink>

        <NavLink
          to="/service-provider-dashboard/orders"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex nav-link p-3 rounded align-items-center justify-content-between"
              : isActive
                ? "d-flex text-dark nav-link bg-orange p-3 rounded align-items-center justify-content-between"
                : "d-flex nav-link p-3 rounded align-items-center justify-content-between"
          }
        >
          <CiReceipt size={30} />
          <h6 className="m-0">Orders</h6>
        </NavLink>

        {/* <NavLink
          to="/service-provider-dashboard/customers"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex nav-link p-3 rounded align-items-center justify-content-between"
              : isActive
              ? "d-flex text-light nav-link bg-orange p-3 rounded align-items-center justify-content-between"
              : "d-flex nav-link p-3 rounded align-items-center justify-content-between"
          }
          style={{
            gap: "20px",
          }}
        >
          <TbUsers size={30} />
          <h6 className="m-0">Customers</h6>
        </NavLink> */}

        <NavLink
          to="/service-provider-dashboard/promotions"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex nav-link p-3 rounded align-items-center justify-content-between"
              : isActive
                ? "d-flex text-dark nav-link bg-orange p-3 rounded align-items-center justify-content-between"
                : "d-flex nav-link p-3 rounded align-items-center justify-content-between"
          }
        >
          <CiDiscount1 size={30} />
          <h6 className="m-0">Promotions</h6>
        </NavLink>

        <NavLink
          to="/service-provider-dashboard/myprofile"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex nav-link p-3 rounded align-items-center justify-content-between"
              : isActive
                ? "d-flex text-dark nav-link bg-orange p-3 rounded align-items-center justify-content-between"
                : "d-flex nav-link p-3 rounded align-items-center justify-content-between"
          }
        >
          <CiSettings size={30} />
          <h6 className="m-0">Settings</h6>
        </NavLink>
      </div>
    </div>
  );
};

export default ServiceProviderDashboardSidebar;
