import {
  UPDATE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  NOTIFICATION_STATUS_FAIL,
  NOTIFICATION_STATUS_REQUEST,
  NOTIFICATION_STATUS_SUCCESS,
} from "../constants/notificationConstants";
import { API_URL } from "./userActions";
import axios from "axios";
export const postNotfication =
  (notificationData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_NOTIFICATION_REQUEST });

      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const { data } = await axios.post(
        `${API_URL}/notifications/post-notification/`,
        notificationData,
        config,
      );

      dispatch({ type: UPDATE_NOTIFICATION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: UPDATE_NOTIFICATION_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const updateNotification =
  (notificationData) => async (dispatch, getState) => {
    try {
      dispatch({ type: NOTIFICATION_STATUS_REQUEST });

      const {
        userLogin: { user },
      } = getState();

      const accessToken = user.access;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const { data } = await axios.post(
        `${API_URL}/notifications/post-notification/`,
        notificationData,
        config,
      );

      dispatch({ type: NOTIFICATION_STATUS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: NOTIFICATION_STATUS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };
