import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FaWarehouse } from "react-icons/fa";
import { toast } from "react-toastify";

import { getSingleRoom } from "../../../actions/roomsActions";
import Spinner from "../../../shared-components/Spinner/Spinner";
import EditUnitForm from "../ServiceProviderDashboardComponents/EditRoomPageComponents/EditUnitForm";
import { UPDATE_ROOMS_CLEAR } from "../../../constants/roomsConstants";
import { API_URL } from "../../../actions/userActions";

const EditRoomPage = () => {
  const { loading, room } = useSelector((state) => state.getSingleRoom);
  const {
    loading: updatedLoading,
    room: updatedRoom,
    error,
  } = useSelector((state) => state.updateRoom);
  const { user } = useSelector((state) => state.userLogin);
  const accessToken = user.access;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { storageId, roomId } = useParams();

  useEffect(() => {
    dispatch(getSingleRoom(roomId));

    if (updatedRoom) {
      toast.success("Updated Successfully!");
      navigate("/service-provider-dashboard/analytics");
      dispatch({ type: UPDATE_ROOMS_CLEAR });
    }

    if (error) {
      toast.error("Kindly Specify Storage Type!");
      dispatch({ type: UPDATE_ROOMS_CLEAR });
    }
  }, [dispatch, updatedRoom, error]);

  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/promotions/get_pormotions/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setPromotions(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessToken]);

  return (
    <>
      {loading || updatedLoading ? (
        <Spinner />
      ) : (
        <div className="mt-3 col-10">
          <div className="d-flex align-items-center">
            <div className="p-2 bg-orange text-dark rounded me-2">
              <FaWarehouse size={20} />
            </div>
            <h5 className="text-uppercase">Edit Storage Unit</h5>
          </div>
          <div className="mt-4">
            <div className="card  w-100">
              <div className="card-body">
                <h3 className="text-uppercase text-center">
                  Edit Storage Unit Form
                </h3>
                <EditUnitForm
                  room={room}
                  storageId={storageId}
                  roomId={roomId}
                  promotions={promotions}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditRoomPage;
