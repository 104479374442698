import React from "react";
import { AiOutlineStar } from "react-icons/ai";
import { AiTwotoneStar } from "react-icons/ai";
import { useState } from "react"; // Removed unnecessary useEffect import
import { MdOutlineDescription } from "react-icons/md";
import { createRating } from "../../../actions/ordersActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateFeedback } from "../../../actions/ordersActions";
import { toast } from "react-toastify";
import { useEffect } from "react";

const OrdersRating = ({ id, id_update, idDetails }) => {
  const dispatch = useDispatch();
  const starArray = Array.from({ length: 5 });
  const [rating, setRating] = useState({
    facility_ratings: 0,
    comment: "",
  });

  const { status } = useSelector((state) => state.createRating);
  useEffect(() => {
    if (status === 201) {
      dispatch(updateFeedback(id_update, idDetails));
      toast.success("Your Feedback has been Placed.");
    }
  }, [status, dispatch, id_update]);
  const handleChange = (id, value) => {
    setRating((prevRating) => ({
      ...prevRating,
      [id]: value,
    }));
  };
  const handleRating = () => {
    if (rating.comment === "") {
      setRating((prevRating) => ({
        ...prevRating,
        comment: null,
      }));
    }
    dispatch(createRating(id, rating));
  };
  return (
    <>
      <div className="d-flex flex-column">
        <p className="fw-bold fs-5"> Give a Rating</p>
        <div className="d-flex">
          {starArray.map((_, index) =>
            index >= rating.facility_ratings ? (
              <div
                className="cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => handleChange("facility_ratings", index + 1)}
                key={index}
              >
                <AiOutlineStar size={27} />
              </div>
            ) : (
              <div
                className="cursor-pointer"
                onClick={() => handleChange("facility_ratings", index + 1)}
                key={index}
              >
                <AiTwotoneStar
                  size={27}
                  style={{ color: "yellow", cursor: "pointer" }}
                />
              </div>
            ),
          )}
        </div>
        {/* <p className="fw-bold my-3"> Add a Comment </p> */}
        <div className="form-group py-2">
          <div className="input-group ">
            <span className="input-group-text">
              <MdOutlineDescription size={20} />
            </span>
            <textarea
              id="description"
              className="form-control"
              placeholder="Add a comment"
              cols="20"
              rows="2"
              onChange={(e) => handleChange("comment", e.target.value)}
              value={rating.ratingComment}
            ></textarea>
          </div>
        </div>
        <button
          className="btn btn-dark text-dark my-3"
          style={{
            width: "180px",
          }}
          onClick={handleRating}
        >
          Submit Rating
        </button>
      </div>
    </>
  );
};

export default OrdersRating;
