import React, { useState, useEffect } from "react";
import FilterAndSort from "./FilterAndSort";
import IndoorStorageHeading from "./IndoorStorageHeading";
import IndoorStorageList from "./IndoorStorageList";

const AvailableUnits = ({ product, storageFacility }) => {
  const [sortedUnits, setSortedUnits] = useState({
    indoor_storage: [],
    outdoor: [],
    climate_control: [],
  });

  useEffect(() => {
    const categorizedStorageUnits = {
      indoor_storage: [],
      outdoor: [],
      climate_control: [],
    };

    storageFacility.storage_unit.forEach((storageUnit) => {
      const type = storageUnit.storage_unit_type;

      if (!categorizedStorageUnits[type]) {
        categorizedStorageUnits[type] = [];
      }

      categorizedStorageUnits[type].push(storageUnit);
    });

    setSortedUnits(categorizedStorageUnits);
  }, [storageFacility.storage_unit]);

  return (
    <ul className="list-group list-group-flush">
      <li className="list-group-item">
        <h5>
          Available Units
          <span className="h6 text-muted">(All Sizes are Approximate)</span>
        </h5>
      </li>
      <li className="list-group-item">
        <FilterAndSort />
      </li>
      {storageFacility?.storage_unit?.length === 0 ? (
        <div className="alert alert-danger" role="alert">
          This Storage Facility has no available Units.
        </div>
      ) : (
        <>
          {Object.entries(sortedUnits).map(
            ([type, units]) =>
              units.length > 0 && (
                <React.Fragment key={type}>
                  <li className="list-group-item bg-light p-2">
                    <IndoorStorageHeading
                      product={product}
                      storageType={units[0]} // Pass the first unit for the heading
                    />
                  </li>

                  {units.map((storageType) => (
                    <React.Fragment key={storageType.id}>
                      <IndoorStorageList room={storageType} />{" "}
                      {/* Pass the array directly */}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ),
          )}
        </>
      )}
    </ul>
  );
};

export default AvailableUnits;
