import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

import { placeOrder } from "../../actions/ordersActions";
import { PLACE_ORDER_RESET } from "../../constants/ordersConstants";
import { postNotfication } from "../../actions/notificationActions";
import { API_URL } from "../../actions/userActions";

import "./cart.css";

const CheckoutCard = ({
  id,
  dateArray,
  handleUnselected,
  blockCheckout,
  totalPrice,
  finalPrice,
  discountPrice,
  platformFees,
  stripeFees,
  setBackgroundBlur,
}) => {
  const [checkoutFrom, setCheckoutFrom] = useState(false);
  const { error, order } = useSelector((state) => state.placeOrder);
  const { user } = useSelector((state) => state.userLogin);
  const accessToken = user.access;

  const [salesTax, setSalesTax] = useState(0);
  const [taxLoading, setTaxLoading] = useState(false);

  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    business_type: "",
    order: dateArray,
  });

  useEffect(() => {
    setFormData({ ...formData, order: dateArray });
  }, [dateArray]);

  const formDataHandler = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const submitFormHandler = (e) => {
    e.preventDefault();
    const isEmptyStartingDate = dateArray?.filter((item) => {
      return item.starting_date === "" || item.months === 0;
    });

    if (isEmptyStartingDate) {
      handleUnselected(isEmptyStartingDate);
    }

    if (
      isEmptyStartingDate?.length === 0 &&
      formData.business_type &&
      formData.order
    ) {
      dispatch(placeOrder(formData));
    }

    if (!formData.business_type) {
      toast.error("Kindly Specify Business Type!");
      return;
    }
  };

  async function proceedCheckout() {
    const isEmptyStartingDate = dateArray?.filter((item) => {
      return item.starting_date === "" || item.months === 0;
    });

    if (isEmptyStartingDate.length !== 0) {
      handleUnselected(isEmptyStartingDate);
      console.log(isEmptyStartingDate);
      return;
    }
    handleUnselected(isEmptyStartingDate);
    setTaxLoading(true);
    setBackgroundBlur(true);
    setCheckoutFrom(!checkoutFrom);
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await axios.post(
      `${API_URL}/orders/tax-details/`,
      {
        amount: +(
          parseFloat(finalPrice) +
          parseFloat(stripeFees) +
          parseFloat(platformFees)
        ).toFixed(2),
        ip: ipAddress,
      },
      config,
    );

    setSalesTax(data.tax_details.tax_breakdown[0].amount);
    setTaxLoading(false);
  }

  useEffect(() => {
    if (error) {
      toast.error("Error Occured While Placing Order");
      dispatch({ type: PLACE_ORDER_RESET });
    }

    if (order) {
      const notificationData = {
        title: "Order Placed",
        body: `Your order no: ${order?.metadata.order_id} has been placed!`,
        url: `http://3.82.183.189/orders/${order?.metadata.order_id}`,
        is_read: false,
      };
      dispatch(postNotfication(notificationData));
      navigate("/orders");
      dispatch({ type: PLACE_ORDER_RESET });
    }
  }, [error, order]);

  return (
    <div className="card">
      <ul className="list-group list-group-flush">
        <li className="list-group-item p-3">
          <div className="d-flex justify-content-between">
            <h6>Total:</h6>
            <h6>${totalPrice}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <h6>Discount:</h6>
            <h6>${discountPrice}</h6>
          </div>
        </li>
        <li className="list-group-item p-3">
          <p className="fst-italic">
            All rates are in US dollars and do not include sales tax or shipping
            unless otherwise noted.
          </p>
          {!checkoutFrom ? (
            <>
              <button
                onClick={proceedCheckout}
                className={`border-0  text-dark p-2 d-block w-100  ${
                  blockCheckout ? "bg-secondary" : "bg-orange"
                }`}
                disabled={blockCheckout}
              >
                Check Out
              </button>
              {blockCheckout ? (
                <span className="text-danger">
                  You have selected an invalid promotion
                </span>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </li>
        {checkoutFrom && (
          <li className="list-group-item p-3">
            <p className="fw-bold">
              Please Fill out the form to place your order
            </p>
            <form>
              <div className="form-group">
                <label htmlFor="business_type">Business Type</label>
                <select
                  id="business_type"
                  onChange={formDataHandler}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option>Select Type</option>
                  <option value="commercial">Commercial</option>
                  <option value="public">Public</option>
                  <option value="business">Business</option>
                  <option value="personal">Personal</option>
                </select>
              </div>
              <button
                onClick={submitFormHandler}
                className="border-0 mt-3 bg-orange text-dark p-2 d-block w-100"
              >
                Place Order
              </button>
            </form>
          </li>
        )}
      </ul>
      <div className="card-footer">
        {checkoutFrom && (
          <div className="d-flex justify-content-between">
            <h6>Sales Tax:</h6>
            {taxLoading ? (
              <Spinner animation="border" size="sm" variant="dark" />
            ) : (
              <h6>${salesTax}</h6>
            )}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <h6>Platform Fees:</h6>
          <h6>${platformFees}</h6>
        </div>
        <div className="d-flex justify-content-between">
          <h6>Stripe Fees:</h6>
          <h6>${stripeFees}</h6>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <h5>Final Price:</h5>
          <h6>
            $
            {(
              parseFloat(finalPrice) +
              parseFloat(stripeFees) +
              parseFloat(platformFees) +
              parseFloat(salesTax)
            ).toFixed(2)}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCard;
