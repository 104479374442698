import {
  GET_ALL_STORAGE_UNITS_REQUEST,
  GET_ALL_STORAGE_UNITS_SUCCESS,
  GET_ALL_STORAGE_UNITS_FAIL,
  GET_SINGLE_STORAGE_UNITS_FAIL,
  GET_SINGLE_STORAGE_UNITS_REQUEST,
  GET_SINGLE_STORAGE_UNITS_SUCCESS,
  CREATE_STORAGE_UNIT_REQUEST,
  CREATE_STORAGE_UNIT_SUCCESS,
  CREATE_STORAGE_UNIT_FAIL,
  CREATE_STORAGE_UNIT_CLEAR,
  DELETE_STORAGE_UNIT_FAIL,
  DELETE_STORAGE_UNIT_REQUEST,
  DELETE_STORAGE_UNIT_SUCCESS,
  UPDATE_STORAGE_UNIT_REQUEST,
  UPDATE_STORAGE_UNIT_SUCCESS,
  UPDATE_STORAGE_UNIT_FAIL,
  UPDATE_STORAGE_UNIT_CLEAR,
  GET_TOTAL_STORAGE_UNITS_REQUEST,
  GET_TOTAL_STORAGE_UNITS_SUCCESS,
  GET_TOTAL_STORAGE_UNITS_FAIL,
  GET_AVAILABLE_STORAGE_UNITS_REQUEST,
  GET_AVAILABLE_STORAGE_UNITS_SUCCESS,
  GET_AVAILABLE_STORAGE_UNITS_FAIL,
  GET_OCCUPIED_STORAGE_UNITS_REQUEST,
  GET_OCCUPIED_STORAGE_UNITS_SUCCESS,
  GET_OCCUPIED_STORAGE_UNITS_FAIL,
  DELETE_IMAGE_FAIL,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  DELETE_FEATURE_FAIL,
  DELETE_FEATURE_REQUEST,
  DELETE_FEATURE_SUCCESS,
  ADD_NEW_FEATURE_REQUEST,
  ADD_NEW_FEATURE_SUCCESS,
  ADD_NEW_FEATURE_FAIL,
  UPDATE_FEATURE_FAIL,
  UPDATE_FEATURE_REQUEST,
  UPDATE_FEATURE_SUCCESS,
  UPDATE_IMAGE_FAIL,
  UPDATE_IMAGE_REQUEST,
  UPDATE_IMAGE_SUCCESS,
  GET_DISTINCTIVE_STORAGE_UNITS_FAIL,
  GET_DISTINCTIVE_STORAGE_UNITS_REQUEST,
  GET_DISTINCTIVE_STORAGE_UNITS_SUCCESS,
} from "../constants/storageUnitConstants";

export const getAllStorageUnitsReducer = (
  state = { loading: true, storageUnits: { results: [] } },
  action,
) => {
  switch (action.type) {
    case GET_ALL_STORAGE_UNITS_REQUEST:
      return { loading: true };
    case GET_ALL_STORAGE_UNITS_SUCCESS:
      return { loading: false, storageUnits: action.payload };
    case GET_ALL_STORAGE_UNITS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSingleStorageUnitReducer = (
  state = { storageUnit: {}, loading: true },
  action,
) => {
  switch (action.type) {
    case GET_SINGLE_STORAGE_UNITS_REQUEST:
      return { loading: true };
    case GET_SINGLE_STORAGE_UNITS_SUCCESS:
      return { loading: false, storageUnit: action.payload };
    case GET_SINGLE_STORAGE_UNITS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createStorageUnitReducer = (
  state = { loading: false, storageUnit: null },
  action,
) => {
  switch (action.type) {
    case CREATE_STORAGE_UNIT_REQUEST:
      return { loading: true };
    case CREATE_STORAGE_UNIT_SUCCESS:
      return { loading: false, storageUnit: action.payload };
    case CREATE_STORAGE_UNIT_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_STORAGE_UNIT_CLEAR:
      return { loading: false, storageUnit: null };
    default:
      return state;
  }
};

export const AddNewFeatureReducer = (
  state = { loading: false, NewFeature: null },
  action,
) => {
  switch (action.type) {
    case ADD_NEW_FEATURE_REQUEST:
      return { loading: true };
    case ADD_NEW_FEATURE_SUCCESS:
      return { loading: false, NewFeature: action.payload };
    case ADD_NEW_FEATURE_FAIL:
      return { loading: false, error: action.payload };
    // case ADD_NEW_FEATURE_CLEAR:
    //   return { loading: false, NewFeature: null };
    default:
      return state;
  }
};

export const updateStorageUnitReducer = (
  state = { loading: false, storageUnit: null },
  action,
) => {
  switch (action.type) {
    case UPDATE_STORAGE_UNIT_REQUEST:
      return { loading: true };
    case UPDATE_STORAGE_UNIT_SUCCESS:
      return { loading: false, storageUnit: action.payload };
    case UPDATE_STORAGE_UNIT_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_STORAGE_UNIT_CLEAR:
      return { loading: false, storageUnit: null };
    default:
      return state;
  }
};

// Update Feature

export const updateFeatureReducer = (
  state = { loading: false, updatedFeature: null },
  action,
) => {
  switch (action.type) {
    case UPDATE_FEATURE_REQUEST:
      return { loading: true };
    case UPDATE_FEATURE_SUCCESS:
      return { loading: false, storageUnit: action.payload };
    case UPDATE_FEATURE_FAIL:
      return { loading: false, error: action.payload };
    // case UPDATE_STORAGE_UNIT_CLEAR:
    //   return { loading: false, storageUnit: null };
    default:
      return state;
  }
};

export const deleteStorageUnitReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STORAGE_UNIT_REQUEST:
      return { loading: true };
    case DELETE_STORAGE_UNIT_SUCCESS:
      return { loading: false, success: true };
    case DELETE_STORAGE_UNIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getTotalStorageUnitReducer = (
  state = { storageUnits: {}, loading: true },
  action,
) => {
  switch (action.type) {
    case GET_TOTAL_STORAGE_UNITS_REQUEST:
      return { loading: true };
    case GET_TOTAL_STORAGE_UNITS_SUCCESS:
      return { loading: false, storageUnits: action.payload };
    case GET_TOTAL_STORAGE_UNITS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAvailableStorageUnitReducer = (
  state = { availableStorageUnits: {}, loading: true },
  action,
) => {
  switch (action.type) {
    case GET_AVAILABLE_STORAGE_UNITS_REQUEST:
      return { loading: true };
    case GET_AVAILABLE_STORAGE_UNITS_SUCCESS:
      return { loading: false, availableStorageUnits: action.payload };
    case GET_AVAILABLE_STORAGE_UNITS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getOccupiedStorageUnitReducer = (
  state = { occupiedStorageUnits: {}, loading: true },
  action,
) => {
  switch (action.type) {
    case GET_OCCUPIED_STORAGE_UNITS_REQUEST:
      return { loading: true };
    case GET_OCCUPIED_STORAGE_UNITS_SUCCESS:
      return { loading: false, occupiedStorageUnits: action.payload };
    case GET_OCCUPIED_STORAGE_UNITS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteImageReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_IMAGE_REQUEST:
      return { loading: true };
    case DELETE_IMAGE_SUCCESS:
      return { loading: false, msg: action.payload };
    case DELETE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteFeatureReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FEATURE_REQUEST:
      return { loading: true };
    case DELETE_FEATURE_SUCCESS:
      return { loading: false, msg: action.payload };
    case DELETE_FEATURE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Update Image

export const updateImageReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_IMAGE_REQUEST:
      return { loading: true };
    case UPDATE_IMAGE_SUCCESS:
      return { loading: false, msg: action.payload };
    case UPDATE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Get distinctive storage units

export const getDistinctiveStorageUnitsReducer = (
  state = { distinctiveStorageUnits: {}, loading: true },
  action,
) => {
  switch (action.type) {
    case GET_DISTINCTIVE_STORAGE_UNITS_REQUEST:
      return { loading: true };
    case GET_DISTINCTIVE_STORAGE_UNITS_SUCCESS:
      return { loading: false, distinctiveStorageUnits: action.payload };
    case GET_DISTINCTIVE_STORAGE_UNITS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
