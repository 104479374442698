import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  MdLocationCity,
  MdDriveFileRenameOutline,
  MdOutlineDescription,
} from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IoIosAddCircle } from "react-icons/io";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import useImageUploader from "../../../../hooks/useImageUpload";
import AddUnitLocation from "./AddUnitLocation";
import { createStorageUnit } from "../../../../actions/storageUnitActions";

const AddUnitForm = ({ onLocationChange }) => {
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);
  const cloudName = "dxqfv1xa2";
  const { handleImageUpload, uploading } = useImageUploader(cloudName);
  const dispatch = useDispatch();

  const [uploadedImages, setUploadedImages] = useState([]);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const urls = await handleImageUpload(files);

    // Convert urls to the desired format
    const formattedImages = urls.map((url) => ({
      image: url,
    }));

    // Update the uploadedImages state with the formatted array
    setUploadedImages((prevImages) => [...prevImages, ...formattedImages]);
  };

  const removeImage = (index) => {
    setUploadedImages((prevImages) => {
      // Create a copy of the previous state array
      const updatedImages = [...prevImages];
      // Remove the image at the specified index
      updatedImages.splice(index, 1);
      // Return the updated array
      return updatedImages;
    });
  };

  // Define the getLocationData function
  const getLocationData = (data, id, setFieldValue) => {
    if (!data) {
      // If data is not available, reset the form field value to null
      setFieldValue(id, null);
      return;
    }

    // If data is available, update the form field value accordingly
    onLocationChange(data);
    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.county || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };
    setFieldValue(id, locationData);
  };

  const daysOfWeek = [
    { id: "SUN", value: "Sunday" },
    { id: "MON", value: "Monday" },
    { id: "TUE", value: "Tuesday" },
    { id: "WED", value: "Wednesday" },
    { id: "THU", value: "Thursday" },
    { id: "FRI", value: "Friday" },
    { id: "SAT", value: "Saturday" },
  ];

  // Function to convert 24-hour format to 12-hour format
  const convertTo12HourFormat = (time24) => {
    const [hour, minute] = time24.split(":");
    const hour12 = hour % 12 || 12;
    const period = hour < 12 ? "AM" : "PM";
    return `${hour12}:${minute} ${period}`;
  };

  const initialValues = {
    name: "",
    location: null,
    address: "",
    starting_work_time: "",
    ending_work_time: "",
    starting_work_day: "",
    ending_work_day: "",
    starting_access_time: "",
    ending_access_time: "",
    starting_access_day: "",
    ending_access_day: "",
    description: "",
    facility_images: [],
    features: [{ features_text: "" }],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    location: Yup.object().required("Location is required"),
    address: Yup.string().required("Address is required"),
    starting_work_time: Yup.string().required("Starting Work Time is required"),
    ending_work_time: Yup.string().required("Ending Work Time is required"),
    starting_work_day: Yup.string().required("Starting Work Day is required"),
    ending_work_day: Yup.string().required("Ending Work Day is required"),
    starting_access_time: Yup.string().required(
      "Starting Access Time is required",
    ),
    ending_access_time: Yup.string().required("Ending Access Time is required"),
    starting_access_day: Yup.string().required(
      "Starting Access Day is required",
    ),
    ending_access_day: Yup.string().required("Ending Access Day is required"),
  });

  const handleFormSubmit = async (values, { setSubmitting }) => {
    values.storage_provider_ids = serviceProvider.id;
    values.facility_images = uploadedImages;
    values.working_hours = `${values.starting_work_day} - ${
      values.ending_work_day
    }  ${convertTo12HourFormat(values.starting_work_time)} - ${convertTo12HourFormat(
      values.ending_work_time,
    )}`;
    values.access_hours = `${values.starting_access_day} - ${
      values.ending_access_day
    }  ${convertTo12HourFormat(values.starting_access_time)} - ${convertTo12HourFormat(
      values.ending_access_time,
    )}`;
    try {
      await validationSchema.validate(values, { abortEarly: false });
      dispatch(createStorageUnit(values));
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      // toast.error("Failed to create storage unit");
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            {/* Name */}
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <div className="input-group">
                <span className="input-group-text">
                  <MdDriveFileRenameOutline size={20} />
                </span>
                <Field
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Storage Facility Name"
                />
              </div>
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>

            {/* Location */}
            <div className="form-group">
              <label htmlFor="location">Location</label>
              <div className="input-group ">
                <span className="input-group-text">
                  <MdLocationCity size={20} />
                </span>

                <Field name="location" type="text" className="form-control">
                  {({ field, form }) => (
                    <div className="form-control p-0">
                      <AddUnitLocation
                        id="location"
                        getLocationData={(data) =>
                          getLocationData(data, field.name, form.setFieldValue)
                        }
                      />
                    </div>
                  )}
                </Field>
              </div>
              <ErrorMessage
                name="location"
                component="div"
                className="text-danger"
              />
            </div>

            {/* Address */}
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <div className="input-group">
                <span className="input-group-text">
                  <MdDriveFileRenameOutline size={20} />
                </span>
                <Field
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Enter your address"
                />
              </div>
              <ErrorMessage
                name="address"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="row">
              {/* Starting Work Time */}
              <div className="form-group col-6">
                <label htmlFor="starting_work_time">Starting Work Time</label>
                <Field
                  type="time"
                  name="starting_work_time"
                  className="form-control"
                  placeholder="Select Starting Time"
                />
                <ErrorMessage
                  name="starting_work_time"
                  component="div"
                  className="text-danger"
                />
              </div>

              {/* Ending Work Time */}
              <div className="form-group col-6">
                <label htmlFor="ending_work_time">Ending Work Time</label>
                <Field
                  type="time"
                  name="ending_work_time"
                  className="form-control"
                  placeholder="Select Ending Time"
                />
                <ErrorMessage
                  name="ending_work_time"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              {/* Starting Work Day */}
              <div className="form-group col-6">
                <label htmlFor="starting_work_day">Starting Work Day</label>
                <Field
                  as="select"
                  name="starting_work_day"
                  className="form-control"
                >
                  <option value="" disabled>
                    Select Day
                  </option>
                  {daysOfWeek.map((day) => (
                    <option key={day.id} value={day.id}>
                      {day.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="starting_work_day"
                  component="div"
                  className="text-danger"
                />
              </div>

              {/* Ending Work Day */}
              <div className="form-group col-6">
                <label htmlFor="ending_work_day">Ending Work Day</label>
                <Field
                  as="select"
                  name="ending_work_day"
                  className="form-control"
                >
                  <option value="" disabled>
                    Select Day
                  </option>
                  {daysOfWeek.map((day) => (
                    <option key={day.id} value={day.id}>
                      {day.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="ending_work_day"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row">
              {/* Access Starting Time */}
              <div className="form-group col-6">
                <label htmlFor="starting_access_time">
                  Starting Access Time
                </label>
                <Field
                  type="time"
                  name="starting_access_time"
                  className="form-control"
                />
                <ErrorMessage
                  name="starting_access_time"
                  component="div"
                  className="text-danger"
                />
              </div>

              {/* Access Ending Time */}
              <div className="form-group col-6">
                <label htmlFor="ending_access_time">Ending Access Time</label>
                <Field
                  type="time"
                  name="ending_access_time"
                  className="form-control"
                />
                <ErrorMessage
                  name="ending_access_time"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              {/* Starting Access Day */}
              <div className="form-group col-6">
                <label htmlFor="starting_access_day">Starting Access Day</label>
                <Field
                  as="select"
                  name="starting_access_day"
                  className="form-control"
                >
                  <option value="" disabled>
                    Select Day
                  </option>
                  {daysOfWeek.map((day) => (
                    <option key={day.id} value={day.id}>
                      {day.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="starting_access_day"
                  component="div"
                  className="text-danger"
                />
              </div>

              {/* Ending Access Day */}
              <div className="form-group col-6">
                <label htmlFor="ending_access_day">Ending Access Day</label>
                <Field
                  as="select"
                  name="ending_access_day"
                  className="form-control"
                >
                  <option value="" disabled>
                    Select Day
                  </option>
                  {daysOfWeek.map((day) => (
                    <option key={day.id} value={day.id}>
                      {day.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="ending_access_day"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            {/* Description */}
            <div className="form-group">
              <label htmlFor="description">Description (Optional)</label>

              <div className="input-group ">
                <span className="input-group-text">
                  <MdOutlineDescription size={20} />
                </span>
                <Field
                  as="textarea"
                  name="description"
                  className="form-control"
                  rows="3"
                />
              </div>
            </div>

            {/* Image Upload */}
            <FieldArray name="facility_images">
              {({ push, remove }) => (
                <div className="my-2">
                  {/* Input for selecting images */}
                  <p>Upload Images (optional)</p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      handleFileChange(event).then(() => {
                        // Formik will automatically update the form field
                      });
                    }}
                    multiple
                  />
                  <ErrorMessage
                    name="facility_images"
                    component="div"
                    className="text-danger"
                  />
                  {/* Display uploaded images */}
                  <div className="row">
                    {uploadedImages.map((image, index) => (
                      <div
                        key={index}
                        className="col-sm-4 d-flex align-items-center mb-2 flex-column"
                      >
                        <img
                          src={image.image}
                          className="img-fluid rounded m-3"
                          alt="storagePic"
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                          }}
                        />
                        <div
                          className="d-flex justify-content-center align-items-center mx-3 "
                          style={{ gap: "10px" }}
                        >
                          <span type="button">
                            <IoMdRemoveCircleOutline
                              size={22}
                              className="text-danger mt-2"
                              onClick={() => removeImage(index)}
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </FieldArray>

            {/* Features */}
            <FieldArray name="features">
              {({ push, remove }) => (
                <div>
                  {values.features.map((feature, index) => (
                    <div key={index} className="form-group my-1">
                      <label>Feature {index + 1}</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <MdDriveFileRenameOutline size={20} />
                        </span>
                        <Field
                          type="text"
                          name={`features.${index}.features_text`}
                          className="form-control"
                        />
                        <div
                          className="d-flex justify-content-center align-items-center mx-3 "
                          style={{ gap: "10px" }}
                        >
                          <span type="button" onClick={() => remove(index)}>
                            <IoMdRemoveCircleOutline
                              size={22}
                              className="text-danger"
                            />
                          </span>
                          <span
                            type="button"
                            onClick={() => push({ features_text: "" })}
                          >
                            <IoIosAddCircle
                              size={22}
                              className="text-success"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            {/* Submit Button */}
            <button
              type="submit"
              className="btn btn-dark text-dark my-4"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "CREATE NEW FACILITY"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddUnitForm;
