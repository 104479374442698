import React, { useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GiOrange } from "react-icons/gi";
import { AiFillGoogleSquare } from "react-icons/ai";
import { FiInstagram, FiTwitter } from "react-icons/fi";
import {
  MdDriveFileRenameOutline,
  MdPhone,
  MdFax,
  MdEmail,
  MdWeb,
  MdFacebook,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { createServiceProviders } from "../../../actions/serviceProviderActions";
import InputMask from "react-input-mask";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  phone_number: Yup.string()
    .min(9)
    .matches(
      /^\+[0-9]{9,}$/,
      "Please enter a valid phone number in the format +999999999",
    )
    .required("Please Enter Phone Number"),
});

const ServiceProviderDetailsForm = () => {
  // getting user eamil from local storage to pre-populate it in the form
  // const user_email = JSON.parse(localStorage.getItem("userInfo"))?.email;
  const [formData, setFormData] = useState({
    name: "",
    phone_number: null,
    fax_number: null,
    email: "",
    website: null,
    facebook: null,
    instagram: null,
    twitter: null,
    google_buisness: null,
  });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const formDataChangeHandler = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.id]: event.target.value,
      };
    });
  };
  const navigate = useNavigate();
  const submitFormHandler = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      // Form data is valid, proceed with submission
      setErrors({});
      dispatch(createServiceProviders(formData));
      navigate("/service-provider-dashboard/analytics");
    } catch (err) {
      // Validation failed, set errors
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  return (
    <>
      <div className="border-bottom ">
        <h1
          className="text-center mineTextOrange"
          style={{ backgroundColor: "#000034", padding: "10px" }}
        >
          <img src="/images/FindxStorage3.png" height={40} />
          {/* <GiOrange />
          FindxStorage */}
        </h1>
        <p className="lead text-uppercase text-center">
          Please fill all the fields to get access to your dashboard
        </p>
      </div>
      <form className="mt-2" onSubmit={submitFormHandler}>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="name">
            Name <span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdDriveFileRenameOutline />
            </span>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter Name"
              onChange={formDataChangeHandler}
            />
          </div>
          {errors.name && <span className="text-danger">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="phone_number">
            Phone Number <span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdPhone />
            </span>
            {/* <input
              type="text"
              className="form-control"
              id="phone_number"
              placeholder="Enter Phone Number.Pattern should be +999999999"
              onChange={(e) => {
                formDataChangeHandler(e);
              }}
            /> */}
            <InputMask
              mask="+1 (999) 999-9999"
              value={formData.phone_number}
              className="form-control"
              id="phone_number"
              placeholder="Enter Phone Number"
              onChange={(e) => {
                const formatNumber = e.target.value.replace(/[()\s-]/g, "");
                e.target.value = formatNumber;
                formDataChangeHandler(e);
              }}
              title="Please enter a valid phone number in the format +1 (999) 999-9999"
              required
            >
              {(inputProps) => (
                <input type="text" {...inputProps} className="form-control" />
              )}
            </InputMask>
          </div>
          {errors.phone_number && (
            <span className="text-danger">{errors.phone_number}</span>
          )}
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="email">
            Business Email <span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdEmail />
            </span>
            <input
              type="email"
              className="form-control"
              id="email"
              // value={formData.email}
              placeholder="Enter Email"
              onChange={formDataChangeHandler}
            />
          </div>
          {errors.email && <span className="text-danger">{errors.email}</span>}
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="fax_number">
            Fax Number
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdFax />
            </span>
            <input
              type="text"
              className="form-control"
              id="fax_number"
              placeholder="Enter Fax Number"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="website">
            Website
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdWeb />
            </span>
            <input
              type="text"
              className="form-control"
              id="website"
              placeholder="Enter Website URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="facebook">
            Facebook
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <MdFacebook />
            </span>
            <input
              type="text"
              className="form-control"
              id="facebook"
              placeholder="Enter Facebook URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="instagram">
            Instagram
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <FiInstagram />
            </span>
            <input
              type="text"
              className="form-control"
              id="instagram"
              placeholder="Enter Instagram URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="twitter">
            Twitter
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <FiTwitter />
            </span>
            <input
              type="text"
              className="form-control"
              id="twitter"
              placeholder="Enter Twitter URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "13px" }} htmlFor="google_buisness">
            Google Business
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <AiFillGoogleSquare />
            </span>
            <input
              type="text"
              className="form-control"
              id="google_buisness"
              placeholder="Enter Google Buisness URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <button className="mt-2 btn text-dark">Submit Form</button>
      </form>
    </>
  );
};

export default ServiceProviderDetailsForm;
