import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { loginUser } from "../../../actions/userActions";
import { USER_REGISTER_RESET } from "../../../constants/userConstants";
import { getAllServiceProviders } from "../../../actions/serviceProviderActions";

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { loading, user, error } = useSelector((state) => state.userLogin);
  const { info, loading: loadingInfo } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: USER_REGISTER_RESET });
    if (user) {
      if (user.service_provider && user.is_provider_verified) {
        // if (user.is_stripe_account_active && user.is_docusign_completed) {
        //   dispatch(getAllServiceProviders());
        //   navigate("/service-provider-dashboard/analytics");
        // } else if (!user.is_stripe_account_active || !user.is_docusign_completed) {
        //   navigate("/service-provider-stripe-status");
        // } else {
        //   navigate("/");
        // }
        dispatch(getAllServiceProviders());
        navigate("/service-provider-dashboard/analytics");
      } else if (user.service_provider && !user.is_provider_verified) {
        navigate("/provider-details-verification");
      } else {
        navigate("/");
      }

      // toast.success("Logged In!");
    }
    if (error) {
      toast.error(error);
    }
  }, [user, error]);

  //   Change Input Handler
  const changeInputHandler = (event) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
      };
    });
  };

  //   Form Submit Handler
  const submitFormHandler = (event) => {
    event.preventDefault();
    const localUser = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (localUser !== null) {
      window.location.reload(false);
      return;
    }
    if (formData.email === "" || formData.password === "") {
      return toast.error("Both Fields are required!");
    }
    dispatch(loginUser(formData));
  };

  const [show, setShow] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handleRoleChange = (event) => {
  //   setSelectedRole(event.target.value);
  // };
  // const handleFormSubmit = (selectedRole) => {
  //   // event.preventDefault();
  //   if (selectedRole === "user") {
  //     navigate("/sign-up");
  //   } else if (selectedRole === "service_provider") {
  //     navigate("/sign-up-storage-provider");
  //   }
  //   handleClose();
  // };

  const handleFormSubmit = (userType) => {
    return () => {
      if (userType === "user") {
        navigate("/sign-up");
      } else if (userType === "service_provider") {
        navigate("/sign-up-storage-provider");
      }
      handleClose();
    };
  };

  return loading || loadingInfo ? (
    <Spinner />
  ) : (
    <>
      <div className="card mx-auto mt-5 authWidth w-50 bg-light">
        <div className="card-body">
          <h1
            className="text-center mineTextOrange "
            style={{ backgroundColor: "#000034", padding: "10px" }}
          >
            <img src="/images/FindxStorage3.png" height={40} />
            {/* <GiOrange /> */}
            {/* FindxStorage Application */}
          </h1>
          <h2 className="text-center mb-3">Sign In</h2>
          <div className="border-bottom"></div>
          <form onSubmit={submitFormHandler}>
            <div className="form-group m-3">
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={changeInputHandler}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="form-group m-3">
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                className="form-control"
                onChange={changeInputHandler}
                placeholder="Password"
              />
            </div>
            <div className="form-group m-3">
              <button
                type="submit"
                className="border-0 p-2 bg-orange text-dark d-block w-100"
              >
                Sign In
              </button>
            </div>
            <div className="border-bottom"></div>
          </form>
          <Link
            to="#"
            className="nav-link text-dark text-center m-3"
            onClick={handleShow}
          >
            Don't Have an Account? Create New!
          </Link>

          {/* <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formBasicSelect">
              <Form.Label>Select Role</Form.Label>
              <Form.Control as="select" value={selectedRole} onChange={handleRoleChange} required>
                <option value="">Choose...</option>
                <option value="user">User</option>
                <option value="service_provider">Service Provider</option>
              </Form.Control>
            </Form.Group>
            <Button className="border-0 p-2 bg-orange text-dark primary mt-4" type="submit" disabled={!selectedRole}>
              Continue
            </Button>
          </Form>
        </Modal.Body>
      </Modal> */}
          <Modal show={show} onHide={handleClose} centered>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                  Select Role
                </h1>
              </div>
              <div className="flex container row">
                <div className="col-md-6">
                  <div className="card">
                    <div
                      className="border-0 p-3 text-dark hover:bg-orange-500"
                      onClick={() => handleFormSubmit("user")()}
                    >
                      <h5 className=" text-center card-title">User</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div
                      className="border-0 p-3 text-dark"
                      onClick={() => handleFormSubmit("service_provider")()}
                    >
                      <h5 className="card-title">Service Provider</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer"></div>
            </div>
          </Modal>

          <Link
            to="/forgot-password"
            className="nav-link text-dark text-center m-3"
          >
            Forgot Password?
          </Link>
        </div>
      </div>
    </>
  );
};

export default SignIn;
